import { Box, Stack, Text, VStack } from "native-base";
import React, { useEffect, useState } from "react";
import InfrastructureMonogram from "./infrastructure_monogram";
import { dynamicRequest, useDynamicSelector } from "@services/redux";
import { useDispatch } from "react-redux";
import { get_cms_tarrif_query } from "@services/redux/slices/dynamic_entity/graphql/graphql_cms_infrastructure_query";
import { FilterItems } from "@helpers/constants";

const InfrastructureContent = (props) => {
  const { values, InfrastructureContentss } = props;
  const dispatch = useDispatch();
  const { items } = useDynamicSelector("get_cms_tarrif");
  const [infra_content, setInfraContent] = useState([]);
  const [infra_tarrif_content, setInfraTarrifContent] = useState([]);

  const get_infrastructure = () => {
    let keys = [{ key: "get_cms_tarrif", loading: true }];
    let variables = {
      json: {
        system_cms_key: "Tarrif For Competition 2",
      },
    };
    dispatch(dynamicRequest(keys, get_cms_tarrif_query, variables));
  };

  useEffect(() => {
    get_infrastructure();
  }, []);

  useEffect(() => {
    let filtered_items = FilterItems(items);

    let ubvf = filtered_items?.filter(
      (list) => list?.stadium_name?.value === values?.stadium_name?.value
    ).sort((a, b) => a?.stadium_name?.row_number - b?.stadium_name?.row_number);

    setInfraContent(ubvf);
  }, [items, values]);

  useEffect(() => { }, [infra_content]);
  return (
    <>
      <Stack variant={"infra_content_stack"} mt={"20px"}>
        <Box variant={"infra_image_box"}>
          <img src={values?.image?.value} className="stadium-img" />
        </Box>
        <Box variant={"infra_content_box"}>
          <Text fontSize={"var(--font-size-25)"} fontFamily={"Inter"} fontWeight={"bold"}>
            {values?.stadium_name?.value}
          </Text>
          <Box width={"100%"}>
            <Text fontSize={"var(--font-size-14)"} textAlign={"justify"} fontFamily={"Inter"}>
              {values?.description?.value}
            </Text>
          </Box>
        </Box>
      </Stack>
      <div style={{ marginTop: "15px" }}>
        <InfrastructureMonogram list={infra_content} />
      </div>
    </>
  );
};
export default InfrastructureContent;
