import React, { useEffect, useState } from "react";
import { Box, HStack, Input, Pressable, Text, VStack } from "native-base";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import lodash from "lodash";
import Form from "@views/components/ui/antd_form/antd_form";

import {
  create_page,
  getcmsListSelector,
  getPageSelector,
  get_cms,
  update_page,
} from "@services/redux";
import { Button } from "antd";

const PageDetails = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { item } = useSelector(getPageSelector);
  const { items, loading } = useSelector(getcmsListSelector);

  const [actionItem, setActionItem] = useState({});
  const [route, setRoute] = useState("");
  const [html, setHTML] = useState([]);
  const [List, setList] = useState([]);

  useEffect(() => {
    if (item) {
      setActionItem({
        ...item,
        html_components: item.html_components.map((x) => x.id),
        card_components: item.card_components.map((x) => x.id),
        table_components: item.table_components.map((x) => x.id),
      });
    }
  }, [item]);
  useEffect(() => {
    if (items.length) {
      setHTML(items.filter((x) => x.type === "html"));
      setList(items.filter((x) => x.type === "list"));
    }
  }, [items]);
  useEffect(() => {
    dispatch(get_cms());
  }, []);
  const handleSubmit = (values) => {
    values.route = route;
    if (values.id) {
      let update_id = values?.id;
      delete values?.id;
      delete values?.error;
      dispatch(
        update_page({
          id: update_id,
          data: values,
        })
      );
    } else {
      dispatch(
        create_page({
          data: values,
        })
      );
    }
  };

  const refresh = () => {
    dispatch(get_cms());
  };
  const onValueChange = (value) => {
    setRoute(`/${lodash.snakeCase(value.name)}`);
  };

  return (
    <Box>
      <HStack justifyContent={"flex-end"}>
        <Button onClick={refresh} loading={loading}>
          {" "}
          Refresh
        </Button>
      </HStack>
      <Form
        id={props.id}
        initialValues={actionItem}
        onSubmit={handleSubmit}
        onValueChange={onValueChange}
      >
        <VStack space={3} mt={2}>
          <Box flex={1}>
            <Form.TextBox
              field={"name"}
              label={t("form:page_name")}
              rules={[
                {
                  required: true,
                  message: `${t("form:page_name")} is required`,
                },
              ]}
            />
          </Box>
          <Box flex={1}>
            <Input
              name="route"
              onChange={(e) => {
                setRoute(e.target.value);
              }}
              value={route}
              isDisabled={true}
            />
          </Box>
          <Box flex={1}>
            <Form.Select
              field={`html_components`}
              label={t("form:html_components")}
              options={html}
              labelField={"key"}
              valueField={"id"}
              isMulti={true}
              rules={[
                {
                  required: true,
                  message: `${t("form:html_components")} is required`,
                },
              ]}
            />
          </Box>
          <Box flex={1}>
            <Form.Select
              field={`table_components`}
              label={t("form:table_components")}
              options={List}
              labelField={"key"}
              valueField={"id"}
              isMulti={true}
              rules={[
                {
                  required: true,
                  message: `${t("form:table_components")} is required`,
                },
              ]}
            />
          </Box>
          <Box flex={1}>
            <Form.Select
              field={`card_components`}
              label={t("form:card_components")}
              options={List}
              labelField={"key"}
              valueField={"id"}
              isMulti={true}
              rules={[
                {
                  required: true,
                  message: `${t("form:card_components")} is required`,
                },
              ]}
            />
          </Box>
        </VStack>
        <HStack justifyContent={"flex-end"} space={4}>
          <Button danger onClick={props.onClose} className="back-button">
            Close
          </Button>
          <Button className="submit-button" htmlType="submit">
            Create
          </Button>
        </HStack>
      </Form>
    </Box>
  );
};
export default PageDetails;
