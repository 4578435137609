import React, { useEffect, useState } from "react";
import Antd_layout from "@views/components/ui/antd_layout";
import { useDispatch, useSelector } from "react-redux";
import { getcmsListSelector, get_cms } from "@services/redux/slices/cms";
import { UserOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import List from "@assets/icons/list.png";
import { ROUTES } from "@views/routes/my_routes";

const SystemCmsPage = (props) => {
  const dispatch = useDispatch();
  const [menu, setMenu] = useState([]);
  const { items } = useSelector(getcmsListSelector);
  const history = useHistory();
  useEffect(() => {
    dispatch(get_cms({json:{}}));
  }, []);
  useEffect(() => {
    let menu_html_list = [];
    let menu_table_list = [];
    items?.map((item) => {
      if (item?.type === "html")
        menu_html_list.push({
          key: item?.id,
          label: item?.key,
          id: item?.id,
          type: item?.type,
          cms_field: item?.system_cms_fields,
        });
      if (item.type === "list")
        menu_table_list.push({
          key: item.id,
          id: item.id,
          label: item.key,
          type: item.type,
          cms_field: item.system_cms_fields,
        });
    });
    setMenu([
      {
        key: "cms",
        icon: <UserOutlined />,
        label: "CMS Content",
        children: [
          {
            key: "html",
            icon: <UserOutlined />,
            label: "HTML",
            children: menu_html_list,
          },
          {
            key: "table",
            icon: <UserOutlined />,
            label: "List",
            children: menu_table_list,
          },
        ],
      },
    ]);
  }, [items]);

  return <Antd_layout menu={menu} />;
};

export default SystemCmsPage;
