import React from "react";
import { Box, Image } from "native-base";
import SprotsLogo from "@assets/images/SDAT New English Logo.png";


const SidebarHeader = (props) => {
  return (
    <div className="side-header">
      <Box w={"100%"} h={"100%"} justifyContent="center" alignItems="center">
        <Image
          height="100px"
          width="100%"
          alt="logo"
          resizeMode={"contain"}
          source={SprotsLogo}
        />
      </Box>
    </div>
  );
};

export default SidebarHeader;
