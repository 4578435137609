import { Box, HStack, Text } from 'native-base'
import React from 'react'
import VertaceLogo from "@assets/images/vertace_logo.png"
const Footer = () => {
  return (
    <div>
      <Box
        height={"30px"}
        backgroundColor={"#000000de"}
        justifyContent={"center"}
        paddingLeft={"20px"}
        paddingRight={"20px"}
        padding={"10px"}
      >
        <HStack>
          <Box flex={1}>
            <Text color={"white"}>
              SPORTS DEVELOPMENT AUTHORITY OF TAMILNADU
            </Text>
          </Box>
          <Box flex={1} alignItems={"end"}>
            <HStack>
              <Text color={"white"}>Powered by</Text>
              <img src={VertaceLogo} width="70px" />
            </HStack>
          </Box>
        </HStack>
      </Box>
    </div>
  );
}

export default Footer