import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getcmsListSelector, get_cms } from "@services/redux/slices/cms";
import { startCase } from "lodash";
import { Collapse } from "antd";
import PanelItem from "./panel_item";
import { Box } from "native-base";
import ScrollView from "../scroll_view/scroll_view";

const { Panel } = Collapse;

const SideBarCollapsibe = () => {
  const dispatch = useDispatch();
  const [menu, setMenu] = useState([]);
  const [menuList, setMenuList] = useState([]);
  const { items } = useSelector(getcmsListSelector);

  useEffect(() => {
    dispatch(get_cms({json:{}}));
  }, []);

  let menu_list = [];
  let menu_html = [];
  let element_switch_object = {
    html: menu_html,
    list: menu_list,
  };
  useEffect(() => {
    items?.map((item) => {
      element_switch_object[item.type].push(item);
    });
    let collapsible_menu_child = getCollapsible({
      element_switch_object,
      setMenuList,
      menuList: menuList,
    });
    setMenu(collapsible_menu_child);
  }, [items, menuList]);
  return menu;
};
const getCollapsiblePanel = ({ header, panel_list, setMenuList, menuList }) => {
  return (
    <Panel
      header={startCase(header)}
      key={header}
      style={{ maxHeight: "100%", overflowY: "auto" }}
    >
      {panel_list && (
        <PanelItem
          panel_list={panel_list}
          setMenuList={setMenuList}
          menuList={menuList}
        />
      )}
    </Panel>
  );
};
const getCollapsible = ({ element_switch_object, setMenuList, menuList }) => {
  return (
    <Box h={"100%"}>

      <Collapse
        accordion
        childrenColumnName="antdChildren"
        style={{ height: "80vh" }}
        onChange={(e) => {
          if (e) setMenuList(element_switch_object[e.toLowerCase()]);
        }}
      >
        {Object.keys(element_switch_object).map((item) => {
          let child = getCollapsiblePanel({
            header: item.toUpperCase(),
            panel_list: element_switch_object[item],
            setMenuList,
            menuList: menuList,
          });
          return child;
        })}
      </Collapse>
    </Box>
  );
};
export default SideBarCollapsibe;
