import React, { useState } from "react";
import { Layout, Menu, theme } from "antd";
import { Box, ScrollView, Text } from "native-base";
import SystemCMSContentValue from "../system_cms_value/system_cms_value";
import HeaderBar from "@views/components/ui/admin_header_bar";
import SideBarHeader from "@views/components/modules/sidebar/sidebar_header";
import SideBarFooter from "@views/components/modules/sidebar/sidebar_footer";
import SideBarCollapsibe from "../dynamic_side_bar/side_bar_collapsible";

const { Header, Content, Footer, Sider } = Layout;

const AntdLayout = () => {
  const [cmsContent, setCMSContent] = useState({});
  function findNested(arr, id) {
    let found = arr?.find((node) => node.id == id);
    if (found) {
      setCMSContent(found);
      return found;
    } else {
      return arr?.some((c) => findNested(c.children, id));
    }
  }
  return (
    <Layout>
      <Sider width={270} h={"100%"} className="site-layout-background">
        <Box height="100px">
          <SideBarHeader />
          <SideBarCollapsibe />
          {/* <SideBarFooter /> */}
        </Box>
      </Sider>
      <Layout>
        <HeaderBar projectName={"SPORTS DEVELOPMENT AUTHORITY OF TAMILNADU"} />
        <ScrollView>
          <Content
            className="site-layout-background"
            style={{
              padding: 24,
              margin: 0,
            }}
          >
            <SystemCMSContentValue key={cmsContent.key} item={cmsContent} />
          </Content>
        </ScrollView>
        <Footer style={{ textAlign: "center" }}>
          {/* tn forest ©2023  */}
        </Footer>
      </Layout>
    </Layout>
  );
};

export default AntdLayout;
