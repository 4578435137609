import React from "react";
import {
  Box,
  Center,
  Stack,
  Image,
  Text,
  VStack,
  HStack,
  Hidden,
  Pressable,
} from "native-base";
import FooterMapImage from "@assets/images/footer/footer_map_logo.png";
import Logo from "@assets/images/SDAT New English Logo.png";
import { SlLocationPin } from "react-icons/sl";
import { AiOutlinePhone } from "react-icons/ai";
import { Icons } from "@helpers/constants";
import { useTranslation } from "react-i18next";
import { IoIosArrowForward } from "react-icons/io";
import { useHistory } from "react-router-dom";
import { ROUTES } from "@views/routes/my_routes";
const FooterPage = () => {
  const history = useHistory();
  const { t } = useTranslation();
  return (
    
    <Box variant="FooterBoxContainer" >
      <VStack space={5}>
        <Stack
          display={"flex"}
          flexDirection={{ xs: "column", lg: "row" }}
          justifyContent={"space-between"}
          alignItems="center"
          space={{ xs: "20px" }}
        >
          {/* sports logo */}
          <Box variant="FooterRightBox">
            <Hidden only={["xs", "md"]}>
              <Image
                position={"absolute"}
                source={{ uri: FooterMapImage }}
                size="full"
                resizeMode="cover"
              />
            </Hidden>
            <Box variant="FooterRightBoxContent">
              <VStack space={2}>
                <Pressable
                  onPress={() => {
                    history.push(ROUTES.HOME);
                  }}
                >
                  <Box variant={"FooterRightLogoBox"}>
                    <Image
                      size={"full"}
                      source={{
                        uri: Logo,
                      }}
                    />
                  </Box>
                </Pressable>
                <Box
                  width={{ xs: "17rem", lg: "12rem" }}
                  ml={{ xs: "-5rem", lg: "0rem" }}
                >
                  <Text variant={"FooterRightBoxTitle"}>
                    {t("sports_development_authority_of_tamilnadu1")}
                  </Text>
                </Box>
              </VStack>
            </Box>
          </Box>
          {/* important-links */}
          <Box variant={"quick_link_box"} fontFamily={"Inter"}>
            <Text variant={"quick_lick_text"}>{t("quick_links")}</Text>
            <a href="https://yas.nic.in/" target="_blank">
              <Text>
                <HStack alignItems={"center"}>
                  <IoIosArrowForward />
                  {t("ministry_of_youth_affairs_and_sports")}
                </HStack>
              </Text>
            </a>
            <a
              href="http://www.sportsauthorityofindia.nic.in/"
              target="_blank"
            >
              <Text>
                <HStack alignItems={"center"}>
                  <IoIosArrowForward />
                  {t("sports_authority_of_india")}
                </HStack>
              </Text>
            </a>
            <a href="https://www.tn.gov.in/" target="_blank">
              <Text>
                <HStack alignItems={"center"}>
                  <IoIosArrowForward />
                  {t("government_of_tamil_nadu")}
                </HStack>
              </Text>
            </a>
            <a href="https://www.tnpesu.org/" target="_blank">
              <Text>
                <HStack alignItems={"center"}>
                  <IoIosArrowForward />
                  {t("tamil_nadu_physical_education_and_sports_university")}
                </HStack>
              </Text>
            </a>
            <a href="/admin" target="_blank">
              <Text>
                <HStack alignItems={"center"}>
                  <IoIosArrowForward />
                  {t("administration_login")}
                </HStack>
              </Text>
            </a>
          </Box>
          {/* footer-contents */}
          <Box variant="FooterIconBoxContainer">
            <HStack space={5}>
              {Icons.map((e) => {
                return (
                  <Box variant="FooterIconBox">
                    <span className="footer_icon_list">
                      <Center>{e.icon}</Center>
                    </span>
                  </Box>
                );
              })}
            </HStack>
            <Box flex={1}>
              <VStack
                space={4}
                alignItems={{ base: "start", xs: "center", lg: "start" }}
              >
                <HStack space={2} alignItems="center">
                  <SlLocationPin size={18} />
                  <Text variant="FooterAddressBox">{t("contact_address")}</Text>
                </HStack>
                <HStack space={2}>
                  <AiOutlinePhone
                    style={{ transform: "rotate(87deg)" }}
                    size={18}
                  />
                  <a href={`tel:${t("mobile_no")}`}>
                    <Text fontFamily="Helvetica">{t("mobile_no")}</Text>
                  </a>
                </HStack>
              </VStack>
            </Box>
          </Box>
        </Stack>
        <Box variant="FooterCopyRightBox">
          <Stack
            justifyContent={"space-between"}
            display={"flex"}
            flexDirection={[
              "column",
              "column",
              "row",
              "row",
              "row",
              "row",
              "row",
            ]}
            alignItems={"center"}
            space={{ xs: "5px" }}
            paddingLeft={{
              base: "20px",
              lg: "60px",
              xl: "60px",
              "2xl": "60px",
            }}
            paddingRight={{
              base: "20px",
              lg: "60px",
              xl: "60px",
              "2xl": "60px",
            }}
          >
            <Text variant={"FooterCopyRightContent"}>
              © 2024 Copyright: Sports Development Authority of Tamilnadu
            </Text>
            <HStack gap={2} alignItems={"center"}>
              <Text variant={"FooterCopyRightContent"}>Powered by</Text>
              <a href="https://vertace.com/" target="_blank">
                <img
                  src="https://blr1.vultrobjects.com/v-agrigl-assets/vertace_logo.png"
                  alt="vertace-logo"
                  className="vertace-logo"
                />
              </a>
            </HStack>
          </Stack>
        </Box>
      </VStack>
    </Box>
  );
};

export default FooterPage;
