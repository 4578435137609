import React from "react";
import { Box, HStack, Hidden, Link, Stack, Text } from "native-base";
import { useTranslation } from "react-i18next";
import { Pagination, Select } from "antd";
import { useEffect } from "react";
import { dynamicRequest, useDynamicSelector } from "@services/redux";
import { useDispatch } from "react-redux";
import { FilterItems } from "@helpers/constants";
import { useState } from "react";
import moment from "moment";
import { get_cms_government_order_query } from "@services/redux/slices/dynamic_entity/graphql/graphql_cms_government_order_query";
import TableLoading from "../events/table_loading";
import VForm from "../ui/antd_form/antd_form";

const GovernmentOrderTable = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [government_data, setGovernmentData] = useState([]);
  const [go_data, set_go_data] = useState([]);
  const [select_year, set_select_year] = useState('')

  const { items: gov_order_items, loading } = useDynamicSelector(
    "get_cms_government_order"
  );

  const get_government_order = () => {
    let keys = [{ key: "get_cms_government_order", loading: true }];
    let variables = {
      json: {
        system_cms_key: "government_order_table",
      },
    };
    dispatch(dynamicRequest(keys, get_cms_government_order_query, variables));
  };

  useEffect(() => {
    get_government_order();
  }, []);
  // const filteredValues = values?.filter((list, index) => {
  //   const name = moment(list?.date?.value).format("yyyy");
  
  //   // Check if the name is already in the Set
  //   if (!uniqueNamesSet.has(name)) {
  //     // If not, add it to the Set and include the object in the filtered array
  //     uniqueNamesSet.add(name);
  //     return true;
  //   }
  
  //   // If the name is already in the Set, exclude the object from the filtered array
  //   return false;
  // });

  useEffect(() => {
    let values = FilterItems(gov_order_items);
    values.sort((a, b) => moment(b?.date?.value)- moment(a?.date?.value));
    setGovernmentData(values);
    set_go_data(values?.map((list, index) => { return { id: moment(list?.date?.value).format("yyyy"), name: moment(list?.date?.value).format("yyyy") } }).filter((date) => date.name !== date.name))
  }, [gov_order_items]);


  const handle_year_change = (key, value) => {  
    console.log("handle_year_change", key, value);
  }
  return (
    <>
      {/* {loading ? (
        <TableLoading />
      ) : ( */}
        <div>
          <Box>
            <HStack padding={"10px"} alignItems={"center"} space={3}>
              <Text>{t("select_year_of_government_order")}</Text>
              {/* <Select
                placeholder={t("select_the_year")}
                style={{ width: "150px" }}
                // fieldNames={"year"}
                options={go_data}
                // labelInValue
                onChange={handle_year_change}
              /> */}
              <VForm.Select options={go_data} placeholder={t("select_the_year")} width={"150px"} onChange={handle_year_change} labelField={"name"}
                valueField={"id"} />
            </HStack>
          </Box>
          <Hidden from="base" till="lg">
            <table class="table">
              <thead className="table-header">
                <tr>
                  <th className="radius-right">{t("table:s_no")}</th>
                  <th>{t("go_no")}</th>
                  <th>{t("date")}</th>
                  <th style={{ width: "70%" }} className="radius-left">
                    {t("subject")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {government_data.map((tablelist, index) => {
                  return (
                    <>
                      <tr
                        className={`space-add ${government_data.length - 1 !== index
                          ? "table-border-bottom"
                          : ""
                          }`}
                      >
                        <td data-label="S.No">{index + 1}</td>
                        <td data-label="go-no">{tablelist?.go_no?.value}</td>
                        <td data-label="date">
                          {moment(tablelist?.date?.value).format("DD-MM-YYYY")}
                        </td>
                        <td
                          data-label="subject"
                          className="column-width subject"
                          style={{ paddingRight: "20px" }}
                        >
                          <Link
                            _text={{
                              color: "#2F80ED",
                              textDecoration: "none",
                              fontWeight: "500",
                              maxWidth: "1400px",
                              minWidth: "600px",
                              fontFamily: "Inter",
                            }}
                            href={tablelist?.document?.value}
                            isExternal
                          >
                            {tablelist?.subject?.value}
                          </Link>
                        </td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
          </Hidden>
          <Hidden from="lg" till="3xl">
            <Box variant={"tender_table_box"}>
              {government_data.map((content, index) => {
                return (
                  <>
                    <Box variant={"tender_table_contents"}>
                      <Text variant={"tender_description"}>
                        {" "}
                        <Link
                          _text={{
                            color: "#2F80ED",
                            textDecoration: "none",
                            fontWeight: "500",
                            maxWidth: "1400px",
                            fontFamily: "Inter",
                          }}
                          href={content?.document?.value}
                          isExternal
                        >
                          {content?.subject?.value}
                        </Link>
                      </Text>
                      <Text>
                        <Text variant={"table_headline"} color={"#828282"}>
                          {t("go_no")}:
                        </Text>
                        <Text
                          color={"black"}
                          fontWeight={"500"}
                          marginLeft={"1px"}
                        >
                          {content?.go_no?.value}
                        </Text>
                      </Text>
                      <Text>
                        <Text variant={"table_headline"} color={"#828282"}>
                          {t("date")}:
                        </Text>
                        <Text
                          color={"black"}
                          fontWeight={"500"}
                          marginLeft={"1px"}
                        >
                          {moment(content?.date?.value).format("DD-MM-YYYY")}
                        </Text>
                      </Text>
                    </Box>
                  </>
                );
              })}
            </Box>
          </Hidden>
          <div className="pagination-box">
            <Stack variant={"pagination_stack"}>
              <Hidden from="base" till="lg">
                <Box justifyContent={"center"}>
                  <Text>Records per page</Text>
                </Box>
              </Hidden>
              <Box variant={"pagination_box"}>
                <Pagination
                  total={85}
                  //   showTotal={(total) => `Records per page ${total} items`}
                  defaultPageSize={20}
                  defaultCurrent={1}
                />
              </Box>
            </Stack>
          </div>
        </div>
      {/* )}  */}
    </>
  );
};

export default GovernmentOrderTable;
