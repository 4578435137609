import {
  Box,
  VStack,
  Center,
  Text,
  Button,
  HStack,
  Pressable,
} from "native-base";
import React from "react";
import Card from "@src/threecard/Card";
import { ROUTES } from "@views/routes/my_routes";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FilterItems } from "@helpers/constants";
import { useEffect } from "react";
import { dynamicRequest, useDynamicSelector } from "@services/redux";
import { get_cms_event_table_query } from "@services/redux/slices/dynamic_entity/graphql/graphql_cms_event_table_query";
import { useState } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";

const Events = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [event_data, setEventData] = useState([]);
  const { items: event_items } = useDynamicSelector("get_cms_event_table");

  const get_event_table = () => {
    let keys = [{ key: "get_cms_event_table", loading: true }];
    let variables = {
      json: {
        system_cms_key: "event_table",
      },
    };
    dispatch(dynamicRequest(keys, get_cms_event_table_query, variables));
  };

  useEffect(() => {
    get_event_table();
  }, []);

  useEffect(() => {
    let values = FilterItems(event_items);
    setEventData(values?.sort((a, b) => Number(a?.order?.value) - Number(b?.order?.value)));
  }, [event_items]);

  const EventList = (event_data) => {
    const list = event_data?.map((information) => (
      <Box variant="eventCard_info">
        <HStack space={3}>
          <Box variant={"calender"} alignItems={"center"} justifyContent={"center"}>
            <Center>
              {/* <Box variant={"calenderDate"}>
                <Center>
                <Text variant={"eventCardCalenderDate"}>
                {information.date}
                </Text>
                </Center>
              </Box> */}

              {/* <Box variant={"calenderMonth"}>
                <Center>
                  <Text variant={"eventCardCalenderMonth"}>
                    {moment(information?.date?.value).format("MMM")}
                  </Text>
                </Center>
              </Box> */}

              <Box variant={"calenderYear"}>
                <Center>
                  <Text variant={"eventCardCalenderYear"}>
                    {moment(information?.date?.value).format("YYYY")}
                  </Text>
                </Center>
              </Box>
            </Center>
          </Box>
          <VStack flex={1} justifyContent="center">
            <Pressable
              onPress={() => {
                history.push(ROUTES.EVENTS);
              }}
            >
              <Text variant="eventCard_htxt">
                {information?.event_name?.value}
              </Text>
              {/* <Text variant="eventCard_btxt">
                {information?.event_name?.value}
              </Text> */}
            </Pressable>
          </VStack>
        </HStack>
      </Box>
    ));
    return list;
  };
  return (
    <>
      <Card
        header={t("events")}
        component={EventList(event_data)}
        url={ROUTES.EVENTS}
      />
    </>
  );
};

export default Events;
