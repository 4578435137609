import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  fields: [],
};

const slice = createSlice({
  name: "validationSchema",
  initialState,
  reducers: {
    _set_validation_schema_data: (state, { payload }) => {
      state.fields = payload.value?.fields;
    },
  },
});

const { _set_validation_schema_data } = slice.actions;

export const validationSchemaSelector = (state) => state.validationSchema;

export const validationSchemaReducer = slice.reducer;

export function set_validation_schema_data(value) {
  return async (dispatch) => {
    dispatch(_set_validation_schema_data({ value }));
  };
}
