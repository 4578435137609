import { Box, HStack, Hidden, Stack, Text, VStack } from "native-base";
import React from "react";
import { InfrastructureContentss } from "./Infrastructure_json";
import { Empty } from "antd";
import ReactHtmlParser from "react-html-parser";

const InfrastructureTable = (props) => {
  const { values, description } = props;
  // const FindIt = InfrastructureContentss[0].description.find(
  //   (Item) => Item.key === values
  // );
  // let Amounts = FindIt?.lists?.table?.map((e) => {
  //   return e?.amount?.map((e) => {
  //     return e?.fees;
  //   });
  // });
  return (
    <div>
      <Box variant={"infrastructure_table_box"}>
        {/* <Text variant={"infrastructure_table_title"}>{FindIt?.title}</Text> */}
      </Box>
      <>
        <Stack
          display={"flex"}
          flexDirection={{
            base: "column",
            lg: "row"
          }}
          justifyContent={
            !description?.note?.includes("<p><br></p>") &&
              description?.note &&
              description?.list
              ? "space-evenly"
              : "center"
          }
        >
          {description?.list && (
            <Box width={{ base: "350px", lg: "600px" }} >
              {ReactHtmlParser(description?.list)}
              {/* <table class="table">
                <thead className="table-header">
                  <tr>
                    <th className="radius-right">S.No</th>
                    <th>Type of user</th>
                    <th className="radius-left">
                      Tariff approved in EC dt. 04.05.2017 (Rs.)
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {FindIt?.lists?.map((p, index) => {
                    return (
                      <>
                        <tr
                          className={`space-add ${
                            p.length - 1 !== index ? "table-border-bottom" : ""
                          }`}
                        >
                          <td>{index + 1}</td>
                          <td>{p?.name}</td>
                          <td>{p.fees}</td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table> */}
            </Box>
          )}
          {!description?.note?.includes("<p><br></p>") && description?.note && (
            <Box variant={"infrastructure_note_box"} width={{ base: "350px", lg: "600px" }}>
              <Box variant={"infrastructure_contents_box"}>
                <Box variant={"infrastructure_note_title_box"}>
                  <Text bold>Note:</Text>
                </Box>
                {ReactHtmlParser(description?.note)}
              </Box>
            </Box>
          )}
        </Stack>
      </>
      {/* <Hidden from={"lg"} till={"3xl"}>
        {FindIt?.lists ? (
          <table class="table">
            <thead className="table-header">
              <tr>
                <th className="radius-right radius-left">Type of user</th>
              </tr>
            </thead>
            <tbody>
              {FindIt?.lists?.map((p, index) => {
                return (
                  <>
                    <tr
                      className={`space-add ${
                        p.length - 1 !== index ? "table-border-bottom" : ""
                      }`}
                    >
                      <td>
                        <HStack
                          justifyContent={"space-between"}
                          paddingRight={"20px"}
                        >
                          {p?.name}
                          {p.fees}
                        </HStack>
                      </td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </table>
        ) : (
          <>
            <Box>
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </Box>
          </>
        )}
      </Hidden> */}
    </div>
  );
};

export default InfrastructureTable;
