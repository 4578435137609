import { Box, HStack, Hidden, Stack, Text } from "native-base";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MenuBar from "../banner/menubar";
import ContactUsContent from "./contact_us_content";
import ContactUsCard from "./contact_card";
import ContactUsWelfareOfficers from "./contact_us_welfare_officers";
import ContactUsInformationManager from "./contact_us_information_manager";
import BackgroundWaterMarkLogo from "@views/pages/home/background_water_mark_logo";
import { dynamicRequest, useDynamicSelector } from "@services/redux";
import { useDispatch } from "react-redux";
import { FilterItems } from "@helpers/constants";
import { get_cms_contact_details_query } from "@services/redux/slices/dynamic_entity/graphql/graphql_cms_contact_details_query";
import { get_cms_contact_us_card_query } from "@services/redux/slices/dynamic_entity/graphql/graphql_cms_contact_us_card_query";

const ContactUs = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [contact_data, setContactData] = useState();

  const { items: contact_items, loading } = useDynamicSelector(
    "get_cms_contact_us_card"
  );
  const get_contact_us_card = () => {
    let keys = [{ key: "get_cms_contact_us_card", loading: true }];
    let variables = {
      json: {
        system_cms_key: "contact_us_card",
      },
    };
    dispatch(dynamicRequest(keys, get_cms_contact_us_card_query, variables));
  };

  useEffect(() => {
    get_contact_us_card();
  }, []);

  useEffect(() => {
    let values = FilterItems(contact_items);
    setContactData(
      values?.sort((a, b) => Number(a?.order?.value) - Number(b?.order?.value))
    );
  }, [contact_items]);
  const [youth_welfare_officers, set_youth_welfare_officers_ata] = useState([]);
  const {
    items: youth_welfare_officers_items,
    loading: youth_welfare_officers_loading,
  } = useDynamicSelector("get_cms_contact_details");

  const get_youth_welfare_officers = () => {
    let keys = [{ key: "get_cms_contact_details", loading: true }];
    let variables = {
      json: {
        system_cms_key: "contact_us_youth_welfare_officers",
      },
    };
    dispatch(dynamicRequest(keys, get_cms_contact_details_query, variables));
  };

  useEffect(() => {
    get_youth_welfare_officers();
  }, []);

  useEffect(() => {
    let values = FilterItems(youth_welfare_officers_items);
    set_youth_welfare_officers_ata(values);
  }, [youth_welfare_officers_items]);
  return (
    <div style={{ overflowX: "hidden" }}>
      <Box variant={"tender_page_menubox"} height={"auto"}>
        <Box height={"120px"}>
          <MenuBar />
          <Hidden only={["lg", "xl", "2xl", "3xl"]}>
            <Box variant={"xs_size_menu_headline"}>
              <Text variant={"xs_size_menu_headline_one"}>
                {t("header_content_one")}
              </Text>
              <Text variant={"xs_size_menu_headline_two"}>
                {t("sports_development_authority_of_tamilnadu")}
              </Text>
            </Box>
          </Hidden>
        </Box>
        <Hidden only={["xs", "sm"]}>
          <Text variant={"page_title"}>{t("contact")}</Text>
        </Hidden>
      </Box>
      <Hidden only={["lg", "xl", "md", "2xl", "3xl"]}>
        <Box>
          <Text variant={"page_title"} color={"black"} paddingBottom={"0px"}>
            {t("contact")}
          </Text>
        </Box>
      </Hidden>
      <Box
        paddingLeft={{ base: "20px", md: "30px", xl: "60px", lg: "60px" }}
        paddingRight={{ base: "20px", md: "30px", xl: "60px", lg: "60px" }}
        // height={"500px"}
        py="20px"
      >
        <BackgroundWaterMarkLogo />
        <ContactUsContent />
        <Stack
          mt={6}
          width={"100%"}
          flexDirection={{ base: "column", lg: "row", xl: "row" }}
          space={3}
        >
          <Box
            flex={1}
            paddingRight={{
              base: "20px",
              md: "0px",
              xs: "0px",
              lg: "20px",
              xl: "20px",
              "2xl": "20px",
              "3xl": "20px",
            }}
          >
            {/* <ScrollView> */}
            <ContactUsCard contact_data={contact_data} />
            {/* </ScrollView> */}
          </Box>
          <Stack
            display={"flex"}
            flex={1}
            height={"auto"}
            width={"100%"}
            space={3}
            mb={"10px"}
          >
            <Box variant={"contact_us_welfareOfficers_card"}>
              <ContactUsWelfareOfficers />
            </Box>
            <Box variant={"contact_us_welfareOfficers_card"}>
              <ContactUsInformationManager />
            </Box>
          </Stack>
          {/* <Box gap={5} flex={1}>
            <Box variant={"contact_us_welfareOfficers_card"}>
              <ContactUsWelfareOfficers />
            </Box>
            <Box variant={"contact_us_welfareOfficers_card"}>
              <ContactUsInformationManager />
            </Box>
          </Box> */}
        </Stack>
      </Box>
    </div>
  );
};

export default ContactUs;
