import RTIQuestionsContents from "@views/components/rti_questions/rti_questions_contents";
import React from "react";

const RTIQuestionsPage = () => {
  return (
    <div>
      <RTIQuestionsContents />
    </div>
  );
};

export default RTIQuestionsPage;
