import EventsContents from "@views/components/events/events_contents";
import React from "react";

const EventsPage = () => {
  return (
    <div>
      <EventsContents />
    </div>
  );
};

export default EventsPage;
