import { Box, HStack, Text } from "native-base";
import React from "react";
import { useTranslation } from "react-i18next";

const ContactUsInformationManager = () => {
  const { t } = useTranslation();
  const headOffice = {
    name: t("kavitha"),
    address: t("website_address"),
    pho_no: "044 – 25611522 ",
    e_mail: "kavitha2703@tn.gov.in",
    department: t("sport_development_authority_of_tamilnadu"),
    designation: t("it_department"),
  };
  return (
    <div>
      <Box width={"100%"} justifyContent={"center"} textAlign={"start"}>
        <Text fontSize={"2.8vh"} fontWeight={"bold"} mb={"10px"}>
          {t("website_information_manager")}
        </Text>
      </Box>
      <Box gap={"20px"} flex={1} mt={"10px"}>
        <Box fontFamily={"Inter"} paddingRight={"30px"}>
          <HStack variant={"contact_us_address_details"} space={"5px"}>
            <HStack
              width={{
                base: "63px",
                xs: "120px",
                md: "120px",
                lg: "74px",
                xl: "185px",
                "2xl": "185px",
                "3xl": "185px",
              }}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Text variant={"contact_details_title"}>{t("name")}</Text>
              <span>:</span>
            </HStack>
            <Text variant={"contact_details"}>{headOffice.name}</Text>
          </HStack>
          <HStack variant={"contact_us_address_details"} space={"5px"}>
            <HStack
              width={{
                base: "63px",
                xs: "120px",
                md: "120px",
                lg: "74px",
                xl: "185px",
                "2xl": "185px",
                "3xl": "185px",
              }}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Text variant={"contact_details_title"}>{t("designation")}</Text>
              <span>:</span>
            </HStack>
            <Text variant={"contact_details"}>{headOffice.designation}</Text>
          </HStack>
          <HStack variant={"contact_us_address_details"} space={"5px"}>
            <HStack
              width={{
                base: "63px",
                xs: "120px",
                md: "120px",
                lg: "74px",
                xl: "185px",
                "2xl": "185px",
                "3xl": "185px",
              }}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Text variant={"contact_details_title"}>{t("department")}</Text>
              <span>:</span>
            </HStack>
            <Text variant={"contact_details"}>{headOffice.department}</Text>
          </HStack>
          <HStack variant={"contact_us_address_details"} space={"5px"}>
            <HStack
              width={{
                base: "63px",
                xs: "120px",
                md: "120px",
                lg: "74px",
                xl: "185px",
                "2xl": "185px",
                "3xl": "185px",
              }}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Text variant={"contact_details_title"}>{t("address")}</Text>
              <span>:</span>
            </HStack>
            <Text variant={"contact_details"}>{headOffice.address}</Text>
          </HStack>
          <HStack variant={"contact_us_address_details"} space={"5px"}>
            <HStack
              width={{
                base: "63px",
                xs: "120px",
                md: "120px",
                lg: "74px",
                xl: "185px",
                "2xl": "185px",
                "3xl": "185px",
              }}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Text variant={"contact_details_title"}>{t("phone")}</Text>
              <span>:</span>
            </HStack>
            <a href={`tel:${headOffice.pho_no}`}>
              <Text variant={"contact_details"} color={"blue.500"}>
                {headOffice.pho_no}
              </Text>
            </a>
          </HStack>
          <HStack variant={"contact_us_address_details"} space={"5px"}>
            <HStack
              width={{
                base: "63px",
                xs: "120px",
                md: "120px",
                lg: "74px",
                xl: "185px",
                "2xl": "185px",
                "3xl": "185px",
              }}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Text variant={"contact_details_title"}>{t("e_mail")}</Text>
              <span>:</span>
            </HStack>
            <a href={`mailto:${headOffice.e_mail}`}>
              <Text variant={"contact_details"} color={"blue.500"}>
                {headOffice.e_mail}
              </Text>
            </a>
          </HStack>
        </Box>
      </Box>
    </div>
  );
};

export default ContactUsInformationManager;
