import React from "react";
import {
  Box,
  Button,
  Stack,
  Image,
  Text,
  Center,
  HStack,
  Hidden,
} from "native-base";
import StalinHon from "../../../assets/images/menu/Hon_CM_Photo1.png";
import KabaddiPlay from "../../../assets/images/menu/kabaddiplay.png";
import { useTranslation } from "react-i18next";

const CmTrophy = () => {
  const { t } = useTranslation();
  return (
    <Box>
      <Hidden only={["sm", "xs"]}>
        <Box variant="SportEventBox">
          {/* <Center> */}
          <Box variant="BackGroundImageBox">
            <Image
              variant="SportEventBackGroundImage"
              source={{
                uri: KabaddiPlay,
              }}
            />
          </Box>
          <Stack
            space={"1"}
            justifyContent={"space-evenly"}
            display={"flex"}
            flexDirection={{
              base: "column",
              xs: "column",
              md: "row",
            }}
          >
            <Box variant="SportEventImageBox">
              <Image
                size={{ xs: "100", md: "100", lg: "130" }}
                // borderRadius={"100"}
                source={{ uri: StalinHon }}
              />
            </Box>
            <Box variant="SportsContentBox">
              <Text variant="SportHeadingText">{t("cm_trophy")}</Text>

              <Text variant="SportsContentText">{t("consectetur")}</Text>
            </Box>

            <Center>
              <Box marginLeft={{ xs: "24", md: "24", lg: "0" }} width={"40"}
                height={"12"}>
                {/* <a
                  href="https://www.sdat.in/cmtrophy"
                  target="_blank"
                  style={{ color: "black" }}
                >
                  <Button
                    width={"32"}
                    height={"12"}
                    rounded={"lg"}
                    backgroundColor={"white"}
                    _text={{
                      color: "#1F2937",
                    }}
                  >
                    {t("apply_now")}
                  </Button>
                </a> */}
              </Box>
            </Center>
          </Stack>
          {/* </Center> */}
        </Box>
      </Hidden>
      <Center>
        <Hidden only={["lg", "xl", "md", "2xl"]}>
          <Box variant="SportsEventBox">
            <Box variant="SportBackGroundBox">
              <Image
                variant="SportsBackGroundImage"
                source={{
                  uri: KabaddiPlay,
                }}
              />
            </Box>
            <Stack
              space={"1"}
              justifyContent={"center"}
              display={"flex"}
              flexDirection={{
                base: "column",
                xs: "column",
                md: "row",
              }}
            >
              <Stack
                display={"flex"}
                flexDirection={{ base: "column", xs: "column", lg: "row" }}
              >
                <Box>
                  <Stack
                    display={"flex"}
                    flexDirection={{ base: "column", xs: "row", lg: "row" }}
                  >
                    <Box variant="SportsEventImageBox">
                      <Image
                        size={{ xs: "20", lg: "140" }}
                        // borderRadius={"100"}
                        source={{ uri: StalinHon }}
                      />
                    </Box>
                    <Box variant="SportContentBox">
                      <Text variant="SportEventContentText">
                        {t("cm_trophy")}
                      </Text>
                    </Box>
                  </Stack>
                </Box>
                <Box>
                  <Text variant="SportsDescriptionText" textAlign={"justify"}>
                    {t("consectetur")}
                  </Text>
                </Box>
              </Stack>

              <Center>
                <Box
                  marginLeft={{ xs: "0", lg: "24" }}
                  marginTop={{ xs: "5", lg: "16" }}
                >
                  {/* <a
                    href="https://www.sdat.in/cmtrophy"
                    target="_blank"
                    style={{ color: "black" }}
                  >
                    <Button
                      width={"32"}
                      height={"12"}
                      rounded={"lg"}
                      backgroundColor={"white"}
                      _text={{
                        color: "#1F2937",
                      }}
                      font-family="'Poppins', sans-serif"
                    >
                      {t("apply_now")}
                    </Button>
                  </a> */}
                </Box>
              </Center>
            </Stack>
          </Box>
        </Hidden>
      </Center>
    </Box>
  );
};
export default CmTrophy;
