import { FilterItems, RupeesFormat } from "@helpers/constants";
import { Box, HStack, Hidden, Stack, Text, VStack } from "native-base";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
// import MobileResponsiveTable from "@views/components/schemes/mobile_responsive_table";
import { Pagination } from "antd";
import MenuBar from "@views/components/banner/menubar";
import MobileResponsiveTable from "@views/components/schemes/mobile_responsive_table";
import ContactUsResponsiveTable from "./contact_us_responsive_table";
import { useDispatch } from "react-redux";
import { dynamicRequest, useDynamicSelector } from "@services/redux";
import { get_cms_contact_details_query } from "@services/redux/slices/dynamic_entity/graphql/graphql_cms_contact_details_query";
import ReactHtmlParser from "react-html-parser";
import TableLoading from "@views/components/events/table_loading";

const ContactDetails = () => {
  const [open_index, setOpenIndex] = useState(0);
  const [contact_data, setContactData] = useState([]);
  const dispatch = useDispatch();
  const handleExpendable = (index) => {
    setOpenIndex(index);
  };
  const { t } = useTranslation();
  const { items: contact_items, loading } = useDynamicSelector(
    "get_cms_contact_details"
  );

  const get_contact_details = () => {
    let keys = [{ key: "get_cms_contact_details", loading: true }];
    let variables = {
      json: {
        system_cms_key: "contact_us_youth_welfare_officers",
      },
    };
    dispatch(dynamicRequest(keys, get_cms_contact_details_query, variables));
  };

  useEffect(() => {
    get_contact_details();
  }, []);

  useEffect(() => {
    let values = FilterItems(contact_items);
    setContactData(values.sort((a, b) => Number(a?.order?.value) - Number(b?.order?.value)));
  }, [contact_items]);
  return (
    <>
      <Box variant={"tender_page_menubox"} height={"auto"}>
        <Box height={"120px"}>
          <MenuBar />
          <Hidden only={["lg", "xl", "2xl", "3xl"]}>
            <Box variant={"xs_size_menu_headline"}>
              <Text variant={"xs_size_menu_headline_one"}>
                {t("header_content_one")}
              </Text>
              <Text variant={"xs_size_menu_headline_two"}>
                {t("sports_development_authority_of_tamilnadu")}
              </Text>
            </Box>
          </Hidden>
        </Box>
        <Hidden only={["xs", "sm"]}>
          <Text variant={"page_title"}>{t("contact")}</Text>
        </Hidden>
        <Hidden only={["lg", "xl", "md", "2xl", "3xl"]}>
          <Box>
            <Text variant={"page_title"} color={"white"} paddingBottom={"0px"}>
              {t("contact")}
            </Text>
          </Box>
        </Hidden>
      </Box>

      <Box
        padding={{
          base: "20px",
          md: "60px",
          lg: "60px",
          xl: "60px",
          "2xl": "60px",
        }}
      >
        {loading ? (
          <TableLoading />
        ) : (
          <>
            <Hidden from="base" till="lg">
              <div>
                <table class="table">
                  <thead className="table-header">
                    <tr>
                      <th className="radius-right">{t("table:s_no")}</th>
                      <th className="radius-left">
                        {t("table:name_of_contact_details")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {contact_data.map((tablelist, index) => {
                      return (
                        <>
                          <tr
                            className={`space-add ${contact_data.length - 1 !== index
                              ? "table-border-bottom"
                              : ""
                              }`}
                          >
                            <td>{index + 1}</td>
                            <td>
                              <div
                                onClick={() => handleExpendable(index)}
                                className={`table_row ${open_index === index
                                  ? `table_row_height`
                                  : `table_row_normal`
                                  }`}
                              >
                                {open_index === index ? (
                                  <p className="competition_name">
                                    <HStack variant={"table_title_tag"}>
                                      {/* <Text width={"10px"}>-</Text> */}
                                      <Box width={"20px"}>
                                        <AiOutlineMinus />
                                      </Box>
                                      <Text variant={"table_title"}>
                                        {
                                          tablelist?.name_of_contact_details
                                            ?.value
                                        }
                                      </Text>
                                    </HStack>
                                  </p>
                                ) : (
                                  <p className="competition_name">
                                    <HStack variant={"table_title_tag"}>
                                      {/* <Text>+</Text> */}
                                      <Box width={"20px"}>
                                        <AiOutlinePlus />
                                      </Box>
                                      <Text variant={"table_title"}>
                                        {
                                          tablelist.name_of_contact_details
                                            ?.value
                                        }
                                      </Text>
                                    </HStack>
                                  </p>
                                )}
                                <div
                                  className={`table_open ${open_index === index
                                    ? `table_active`
                                    : `table_inactive`
                                    }`}
                                >
                                  <HStack
                                    padding={"20px"}
                                    space={4}
                                    alignItems={"center"}
                                  >
                                    {ReactHtmlParser(
                                      tablelist?.contact_details?.value
                                    )}

                                    {/* {tablelist.children.length ? (
                                  <table
                                    className="expendable-table"
                                    style={{ flex: 1 }}
                                  >
                                    <thead>
                                      <tr className="expendable-table-header">
                                        <th className="radius-right">
                                          {t("table:s_no")}
                                        </th>
                                        <th>{t("table:region")}</th>
                                        <th>{t("table:email_id")}</th>
                                        <th className="radius-left">
                                          {t("table:contact_no")}
                                        </th>
                                      </tr>
                                    </thead>

                                    <tbody>
                                      {tablelist?.children?.map((s, index) => {
                                        return (
                                          <tr
                                            className={`space-add ${
                                              TableData.length - 1 !== index
                                                ? "table-border-bottom"
                                                : ""
                                            }`}
                                          >
                                            <td>{index + 1}</td>
                                            <td>{s.region}</td>
                                            <td>
                                              {s.email_id.map((a) => {
                                                return (
                                                  <>
                                                    <VStack>
                                                      <a
                                                        href={`email:${a.email_id}`}
                                                      >
                                                        {a.email_id}
                                                      </a>
                                                    </VStack>
                                                  </>
                                                );
                                              })}
                                            </td>
                                            <td>
                                              {s.contact_no.map((a) => {
                                                return (
                                                  <>
                                                    <VStack>
                                                      <a
                                                        href={`tel:${a.contact_no}`}
                                                      >
                                                        {a.contact_no}
                                                      </a>
                                                    </VStack>
                                                  </>
                                                );
                                              })}
                                            </td>
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </table>
                                ) : (
                                  " "
                                )} */}
                                  </HStack>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </Hidden>
            <Hidden from="lg" till="3xl">
              <ContactUsResponsiveTable TableData={contact_data} />
            </Hidden>
            <div className="pagination-box">
              <Stack variant={"pagination_stack"}>
                <Hidden from="base" till="lg">
                  <Box justifyContent={"center"}>
                    <Text>Records per page</Text>
                  </Box>
                </Hidden>
                <Box variant={"pagination_box"}>
                  <Pagination
                    total={85}
                    //   showTotal={(total) => `Records per page ${total} contact_no`}
                    defaultPageSize={20}
                    defaultCurrent={1}
                  />
                </Box>
              </Stack>
            </div>
          </>
        )}
      </Box>
    </>
  );
};

export default ContactDetails;
