import { useState, useEffect } from "react";
import { retrieveItem } from "./storage";

const useCheckLogin = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  useEffect(() => {
    let user = null;
    let session_id = retrieveItem("session_id");
    try {
      if (session_id) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
    } catch (e) {
      setIsLoggedIn(false);
    }
  });
  return isLoggedIn;
};

const useShouldChangePassword = () => {
  const [shouldChangePassword, setShouldChangePassword] = useState(null);
  useEffect(() => {
    let user = null;
    let userValue = localStorage.getItem("user");
    try {
      user = JSON.parse(userValue);
      let forcePasswordChange = user.force_password_change;
      if (forcePasswordChange) {
        setShouldChangePassword(true);
      } else {
        setShouldChangePassword(false);
      }
    } catch (e) {
      setShouldChangePassword(false);
    }
  });
  return shouldChangePassword;
};

const usePageAccess = (key) => {
  const [hasAccess, setHasAccess] = useState(null);
  useEffect(() => {
    let user = null;
    let userValue = localStorage.getItem("user");
    try {
      user = JSON.parse(userValue);
      let pageAccess = user.PageAccess.find((x) => x.Name === key);
      if (pageAccess) {
        setHasAccess(true);
      } else {
        setHasAccess(false);
      }
    } catch (e) {
      setHasAccess(false);
    }
  });
  return hasAccess;
};

const usePageComponentAccess = (key) => {
  const [hasAccess, setHasAccess] = useState(null);
  useEffect(() => {
    let user = null;
    let userValue = localStorage.getItem("user");
    try {
      user = JSON.parse(userValue);
      let pageComponentAccess = user.PageComponentAccess.find(
        (x) => x.Name === key
      );
      if (pageComponentAccess) {
        setHasAccess(true);
      } else {
        setHasAccess(false);
      }
    } catch (e) {
      setHasAccess(false);
    }
  });
  return hasAccess;
};

export {
  useCheckLogin as useCheckLogin,
  useShouldChangePassword as useShouldChangePassword,
  usePageAccess as usePageAccess,
  usePageComponentAccess as usePageComponentAccess,
};
