import CryptoJS from "crypto-js";

const SECRET_KEY = "Ytdnjdfdkfnkdfhkdfkdfldfh37djrk";

export const encryptData = (data, salt) =>
  CryptoJS.AES.encrypt(JSON.stringify(data), salt || SECRET_KEY).toString();

export const decryptData = (ciphertext, salt) => {
  const bytes = CryptoJS.AES.decrypt(ciphertext, salt || SECRET_KEY);
  try {
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } catch (err) {
    return null;
  }
};
