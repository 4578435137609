import { FilterItems, RupeesFormat } from "@helpers/constants";
import { Box, HStack, Hidden, Stack, Text, VStack } from "native-base";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import CompetitionsResponsiveTable from "./Competitions_responsive_table";
import { Pagination } from "antd";
// import Beach from "@assets/images/menu/Beachsports.jpg";
import { useEffect } from "react";
import { dynamicRequest, useDynamicSelector } from "@services/redux";
import { get_cms_competitions_query } from "@services/redux/slices/dynamic_entity/graphql/graphql_cms_competitions_query";
import { useDispatch } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import TableLoading from "../events/table_loading";

const CompetitionsTable = () => {
  const [open_index, setOpenIndex] = useState(0);
  const [competition_data, setCompetitionData] = useState([]);
  const handleExpendable = (index) => {
    setOpenIndex(index);
  };
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { items: competition_items, loading } = useDynamicSelector(
    "get_cms_competitions"
  );

  const get_competitions = () => {
    let keys = [{ key: "get_cms_competitions", loading: true }];
    let variables = {
      json: {
        system_cms_key: "competition_table",
      },
    };
    dispatch(dynamicRequest(keys, get_cms_competitions_query, variables));
  };

  useEffect(() => {
    get_competitions();
  }, []);

  useEffect(() => {
    let values = FilterItems(competition_items);
    setCompetitionData(values.sort((a, b) => a?.title?.row_number - b?.title?.row_number));
  }, [competition_items]);
  

  return (
    <>
      {loading ? (
        <TableLoading />
      ) : (
        <>
          <Hidden from="base" till="md">
            <div>
              <table class="table">
                <thead className="table-header">
                  <tr>
                    <th className="radius-right">{t("table:s_no")}</th>
                    <th className="radius-left">
                      {t("table:name_of_competition")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {competition_data?.map((tablelist, index) => {
                    return (
                      <>
                        <tr
                          className={`space-add ${competition_data?.length - 1 !== index
                              ? "table-border-bottom"
                              : ""
                            }`}
                        >
                          <td style={{ alignItems: "flex-start" }}>
                            {index + 1}
                          </td>
                          <td>
                            <div
                              onClick={() => handleExpendable(index)}
                              className={`table_row ${open_index === index
                                  ? `table_row_height`
                                  : `table_row_normal`
                                }`}
                            >
                              {open_index === index ? (
                                <p className="competition_name">
                                  <HStack variant={"table_title_tag"}>
                                    {/* <Text width={"10px"}>-</Text> */}
                                    <Box width={"20px"}>
                                      <AiOutlineMinus />
                                    </Box>
                                    <Text variant={"table_title"}>
                                      {tablelist?.title?.value}
                                    </Text>
                                  </HStack>
                                </p>
                              ) : (
                                <p className="competition_name">
                                  <HStack variant={"table_title_tag"}>
                                    {/* <Text>+</Text> */}
                                    <Box width={"20px"}>
                                      <AiOutlinePlus />
                                    </Box>
                                    <Text variant={"table_title"}>
                                      {tablelist?.title?.value}
                                    </Text>
                                  </HStack>
                                </p>
                              )}
                              <div
                                className={`table_open ${open_index === index
                                    ? `table_active`
                                    : `table_inactive`
                                  }`}
                              >
                                <div className="description_contents">
                                  {tablelist?.description?.value}
                                </div>

                                <HStack width={"100%"}>
                                  <Box
                                    // flex={1}
                                    alignItems={"center"}
                                    padding={"10px"}
                                    width={"50%"}
                                  >
                                    <img
                                      src={tablelist?.image?.value}
                                      alt="fd"
                                      style={{ borderRadius: "10%" }}
                                      width={"70%"}
                                    />
                                  </Box>
                                  <VStack width={"100%"}>
                                    <Box justifyContent={"center"}>
                                      <div
                                        className="description_contents"
                                        style={{
                                          textAlign: "justify",
                                          width: "50%",
                                        }}
                                      >
                                        {ReactHtmlParser(
                                          tablelist?.list?.value
                                        )}
                                      </div>
                                    </Box>
                                    <Box
                                      width={"50%"}
                                      padding={"20px"}
                                      textAlign={"justify"}
                                    >
                                      <Text fontSize={"var(--font-size-14)"}>{tablelist?.content?.value}</Text>
                                    </Box>
                                  </VStack>
                                </HStack>
                                {/* ) : ( */}
                                {/* "" */}
                                {/* )} */}
                                <Text fontSize={"var(--font-size-14)"}>{tablelist?.note?.value}</Text>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </Hidden>
          <Hidden from="md" till="2xl">
            <div>
              <CompetitionsResponsiveTable TableData={competition_data} />
            </div>
          </Hidden>
          <div className="pagination-box">
            <Stack variant={"pagination_stack"}>
              <Hidden from="base" till="lg">
                <Box justifyContent={"center"}>
                  <Text>Records per page</Text>
                </Box>
              </Hidden>
              <Box variant={"pagination_box"}>
                <Pagination
                  total={85}
                  //   showTotal={(total) => `Records per page ${total} items`}
                  defaultPageSize={20}
                  defaultCurrent={1}
                />
              </Box>
            </Stack>
          </div>
        </>
      )}
    </>
  );
};

export default CompetitionsTable;
