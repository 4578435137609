import { HStack, Text, VStack, Box } from "native-base";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
// import imge2 from "@assets/images/menu/campion2.jpg";
import ReactHtmlParser from "react-html-parser";

const MobileResponsiveTable = (props) => {
  const [open_index, setOpenIndex] = useState(0);
  const { t } = useTranslation();
  const handleExpendable = (index) => {
    setOpenIndex(index);
  };
  const { TableData } = props;
  return (
    <div>
      <table class="responsive_table">
        <thead className="responsive_table-header">
          <tr>
            <th className="radius-right">{t("table:s_no")}</th>
            <th className="radius-left">{t("table:name_of_scheme")}</th>
          </tr>
        </thead>
        <tbody>
          {TableData?.map((tablelist, index) => {
            return (
              <>
                <tr
                  className={` ${
                    TableData.length - 1 !== index ? "table-border-bottom" : ""
                  }`}
                >
                  <td
                    style={{
                      alignItems: "start",
                      position: "absolute",
                      height: "20px",
                      padding: "25px",
                    }}
                  >
                    {index + 1}
                  </td>
                  <td>
                    <div
                      onClick={() => handleExpendable(index)}
                      className={`table_row ${
                        open_index === index
                          ? `table_row_height`
                          : `table_row_normal`
                      }`}
                    >
                      {open_index === index ? (
                        <p className="competition_name">
                          <HStack variant={"table_title_tag"}>
                            {/* <Text width={"10px"}>-</Text> */}
                            <Box width={"20px"}>
                              <AiOutlineMinus />
                            </Box>
                            <Text variant={"table_title"}>
                              {tablelist?.title?.value}
                            </Text>
                          </HStack>
                        </p>
                      ) : (
                        <p className="competition_name">
                          <HStack variant={"table_title_tag"}>
                            {/* <Text>+</Text> */}
                            <Box width={"20px"}>
                              <AiOutlinePlus />
                            </Box>
                            <Text variant={"table_title"}>
                              {tablelist?.title?.value}
                            </Text>
                          </HStack>
                        </p>
                      )}
                      <div
                        className={`table_open ${
                          open_index === index
                            ? `table_active`
                            : `table_inactive`
                        }`}
                      >
                        <div className="description_contents">
                          {tablelist?.description?.value}
                        </div>
                        <VStack space={"20px"}>
                          <img
                            src={tablelist?.image.value}
                            alt="fd"
                            style={{ borderRadius: "10%" }}
                            width={"90%"}
                          />
                          {/* {tablelist.children.map((s, index) => {
                            return (
                              <>
                                <Text bold fontSize={"20px"}>
                                  {s.medals}
                                </Text>
                                <table
                                  className="expendable-table"
                                  style={{ flex: 1 }}
                                >
                                  <thead>
                                    <tr className="expendable-table-header">
                                      <th className="radius-right">
                                        {t("table:sports_kit")}
                                      </th>
                                      <th className="radius-left">
                                        {t("table:quantity")}
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      className={`space ${
                                        TableData.length - 1 !== index
                                          ? "table-border-bottom"
                                          : ""
                                      }`}
                                    >
                                      <td>
                                        {s.sports_kit.map((a) => {
                                          return (
                                            <>
                                              <VStack
                                                paddingTop={1}
                                                paddingBottom={1}
                                              >
                                                <Text>{a.kits}</Text>
                                              </VStack>
                                            </>
                                          );
                                        })}
                                      </td>
                                      <td>
                                        {s.quantity.map((a) => {
                                          return (
                                            <>
                                              <VStack
                                                paddingTop={1}
                                                paddingBottom={1}
                                              >
                                                <Text>{a.items}</Text>
                                              </VStack>
                                            </>
                                          );
                                        })}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </>
                            );
                          })} */}
                          <Box w="50%">
                            {ReactHtmlParser(tablelist?.list?.value)}
                          </Box>
                        </VStack>
                        {/* <Text>{tablelist.bottom_content}</Text> */}
                      </div>
                    </div>
                  </td>
                </tr>
              </>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default MobileResponsiveTable;
