import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./admin-web-index.css";
import "./antd.css";
import { Provider, useSelector } from "react-redux";
import { NativeBaseProvider, extendTheme, theme as nbTheme } from "native-base";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IconContext } from "react-icons";
import { fontSizeSelector, store } from "@services/redux";
import App from "./App";
import "./i18n";
import { color_gradients } from "@helpers/constants";
import "swiper/css/bundle";
import "./styles.css";

const theme = extendTheme({
  colors: {
    primary: nbTheme.colors.violet,
    primaryContent: nbTheme.colors.warmGray,
    inputBorder: nbTheme.colors.coolGray,
    error: nbTheme.colors.red,
  },
  breakpoints: {
    base: 0,
    xs: 320,
    sm: 480,
    md: 768,
    lg: 1024,
    xl: 1440,
    "2xl": 2560,
    "3xl": 3840,
  },
  fonts: {
    Roboto: "Roboto-Regular",
    Marutham: "Marutham",
    // Nilavu: "Nilavu",
  },
  components: {
    Box: {
      variants: {
        vigilance_content_box: () => {
          return {
            alignItems: "center",
            justifyContent: "center",
            _text: {
              fontSize: "var(--font-size-1-8)",
              fontFamily: "Inter",
              fontWeight: "500",
            },
            marginBottom: "20px",
          };
        },
        vigilance_table_box: () => {
          return {
            style: {
              boxShadow: "0px 4px 15px rgba(0,0,0,0.08)",
              borderRadius: "10px",
              border: "0.1px solid rgba(0,0,0,0.08)",
            },
            padding: {
              base: "20px",
              xs: "10px",
              lg: "20px",
              xl: "20px",
              "2xl": "20px",
              "3xl": "20px",
            },
          };
        },
        infra_content_box: () => {
          return {
            width: {
              base: "50%",
              xs: "100%",
              sm: "100%",
              md: "100%",
              lg: "50%",
              xl: "50%",
              "2xl": "50%",
              "3xl": "50%",
            },
            justifyContent: "space-around",
            display: "flex",
            flexDirection: "column",
          };
        },
        infra_image_box: () => {
          return {
            width: {
              base: "45%",
              xs: "100%",
              sm: "100%",
              md: "100%",
              lg: "45%",
              xl: "45%",
              "2xl": "45%",
              "3xl": "45%",
            },
          };
        },
        pagination_box: () => {
          return {
            alignItems: {
              base: "",
              sm: "end",
              xs: "end",
              md: "end",
              lg: "",
              xl: "",
              "2xl": "",
              "3xl": "",
            },
          };
        },
        contact_us_welfareOfficers_card: () => {
          return {
            padding: "20px",
            style: {
              boxShadow: "0px 4px 15px rgba(0,0,0,0.08)",
              borderRadius: "10px",
            },
            justifyContent: "center",
            alignItems: "start",
          };
        },
        infrastructure_note_box: () => {
          return {
            background: "#FCEBDB",
            borderRadius: "20px",
          };
        },
        infrastructure_contents_box: () => {
          return {
            padding: "20px",
          };
        },
        infrastructure_note_title_box: () => {
          return {
            paddingTop: "10px",
            paddingBottom: "10px",
          };
        },
        infrastructure_table_box: () => {
          return {
            padding: "10px",
            width: "100%",
            alignItems: "center",
          };
        },
        xs_size_menu_headline: () => {
          return {
            marginTop: { xs: "", md: "-8.5vh" },
            marginLeft: "56px",
          };
        },
        tender_table_box: () => {
          return {
            marginBottom: "35px",
          };
        },
        tender_table_contents: () => {
          return {
            padding: "10px",
            marginRight: "10px",
            borderColor: "#E0E0E0",
            borderStyle: "solid",
            borderBottomWidth: 1,
            fontFamily: "Inter",
            textAlign: "justify",
          };
        },
        service_content_image_box: () => {
          return {
            position: "absolute",
            width: "100%",
            height: ["", "500px", "500px", "700px", "850px", "800px", ""],
            marginTop: "10vh",
            alignItems: "end",
            textAlign: "center",
          };
        },
        galleryAndFooterPage: () => {
          return {
            mt: "5rem",
            ml: {
              lg: "4rem",
            },
            mb: "3rem",
            width: "auto",
            hight: "60vh",
          };
        },
        UpcomingEventBox: () => {
          return {
            padding: { xs: "2", lg: "4" },
            borderRadius: "16",
            // shadow: "5",    boxShadow: "10px",
            // right={["0px", "60px", "0px"]}
            backgroundColor: "#ffffff",
            // width: {
            //   xs: "347px",
            //   sm: "100px",
            //   md: "400px",
            //   lg: "600px",
            //   xl: "660px",
            //   // "100%",
            // },
            width: [
              "347px",
              "347px",
              "500px",
              "400px",
              "600px",
              "600px",
              "800px",
              "900px",
            ],
            height: {
              xs: "80px",
              lg: "128px",
            },
            marginBottom: "3",
            // mt: {
            //   xs: "-3rem",
            //   md: "-2rem",
            //   lg: "-4rem",
            // },
            ml: {
              xs: "0",
              lg: "-80px",
              xl: "-100",
              md: 0,
            },

            zIndex: "5",
          };
        },
        menuBarBox: () => {
          return {
            justifyContent: "center",
            height: "75px",
            bgImage: color_gradients.transparent_color,
          };
        },
        UpcomingContentBox: () => {
          return {
            width: {
              xs: "16",
              lg: "24",
            },
            height: {
              xs: "16",
              lg: "24",
            },
            rounded: "xl",
            bg: ["#E6E6FA"],
          };
        },
        HomePageContentBox: () => {
          return {
            // mt: { xs: "10", sm: "10", md: "40", lg: "32", xl: "32" },
            // ml: { xs: "10", sm: "10", md: "16", lg: "20", xl: "32" },
          };
        },
        HomePageFirstBox: () => {
          return {
            mr: { xs: "170px", lg: "0" },
          };
        },
        about_us_second_component_first_image_box: () => {
          return {
            mt: { lg: "-200px" },
            ml: { xs: "32", lg: "140px" },
          };
        },
        HomePageSecondBox: () => {
          return {
            position: "absolute",
            mt: { xs: "60px", md: "16", lg: "60px", xl: "72px" },
            ml: { xs: "37px", md: "10", lg: "71px", xl: "70px", "2xl": "88px" },
            // mr: { xs: "0", md: "10", lg: "12" },
            // mr: { xs: "5" },
          };
        },
        about_us_second_component_second_image_box: () => {
          return {
            position: "absolute",
            mt: { xs: "8", md: "16", lg: "16" },
            // ml: { xs: "0", md: "10", lg: "12" },
            mr: { xs: "0", md: "10", lg: "14" },
            ml: { xs: "5" },
          };
        },
        HomePageThirdBox: () => {
          return {
            zIndex: "-1",
            position: "absolute",
            width: {
              base: "96",
              xs: "130px",
              md: "96",
            },
            height: {
              base: "20",
              md: "50",
            },
            mt: {
              xs: "253px",
              md: "240px",
              lg: "300px",
              xl: "380px",
              "2xl": "480px",
            },
            ml: { xs: "", md: "", lg: "", xl: "" },
            mr: { xs: "170px" },
          };
        },
        HomePageFourBox: () => {
          return {
            zIndex: "-1",
            position: "absolute",
            width: {
              base: "96",
              xs: "130px",
              md: "96",
            },
            height: {
              base: "20",
              md: "50",
            },
            mt: {
              xs: "15px",
              md: "12px",
              lg: "20px",
              xl: "50px",
              "2xl": "28px",
            },
            ml: {
              xs: "230px",
              md: "230px",
              lg: "350px",
              xl: "445px",
              "2xl": "550px",
            },
          };
        },
        about_us_second_component_third_image_box: () => {
          return {
            position: "absolute",
            width: {
              base: "96",
              xs: "130px",
              md: "96",
            },
            height: {
              base: "20",
              md: "50",
            },
            mt: { xs: "152px", md: "48", lg: "64", xl: "80" },
            mr: { xs: "32", md: "40", lg: "60", xl: "167px" },
          };
        },
        HomePageRightBox: () => {
          return {
            paddingRight: { xs: " ", lg: "80px" },
            // width: { xs: "400px" },
            textAlign: { xs: "center", sm: "center", md: "left", lg: "left" },
            // pr: {
            //   xs: "90px",
            // },
          };
        },
        HomePageDescriptionBox: () => {
          return {
            pl: { xs: "2rem", md: "0rem", lg: "0rem", xl: "0rem" },
            pr: { xs: "2rem", md: "0rem", lg: "0rem", xl: "0rem" },
            width: "110%",
            // marginTop: { xs: "0", lg: "10" },
            // marginRight: "24",
            // pl: { xs: "3", lg: "0" },
            alignItems: "center",
          };
        },
        HomePageButtonBox: () => {
          return {
            // marginTop: "12",
            // alignItems={{
            //   base: "center",
            //   xs: "center",
            //   md: "left",
            //   lg: "left",
            // }}
            alignItems: ["center", "center", "left", "left"],
            // marginLeft: { sm: "0", xs: "0", md: "3", lg: "0", xl: "5" },
          };
        },
        LatestNewsBox: () => {
          return {
            padding: 5,
            bgColor: "gray.100",
            height: "16",
            zIndex: -1,
          };
        },
        SportEventBox: () => {
          return {
            overflowY: "hidden",
            bgImage: color_gradients.sports_event_gradient,
            rounded: { xs: "xl", lg: "none" },
            width: { xs: "80", md: "100%", lg: "full" },
            height: { xs: "80", md: "48", lg: "180" },
            // marginTop: { xs: "16", md: "16", lg: "48", xl: "64" },
            marginTop: { xs: "", md: "", lg: "", xl: "28" },
          };
        },
        TNChampionsBox: () => {
          return {
            overflowY: "hidden",
            // bgImage: color_gradients.sports_event_gradient,
            rounded: { xs: "xl", lg: "none" },
            width: { xs: "80", md: "100%", lg: "full" },
            height: { xs: "80", md: "48", lg: "430px" },
            // marginTop: { xs: "16", md: "16", lg: "48", xl: "64" },
            marginTop: { xs: "2", md: "2", lg: "3", xl: "5" },
          };
        },
        BackGroundImageBox: () => {
          return {
            width: "100%",
            height: "207",
            position: "absolute",
            opacity: "0.2",
          };
        },
        SportBackGroundBox: () => {
          return {
            width: "100%",
            height: "100%",
            position: "absolute",
            opacity: "0.2",
          };
        },
        SportEventImageBox: () => {
          return {
            marginTop: { xs: "5", md: "10", lg: "6" },
            ml: { xs: "5", md: "5" },
          };
        },
        SportsContentBox: () => {
          return {
            marginTop: { xs: "10", md: "12", lg: "10" },
            marginLeft: { xs: "5", md: "5", lg: "0" },
          };
        },
        SportsEventImageBox: () => {
          return {
            marginTop: { xs: "5", lg: "5" },
            ml: { xs: "5", lg: "-10" },
          };
        },
        SportContentBox: () => {
          return {
            marginTop: { xs: "10", lg: "10" },
            marginLeft: { xs: "5", lg: "6" },
          };
        },
        About_us_history_contents_box: () => {
          return {
            bgImage: color_gradients.sports_event_gradient,
            display: "flex",
            flexDirection: "row",
            width: "100%",
            height: "150px",
          };
        },
        galleryImageShape: () => {
          return {
            // style: { transform: "skew(6deg)", objectFit: "cover" },
            style: { objectFit: "cover" },
            paddingLeft: "28px",
            width: {
              xs: "244px",
              md: "244px",
              lg: "260px",
              xl: "300px",
              "2xl": "500px",
            },
            height: {
              xs: "400px",
              md: "400px",
              lg: "450px",
              xl: "450px",
              "2xl": "812px",
            },
            overflowY: "hidden",
          };
        },
        SportsEventBox: () => {
          return {
            overflowY: "hidden",
            bgImage: color_gradients.sports_event_gradient,
            ml: { xs: "0" },
            rounded: { xs: "xl", lg: "none" },
            width: { xs: "80", lg: "full" },
            height: { xs: "72", lg: "180" },
            marginTop: { xs: "16", lg: "48" },
          };
        },
        TNCampionsBox: () => {
          return {
            overflowY: "hidden",
            // bgImage: color_gradients.sports_event_gradient,
            ml: { xs: "0" },
            rounded: { xs: "xl", lg: "none" },
            width: { xs: "80", lg: "full" },
            height: { xs: "600px", lg: "180" },
            marginTop: { xs: "16", lg: "48" },
          };
        },

        gallery_image_shape: () => {
          return {
            padding: "1rem",
            width: {
              xs: "244px",
              md: "345px",
              lg: "283px",
            },
            height: {
              xs: "312px",
              md: "450px",
            },
            position: "absolute",
            opacity: 0.2,
          };
        },
        GalleryContainer: () => {
          return {
            overflow: "inherit",
            ml: {
              md: "3rem",
            },
            mr: {
              md: "3rem",
            },
          };
        },
        GalleryContentBox: () => {
          return {
            width: {
              lg: "40rem",
              xl: "54rem",
            },
          };
        },
        TwitterContentContainer: () => {
          return {
            mb: "2",
            mt: "10px",
            // ml: { xs: "2rem" },
            width: { lg: "35vw", xs: "345px", "2xl": "800px" },
            // width: "auto",
            // height: { lg: "211px", xs: "340px", "2xl": "400px" },
            shadow: "5",
            rounded: "2xl",
            padding: 5,
            style: {
              boxShadow: "0px 14px 24px rgba(0, 0, 0, 0.08)",
            },
          };
        },
        TwitterContentLogo: () => {
          return {
            width: { base: "48px", "2xl": "58px" },
            height: { base: "48px", "2xl": "58px" },
          };
        },
        TwitterBoxDescription: () => {
          return {
            width:
              // lg: "390px",
              // xs: "315px",
              // "2xl": "600px"
              "auto",
            height: "auto",
            padding: "2",
            justifyContent: "center",
          };
        },
        // ViewMoreBoxButton: () => {
        //   return {
        //     width: "110px",
        //     height: "20px",
        //     fontFamily: "Poppins",
        //     borderColor: "blue",
        //   };
        // },
        box_shape: () => {
          return {
            // marginTop: "23rem",
            // marginRight: "4rem",
            width: "60px",
            height: "80px",
            transform: "skew(20deg)",
            alignItems: "end",
          };
        },
        card: () => {
          return {
            borderRadius: "20px",
            backgroundColor: "#F5F5F5",
            width: {
              xs: "360px",
              sm: "413px",
              // md: "220px",
              // lg: "294px",
              // lg: "28.6vw",
              md: "28.6vw",
              // xl: "413px",
              xl: "28.6vw",
            },
            height: {
              xs: "470px",
              sm: "600px",
              // md: "341px",
              // lg: "456px",
              // lg: "44.51vw",
              md: "44.51vw",
              xl: "44.51vw",
              "2xl": "44.51vw",
              "3xl": "44.51vw",
            },
            pt: {
              xs: "10px",
              // md: "10px",
              //  lg: "20px",
              // lg: "1.95vw",
              md: "1.95vw",
              xl: "30px",
            },
            pl: {
              base: "20px",
              md: "20px",
              lg: "25px",
              xl: "30px",
            },
            pr: {
              base: "36px",
              md: "10px",
              // lg: "30px",
              lg: "2.92vw",
              xl: "36px",
            },

            pt: "5",
            mt: "30px",
            mb: { base: "20px", md: "5px" },
            position: "relative",
          };
        },

        infoCard: () => {
          return {
            height: {
              base: "390px",
              // md: "212px ",
              // lg: "285px",
              // lg: "27.7vw",
              md: "26.7vw",
              lg: "28.7vw",
              // xl: "420px",
              xl: "calc(.6552*(28.6vw *1.552))",
            },
            mb: "10px",
          };
        },

        eventCard_info: () => {
          return {
            mb: {
              base: "15px",
              // md: "5px",
              // lg: "15px",
              // lg: "1vw",
              md: "1vw",
              xl: "15px",
            },
          };
        },

        calender: () => {
          return {
            height: {
              base: "60px",
              md: "50px",
              // lg: "60px",
              lg: "6.2vw",
              xl: "93px",
            },
            width: {
              base: "60px",
              md: "50px",
              // lg: "60px",
              lg: "5.85vw",
              xl: "93px",
            },

            mr: { xs: "2px", sm: "5", md: "1px", lg: "3px", xl: "5" },
            borderRadius: "13.92592716217041px",
            bg: "#E1DBEF",
          };
        },
        calenderDate: () => {
          return {
            height: {
              xs: "30px",
              sm: "47px",
              md: "25px",
              // lg: "30px",
              lg: "2.92vw",
              xl: "45px",
            },
            width: {
              xs: "24px",
              sm: "47px",
              // lg: "44px",
              lg: "4.296vw",
              xl: "45px",
            },
          };
        },
        calenderMonth: () => {
          return {
            width: {
              xs: "23px",
              sm: "47px",
              // lg: "44px",
              lg: "4.296vw",
              xl: "45px",
            },
            height: {
              xs: "24px",
              md: "15px",
              // lg: "24px",
              lg: "2.34vw",
              xl: "45px",
            },
          };
        },
        calenderYear: () => {
          return {
            width: {
              xs: "23px",
              sm: "47px",
              // lg: "44px",
              lg: "20px",
              xl: "45px",
            },
            height: {
              xs: "24px",
              md: "15px",
              // lg: "24px",
              lg: "20px",
              xl: "45px",
            },
          };
        },
        // cardButton: () => {
        //   return {
        //     backgroundColor: "#F5F5F5",
        //     height: {
        //       base: "64px",
        //       // md: "50px",
        //       // lg: "64px",
        //       // lg: "6.25vw",
        //       md: "6.25vw",
        //       xl: "64px",
        //     },
        //     width: {
        //       base: "313px",
        //       // md: "180px",
        //       // lg: "250px",
        //       // lg: "24.41vw",
        //       md: "24.41vw",
        //       xl: "370px",
        //     },
        //     justifyContent: "end",
        //     pb: { md: "5px", lg: "0px" },
        //   };
        // },
        //banner
        bannerBox: () => {
          return {
            width: "100%",
            height: "30px",
            justifyContent: "center",
            // ml: { xs: "0px", lg: "15px" },
            mr: { xs: "15px", lg: "15px" },
          };
        },
        switchBox: () => {
          return {
            width: "6rem",
            height: "1.5rem",
            fontSize: "var(--font-size-10)",
            // height: "16px"
          };
        },
        bannerContainer: () => {
          return {
            overflow: "hidden",
            height: {
              xs: "30rem",
              md: "33rem",
              lg: "45rem",
              xl: "50rem",
            },
          };
        },
        bannerBackgroundImage: () => {
          return {
            width: {
              sm: "16",
              xs: "56",
              md: "200px",
              lg: "300px",
              xl: "300px",
              "2xl": "500px",
            },
            height: {
              sm: "16",
              xs: "32",
              md: "130",
              lg: "180px",
              xl: "180px",
              "2xl": "260px",
            },
            mt: {
              base: 0,
              xs: "10rem",
              md: "12rem",
              lg: "15rem",
              xl: "15rem",
            },
            mr: {
              xs: "-3rem",
              lg: "20vw",
              xl: "200px",
              md: "12rem",
            },
            ml: {
              lg: "30rem",
              xl: "100vh",
              md: "8rem",
              "2xl": "80rem",
            },
          };
        },
        service_menu: () => {
          return {
            bgImage: color_gradients.sports_event_gradient,
          };
        },
        // Servicebox:()=>{
        //   return{
        //     width:"413px",
        //     height:"134px",
        //     display:"flex",
        //     flexDirection:"row",
        //     alignItems:"center"
        //   }
        // },
        Service_left: () => {
          return {
            alignItems: "start",
            width: "30%",
          };
        },
        Service_right: () => {
          return {
            width: "70%",
          };
        },
        BackgroundTextBoxLayer: () => {
          return {
            position: "absolute",
            width: "100%",
            height: "100%",
            bgImage: color_gradients.text_gradient,
          };
        },
        FooterBoxContainer: () => {
          return {
            // mt: { lg: "5rem" },
            backgroundColor: "#EAEAEA",
            justifyContent: "flex-end",
            // height: "435px",
          };
        },
        FooterRightBox: () => {
          return {
            padding: { lg: "32px" },
            justifyContent: "center",
            width: { xs: "100%", md: "419px", lg: "419px" },
            height: { xs: "115px", lg: "230px" },
          };
        },
        FooterRightBoxContent: () => {
          return {
            width: { lg: "98px", xs: "76px" },
            height: { lg: "97px", xs: "75px" },
            ml: { xs: "10rem", lg: "3rem" },
            mt: { xs: "-4rem", lg: "3rem" },
          };
        },
        FooterRightLogoBox: () => {
          return {
            width: { xs: "76px", lg: "85px" },
            height: { xs: "75px", lg: "86px" },
            mt: { xs: "-2rem" },
          };
        },
        FooterCopyRightBox: () => {
          return {
            width: "100%",
            height: "4rem",
            justifyContent: "center",
            textAlign: "center",
            backgroundColor: "#323232",
          };
        },
        FooterIconBoxContainer: () => {
          return {
            alignItems: { base: "start", xs: "center", lg: "start" },
            padding: { lg: "2rem" },
          };
        },
        FooterIconBox: () => {
          return {
            width: "25px",
            height: "25px",
            backgroundColor: "#2b1063",
            rounded: "20px",
            // mb: "2",
            cursor: "pointer",
            alignItems: "center",
          };
        },
        iconContainer: () => {
          return {
            width: "22px",
            height: "20px",
            background: "gray.300",
            borderRadius: "2",
            textAlign: "center",
            lineHeight: "0px",
          };
        },
        socialMediaIcons: () => {
          return {
            width: "16px",
            height: "16px",
            backgroundColor: "#2b1063",
            rounded: "20px",
            textAlign: "center",
          };
        },
        bannerAllImages: () => {
          return {
            position: "absolute",
            width: "full",
            height: "full",
            alignItems: "end",
            // bgImage:
            //   "linear-gradient(45deg, rgb(31 0 95), rgb(167 18 173 / 58%))",
            opacity: 100,
            pt: "5rem",
          };
        },
        backgroundImageOne: () => {
          return {
            width: {
              sm: "16",
              xs: "32",
              md: "220px",
              lg: "300px",
              xl: "300px",
              "2xl": "400px",
            },

            height: {
              sm: "16",
              xs: "94px",
              md: "40",
              lg: "48",
              xl: "48",
            },
            mt: {
              base: 0,
              xs: 5,
              md: "8",
              lg: "20",
              xl: "20",
            },
            mr: {
              xs: -24,
              md: "100px",
              lg: "160px",
              xl: "160px",
            },
          };
        },
        backgroundImageTwo: () => {
          return {
            width: {
              sm: "16",
              xs: "40",
              md: "180px",
              lg: "240px",
              xl: "240px",
              "2xl": "400px",
            },
            height: {
              sm: "16",
              xs: "110px",
              md: "100px",
              lg: "32",
              xl: "160px",
              "2xl": "160px",
            },
            mt: {
              base: 0,
              xs: "19px",
              md: 5,
              lg: "56px",
              xl: "50px",
              "2xl": "50px",
            },
            mr: {
              xs: "-3rem",
              md: "120px",
              lg: "200px",
              xl: "200px",
            },
          };
        },
        backgroundImageThree: () => {
          return {
            zIndex: 1,
            position: "absolute",
            width: {
              sm: "16",
              xs: "8rem",
              md: "48",
              lg: "240px",
              xl: "240px",
              "2xl": "350px",
            },
            height: {
              sm: "16",
              xs: "104px",
              md: "120px",
              lg: "40",
              xl: "200px",
            },
            mt: {
              base: 0,
              xs: "6px",
              md: "5px",
              lg: "50px",
              xl: "10px",
              "2xl": "10px",
            },
            mr: {
              xs: "120px",
              md: "24rem",
              lg: "31rem",
              xl: "31rem",
              "2xl": "40rem",
            },
          };
        },
        backgroundImageFour: () => {
          return {
            zIndex: 1,
            position: "absolute",
            width: {
              sm: "16",
              xs: "109px",
              md: "48",
              lg: "72",
              xl: "72",
              "2xl": "340px",
            },
            height: {
              sm: "16",
              xs: "104px",
              md: "140px",
              lg: "170px",
              xl: "170px",
            },
            mt: {
              base: 0,
              xs: "41px",
              md: "20",
              lg: "40",
              xl: "40",
              "2xl": "170px",
            },
            mr: {
              xs: "42px ",
              md: "15.5rem",
              lg: "20rem",
              xl: "20rem",
              "2xl": "28rem",
            },
          };
        },
        backgroundImageFifth: () => {
          return {
            zIndex: 1,
            position: "absolute",
            width: {
              sm: "16",
              xs: "142px",
              md: "48",
              lg: "64",
              xl: "64",
              "2xl": "360px",
            },
            height: {
              sm: "16",
              xs: "6.5rem",
              md: "32",
              lg: "48",
              xl: "48",
            },
            mt: {
              base: 0,
              xs: "9.4rem",
              md: "13rem",
              lg: "19rem",
              xl: "19rem",
              "2xl": "340px",
            },
            mr: {
              xs: "20",
              md: "20rem",
              lg: "29rem",
              xl: "29rem",
              "2xl": "40rem",
            },
          };
        },
        tender_page_menubox: () => {
          return {
            bgImage: color_gradients.sports_event_gradient,
          };
        },
        executive_image_box: () => {
          return {
            width: "150px",
            position: "absolute",
            marginTop: "-90px",
            zIndex: "1",
            style: { borderRadius: "50%" },
          };
        },
        executive_text_box: () => {
          return {
            display: "flex",
            flexDirection: "column",
            // justifyContent: "flex-end",
            paddingLeft: "10px",
            paddingRight: "10px",
            fontFamily: "Inter",
            height: "100%",
            maxHeight: "500px",
            minHeight: "140px",
            marginTop: "70px",
          };
        },
        contact_us_card_box: () => {
          return {
            width: "auto",
            alignItems: "center",
            display: "flex",
            flexDirection: { base: "column", md: "column", lg: "row" },
            justifyItems: "center",
            height: "auto",
            style: {
              boxShadow: "0px 4px 15px rgba(0,0,0,0.08)",
              borderRadius: "10px",
            },
            padding: "30px",
          };
        },
      },
    },
    Text: {
      variants: {
        contact_us_card_heading: () => {
          return {
            fontSize: {
              base: "var(--font-size-2)",
              xs: "var(--font-size-2-5)",
              md: "var(--font-size-3-8)",
              lg: "var(--font-size-3)",
              xl: "var(--font-size-3-8)",
              "2xl": "var(--font-size-3-8)",
              "3xl": "var(--font-size-3-8)",
            },
            fontFamily: "Inter",
            fontWeight: "bold",
          };
        },
        contact_us_address: () => {
          return {
            maxW: "450px",
            fontSize: "var(--font-size-2)",
            color: "#333333",
            fontWeight: "450",
            fontFamily: "Inter",
          };
        },
        district_sports_name_title: () => {
          return {
            color: "black",
            marginLeft: "1px",
            fontWeight: "500",
          };
        },
        district_sports_email: () => {
          return {
            color: "black",
            fontWeight: "500",
            marginLeft: "1px",
          };
        },
        contact_details_title: () => {
          return {
            fontWeight: "bold",
            fontSize: "var(--font-size-2)",
            fontFamily: "Inter",
            color: "black",
          };
        },
        contact_details: () => {
          return {
            fontSize: "var(--font-size-2)",
            fontFamily: "Inter",
            fontWeight: "450",
            color: "black",
            marginTop: {
              base: "5px",
              sm: "5px",
              xs: "5px",
              md: "5px",
              lg: "5px",
              xl: "5px",
              "2xl": "2.5px",
              "3xl": "2.5px",
            },
          };
        },
        quick_lick_text: () => {
          return {
            fontWeight: "bold",
            fontFamily: "Inter",
          };
        },
        table_title: () => {
          return {
            color: "#2f80ed",
            fontFamily: "Inter",
            fontWeight: 600,
            fontSize: "var(--font-size-14)",
          };
        },
        infrastructure_table_title: () => {
          return {
            fontFamily: "Inter",
            fontSize: "var(--font-size-18)",
            fontWeight: "bold",
          };
        },
        xs_size_menu_headline_one: () => {
          return {
            fontSize: "var(--font-size-3-5)",
            fontFamily: "Nilavu",
            color: "white",
          };
        },
        xs_size_menu_headline_two: () => {
          return {
            fontSize: "var(--font-size-2-8)",
            color: "white",
            fontWeight: "500",
          };
        },
        tender_description: () => {
          return {
            color: "blue.500",
          };
        },
        table_headline: () => {
          return {
            color: "#828282",
          };
        },
        NumberFormat: () => {
          return {
            fontFamily: "BebasNeue",
            fontSize: { xs: "var(--font-size-28)", lg: "var(--font-size-42)" },
            fontWeight: "400",
          };
        },
        YearFormat: () => {
          return {
            fontFamily: "BebasNeue",
            fontSize: { xs: "var(--font-size-20)", lg: "var(--font-size-30)" },
            fontWeight: "400",
          };
        },
        MonthFormat: () => {
          return {
            fontFamily: "BebasNeue",
            fontSize: { xs: "var(--font-size-22)", lg: "var(--font-size-30)" },
            fontWeight: "38px",
          };
        },
        HeadLineTextOne: () => {
          return {
            fontSize: { xs: "var(--font-size-36)", lg: "var(--font-size-36)" },
            //fontSize={["var(--font-size-20)", "var(--font-size-30)", "var(--font-size-40)"]}
            color: "#3B1B7E",
            fontWeight: "light",
            fontFamily: "BebasNeue",
          };
        },
        executive_title: () => {
          return {
            fontWeight: "bold",
            fontSize: { xs: "var(--font-size-30)", lg: "var(--font-size-20)" },
            color: "black",
            // fontFamily: "BebasNeue",
            marginBottom: "2px",
          };
        },
        executive_position: () => {
          return {
            fontWeight: "bold",
            fontSize: "var(--font-size-15)",
            color: "black",
            fontFamily: "Inter",
            marginBottom: "2px",
          };
        },
        executive_address: () => {
          return {
            fontSize: "var(--font-size-12)",
            fontFamily: "Inter",
            width: "250px",
            marginBottom: "2px",
          };
        },
        history_headline_text_one: () => {
          return {
            fontSize: { xs: "var(--font-size-36)", lg: "var(--font-size-36)" },
            //fontSize={["var(--font-size-20)", "var(--font-size-30)", "var(--font-size-40)"]}
            color: "#fff",
            fontWeight: "light",
            fontFamily: "BebasNeue",
          };
        },
        HeadLineTextTwo: () => {
          return {
            marginTop: "1",
            fontWeight: "light",
            fontSize: { xs: "xl", lg: "var(--font-size-25)" },
            fontFamily: "BebasNeue",
          };
        },
        DescriptionText: () => {
          return {
            fontSize: { xs: "sm", lg: "var(--font-size-16)" },
            textAlign: { xs: "center", md: "start", lg: "start" },
            fontFamily: "'Inter', sans-serif",
          };
        },
        SportHeadingText: () => {
          return {
            fontSize: { xs: "md", md: "var(--font-size-18)", lg: "var(--font-size-30)" },
            fontWeight: { xs: "bold", md: "bold", lg: "bold" },
            fontFamily: "Inter",
            color: "white",
            width: { xs: "40", md: "100%", lg: "full" },
          };
        },
        TNchampitionText: () => {
          return {
            fontSize: { xs: "md", md: "lg", lg: "3xl" },
            fontWeight: { xs: "bold", md: "bold", lg: "bold" },
            fontFamily: "Inter",
            color: "white",
            width: { xs: "100%", md: "100%", lg: "full" },
          };
        },
        SportsContentText: () => {
          return {
            textAlign: "start",
            width: { xs: "sm", md: "sm", lg: "756px" },
            fontFamily: "Inter",
            color: "white",
            fontSize:{md:"var(--font-size-12)",lg:"var(--font-size-14)"}
          };
        },
        ChampionContentText:()=>{
          return {
            textAlign: "start",
            width: "100%",
            fontFamily: "Inter",
            color: "black",
          };
        },
        SportEventContentText: () => {
          return {
            fontSize: { xs: "var(--font-size-14)", lg: "2xl" },
            fontWeight: { xs: "bold", lg: "bold" },
            color: "white",
            width: { xs: "40", lg: "56" },
            fontFamily: "'Inter', sans-serif",
          };
        },
        SportsDescriptionText: () => {
          return {
            paddingLeft: { xs: "3" },
            paddingRight: { xs: "5" },
            mt: { xs: "3" },
            ml: { xs: "3" },
            fontSize: { xs: "var(--font-size-14)" },
            textAlign: "start",
            width: { xs: "300px", lg: "xl" },
            color: "white",
            fontFamily: "'Inter', sans-serif",
          };
        },
        RightHeadLine: () => {
          return {
            color: "#3B1B7E",
            fontWeight: "light",
            fontSize: { xs: "14px", lg: "var(--font-size-21)" },
            fontFamily: "BebasNeue",
          };
        },
        RightSecondHeadLine: () => {
          return {
            fontWeight: "bold",
            fontSize: { xs: "var(--font-size-14)", lg: "var(--font-size-18)" },
            fontFamily: "'Inter', sans-serif",
          };
        },
        RightDescription: () => {
          return {
            fontFamily: "'Inter', sans-serif",
            fontSize: { xs: "var(--font-size-12)", lg: "var(--font-size-14)" },
          };
        },
        tamil_header: () => {
          return {
            fontfamily: "Nilavu",
          };
        },
        header: () => {
          return {
            color: "#3B1B7E",
            fontWeight: "400",
            fontFamily: "BebasNeue",
            fontSize: {
              base: "var(--font-size-32)",
              md: "var(--font-size-25)",
              // lg: "var(--font-size-35)",
              lg: "var(--font-size-2-9)",
              xl: "var(--font-size-42)",
            },
            mt: { base: "15px", md: "0px", lg: "0px", xl: "10px" },
            mb: { base: "15px", md: "0px", lg: "22px", xl: "30px" },
            height: "50px",

            cursor: "pointer",
          };
        },
        TwitterTitleOne: () => {
          return {
            fontSize: {
              lg: "var(--font-size-04)",
              xs: "var(--font-size-65)",
              xl: "var(--font-size-80)",
              "2xl": "var(--font-size-120)",
            },
            // ml: { xs: "3rem", lg: "0rem" },
            fontWeight: "900",
            color: "#f4f4f4",
            fontFamily: "Inters",
          };
        },
        TwitterTitleTwo: () => {
          return {
            mr: {
              lg: "",
              xs: "0rem",
            },
            position: "absolute",
            fontWeight: "100",
            fontSize: {
              base: "var(--font-size-28)",
              xs: "var(--font-size-40)",
              lg: "var(--font-size-3-5)",
              xl: "var(--font-size-33)",
              "2xl": "var(--font-size-40)",
            },
            color: "#43267f",
            fontFamily: "BebasNeue",
          };
        },
        TwitterBoxContentTitle: () => {
          return {
            fontSize: { "2xl": "var(--font-size-25)" },
            fontWeight: "bolder",
            fontFamily: "Inter",
            color: "#000000",
          };
        },
        TwitterContentTag: () => {
          return {
            fontSize: { "2xl": "var(--font-size-20)" },
            fontFamily: "Inter",
            color: "#000000",
            fontWeight: "400",
          };
        },
        TwitterDescriptionContent: () => {
          return {
            fontSize: {
              lg: "var(--font-size-1-1)",
              "2xl": "var(--font-size-2)",
            },
            justifyContent: "center",
            letterSpacing: "1px",
            fontFamily: "Inter",
            fontWeight: "400",
            color: "#000000",
            textAlign: "justify",
          };
        },
        BannerEnglishTitle: () => {
          return {
            letterSpacing: { base: "5px", xs: "2px" },
            style: {
              textShadowColor: "black",
              textShadowOffset: { width: 1, height: 0 },
              textShadowRadius: 5,
            },
            fontFamily: "Nilavu",
            fontSize: {
              xs: "var(--font-size-36)",
              md: "var(--font-size-60)",
              lg: "var(--font-size-10)",
              xl: "var(--font-size-10)",
              "2xl": "var(--font-size-10)",
            },
            // width: {
            //   md: "30rem",
            //   lg: "40rem",
            //   xl: "50rem",
            //   // "2xl": "60rem"
            // },
            mt: {
              xs: "11rem",
              md: "11rem",
            },
            // ml: {
            //   xs: "1rem",
            //   md: "3rem",
            // },
            color: "white",
            fontWeight: "bold",
          };
        },
        BannerTamilTitle: () => {
          return {
            width: "100%",
            // width: {
            //   xs: "260px",
            //   md: "337px",
            //   lg: "460px",
            //   xl: "",
            // },
            style: {
              textShadowColor: "black",
              textShadowOffset: { width: 1, height: 0 },
              textShadowRadius: 5,
            },
            ml: "1rem",
            fontSize: {
              xs: "1.5vh",
              md: "var(--font-size-18)",
              lg: "var(--font-size-17)",
              xl: "var(--font-size-29)",
              "2xl": "var(--font-size-35)",
            },
            // ml: {
            //   xs: "3rem",
            //   md: "6rem",
            //   lg: "5rem",
            // },
            color: "white",
            fontWeight: "bold",
          };
        },
        announcementCard_txt: () => {
          return {
            fontFamily: "Inter",
            fontSize: {
              xs: "var(--font-size-13)",
              // md: "var(--font-size-10)",
              // lg: "var(--font-size-12)",
              // lg: "var(--font-size-1-171)",
              md: "var(--font-size-1-171)",
              lg: "var(--font-size-13)",
              xl: "calc(11px + 0.35625vw)",
            },
            fontWeight: "450",
            mb: {
              base: "20px",
              // md: "8px",
              // lg: "20px",
              // lg: "1.953vw",
              md: "1.953vw",
              xl: "30px",
            },
          };
        },
        tendersCard_txt: () => {
          return {
            fontFamily: "Inter",
            fontSize: {
              base: "var(--font-size-13)",
              // md: "var(--font-size-10)",
              // lg: "var(--font-size-12)",
              // lg: "var(--font-size-1-171)",
              md: "var(--font-size-1-171)",
              lg: "var(--font-size-13)",
              xl: "calc(11px + 0.35625vw)",
            },
            fontWeight: "450",
            mb: {
              base: "30px",
              // md: "15px",
              // lg: "20px",
              // lg: "1.953vw",
              md: "1.953vw",
              xl: "30px",
            },
          };
        },

        eventCardCalenderDate: () => {
          return {
            fontFamily: "BebasNeue",
            fontSize: {
              xs: "28.82px",
              sm: "var(--font-size-42)",
              md: "var(--font-size-28)",
              // lg: "var(--font-size-33)",
              lg: "var(--font-size-3-2)",
              xl: "var(--font-size-42)",
            },
            fontWeight: "400",
          };
        },
        eventCardCalenderMonth: () => {
          return {
            fontFamily: "BebasNeue",
            fontSize: {
              xs: "20.26px",
              sm: "var(--font-size-30)",
              md: "var(--font-size-20)",
              // lg: "var(--font-size-25)",
              lg: "var(--font-size-2-4)",
              xl: "var(--font-size-30)",
            },
            fontWeight: "35px",
          };
        },
        eventCardCalenderYear: () => {
          return {
            fontFamily: "BebasNeue",
            fontSize: {
              xs: "var(--font-size-20)",
              sm: "var(--font-size-21)",
              md: "var(--font-size-20)",
              lg: "var(--font-size-30)",
              xl: "var(--font-size-30)",
              "2xl": "var(--font-size-30)",
            },
            fontWeight: "35px",
          };
        },
        eventCard_htxt: () => {
          return {
            fontFamily: "Inter",
            fontSize: {
              base: "var(--font-size-13)",
              // md: "var(--font-size-10)",
              // lg: "13px",
              // lg: "var(--font-size-1-6)",
              md: "var(--font-size-1-6)",
              lg: "var(--font-size-13)",
              xl: "calc(12px + 0.35625vw)",
            },
            mb: {
              base: "1px",
              md: "2px",
              xl: "9px",
            },
            lineHeight: { base: "1.2em", xl: "1.4em" },
          };
        },
        eventCard_btxt: () => {
          return {
            fontFamily: "Inter",
            fontSize: {
              base: "var(--font-size-13)",
              // md: "var(--font-size-19)",
              // lg: "var(--font-size-12)",
              // lg: "var(--font-size-1-171)",
              md: "var(--font-size-1-171)",
              lg: "var(--font-size-12)",
              xl: "calc(11px + 0.35625vw)",
            },
            color: "gray.500",
          };
        },
        cardButton_Txt: () => {
          return {
            fontFamily: "Inter",
            fontWeight: "450",
            color: "white",
            fontSize: {
              base: "var(--font-size-15)",
              md: "var(--font-size-1-111)",
              xl: "var(--font-size-1-2)",
            },
          };
        },
        FooterRightBoxTitle: () => {
          return {
            width: {
              xs: "100%",
              lg: "300px",
            },
            fontWeight: "500",
            fontSize: { xs: "var(--font-size-18)", lg: "var(--font-size-22)" },
            fontFamily: "BebasNeue",
          };
        },
        FooterCopyRightContent: () => {
          return {
            color: "#ffffffb5",
            fontSize: {
              base: "var(--font-size-14)",
              xs: "var(--font-size-12)",
            },
            // width: { base: "100%", xs: "68%" },
          };
        },
        FooterAddressBox: () => {
          return {
            width: {
              xs: "16rem",
              lg: "300px",
            },
            fontFamily: "Helvetica",
            color: "#131313",
            fontWeight: "100",
            marginTop: "10px",
          };
        },
        page_title: () => {
          return {
            fontSize: "var(--font-size-30)",
            fontFamily: "Inter",
            color: "white",
            fontWeight: "600",
            padding: "30px",
          };
        },
      },
    },
    Button: {
      variants: {
        cardButton: () => {
          return {
            backgroundColor: "",
            shadow: 2,
            borderRadius: { base: "10" },
            borderWidth: "1.3px",
            // width: {
            //   base: "120px",
            //   md: "9.444vw",
            //   // lg: "9.444vw"
            //   xl: "9.5vw",
            // },
            width: "fit-content",
            height: "fit-content",
            // height: {
            //   base: "48px",
            //   md: "3.3333vw",
            //   // lg: "3.3333vw"
            //   xl: "calc(.35*(9.5vw))",
            // },
            mb: { xl: "30px" },
            borderColor: "violet.900",
          };
        },
      },
    },
    Image: {
      variants: {
        bannerImages: () => {
          return {
            size: "100%",
            resizeMode: "cover",
            rounded: "10px",
          };
        },
        ImageFirst: () => {
          return {
            opacity: { xs: "0.4px", md: "0.4px", lg: "0.4px" },
            size: {
              xs: "120px",
              sm: "250",
              md: "110px",
              lg: "150",
              xl: "140px",
              "2xl": "185",
            },
            rounded: "xl",
          };
        },
        ImageSecond: () => {
          return {
            size: {
              xs: "230",
              sm: "200",
              md: "240",
              lg: "320",
              xl: "400",
              "2xl": "500",
            },
            rounded: "xl",
            alt: "fallback text",
          };
        },
        ImageThird: () => {
          return {
            opacity: { xs: "0.4px", md: "0.4px", lg: "0.4px" },
            size: {
              xs: "130",
              sm: "130",
              md: "150",
              lg: "180",
              xl: "200",
              "2xl": "230",
            },
            resizeMode: "cover",
            alt: "fallback text",
            rounded: "xl",
          };
        },
        ImageFourth: () => {
          return {
            opacity: { xs: "0.4px", md: "0.4px", lg: "0.4px" },
            size: {
              xs: "130",
              sm: "130",
              md: "150",
              lg: "180",
              xl: "180",
              "2xl": "220",
            },
            resizeMode: "cover",
            alt: "fallback text",
            rounded: "xl",
          };
        },
        SportEventBackGroundImage: () => {
          return {
            size: "100%",
            resizeMode: "cover",
            alt: "image",
          };
        },
        SportsBackGroundImage: () => {
          return {
            size: { xs: "100%" },
            resizeMode: "cover",
            alt: "image",
          };
        },
      },
    },
    Center: {
      variants: {
        galleryAndTwitterTitle: () => {
          return {
            width: { base: "100%", md: "50%", lg: "50%" },
            alignItems: {
              base: "start",
              xs: "center",
              lg: "Start",
              md: "center",
            },
          };
        },
      },
    },
    Stack: {
      variants: {
        infra_content_stack: () => {
          return {
            display: "flex",
            flexDirection: {
              base: "row",
              xs: "column",
              md: "column",
              xl: "row",
              lg: "row",
              "2xl": "row",
              "3xl": "row",
            },
            width: "100%",
            justifyContent: "space-evenly",
            space: "20px",
            marginTop: "30px",
          };
        },
        pagination_stack: () => {
          return {
            width: "100%",
            justifyContent: "space-between",
            fontFamily: "Inter",
            display: "flex",
            flexDirection: {
              base: "row",
              xs: "column",
              sm: "column",
              xl: "row",
              lg: "row",
              "2xl": "row",
              "3xl": "row",
            },
          };
        },
        galleryPageContent: () => {
          return {
            display: "flex",
            flexDirection: { xs: "column", lg: "row", md: "row" },
            // justifyContent: "space-evenly"
            style: {
              gap: "2rem",
            },
            justifyContent: ["center", "center", "center", "center", ""],
          };
        },
      },
    },
    Card: {
      variants: {
        service_card: () => {
          return {
            background: "white",
            cursor: "pointer",
            width: [
              "350px",
              "350px",
              "350px",
              "350px",
              "350px",
              "350px",
              "413px",
            ],
            height: [
              "100px",
              "100px",
              "100px",
              "100px",
              "100px",
              "100px",
              "134px",
            ],
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            borderRadius: "12px",
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.05)",
          };
        },
      },
    },
    HStack: {
      variants: {
        // contact_content_title_align: () => {
        //   return {
        //     justifyContent: "space-around",
        //     alignItems: "center",
        //     width: {
        //       base: "70px",
        //       xs: "70px",
        //       sm: "70px",
        //       md: "90px",
        //       lg: "50px",
        //       xl: "var(--font-size-80)",
        //       "2xl": "100px",
        //       "3xl": "100px",
        //     },
        //   };
        // },
        contact_content_title_align: () => {
          return {
            justifyContent: "space-between",
            alignItems: "center",
            width: {
              base: "75px",
              xs: "75px",
              sm: "75px",
              md: "75px",
              lg: "206px",
              xl: "206px",
              // "2xl": "100px",
              // "3xl": "100px",
            },
          };
        },
        contact_content_title_aligns: () => {
          return {
            justifyContent: "space-around",
            alignItems: "center",
            width: {
              base: "140px",
              // xs: "70px",
              // sm: "70px",
              // md: "90px",
              lg: "160px",
              // xl: "80px",
              // "2xl": "100px",
              // "3xl": "100px",
            },
          };
        },
        contact_us_address_details: () => {
          return {
            // space: "2vh",
            space: "20px",
          };
        },
        table_title_tag: () => {
          return {
            space: 1,
            alignItems: "center",
            height: "60px",
            width: "100%",
          };
        },
      },
    },
  },
});
ReactDOM.render(
  <Provider store={store}>
    <NativeBaseProvider theme={theme}>
      <IconContext.Provider value={{ className: "global-class-name" }}>
        <App />
        <ToastContainer newestOnTop pauseOnFocusLoss />
      </IconContext.Provider>
    </NativeBaseProvider>
  </Provider>,
  document.getElementById("root")
);
