import { Box, HStack, Hidden, Link, Stack, Text } from "native-base";
import React from "react";
import { useTranslation } from "react-i18next";
import "../tender/table.css";
import { Pagination, Select, Tooltip } from "antd";
import {
  dynamicRequest,
  get_system_cms_field,
  get_system_cms_value_clear,
  getsystemCMSFieldListSelector,
  useDynamicSelector,
} from "@services/redux";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FilterItems } from "@helpers/constants";
import { useState } from "react";
import moment from "moment";
import { get_cms_event_table_query } from "@services/redux/slices/dynamic_entity/graphql/graphql_cms_event_table_query";
import EventTableLoading from "./table_loading";
import TableLoading from "./table_loading";
const EventsTable = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [event_data, setEventData] = useState([]);

  // const { items } = useSelector(getsystemCMSFieldListSelector);
  const { items: event_items, loading } = useDynamicSelector(
    "get_cms_event_table"
  );

  // useEffect(() => {
  //   dispatch(
  //     get_system_cms_field({
  //       json: {
  //         system_cms_key: "event_table",
  //       },
  //     })
  //   );
  // }, []);

  const get_event_table = () => {
    let keys = [{ key: "get_cms_event_table", loading: true }];
    let variables = {
      json: {
        system_cms_key: "event_table",
      },
    };
    dispatch(dynamicRequest(keys, get_cms_event_table_query, variables));
  };

  useEffect(() => {
    get_event_table();
  }, []);

  useEffect(() => {
    let values = FilterItems(event_items);
    setEventData(values);
  }, [event_items]);

  const options = [
    {
      id: "1",
      label: "2001",
    },
  ];
  const handleChange = (event) => {
    console.log("handleChange", event);
  };
  return (
    <>
      {loading ? (
        <TableLoading />
      ) : (
        <div>
          <HStack padding={"10px"} alignItems={"center"} space={3}>
            <Text>{t("year_of_events")}</Text>
            <Select
              placeholder={"Select the year"}
              style={{ width: "150px" }}
              fieldNames={"year"}
              options={options}
              onChange={handleChange}
            />
          </HStack>
          <Hidden from="base" till="lg">
            <div>
              <table class="table">
                <thead className="table-header">
                  <tr>
                    <th className="radius-right">{t("s.no")}</th>
                    <th>{t("event_date")}</th>
                    <th className="radius-left">{t("event_title")}</th>
                  </tr>
                </thead>
                <tbody>
                  {event_data.map((tablelist, index) => {
                    return (
                      <tr
                        className={`space-add ${
                          event_data.length - 1 !== index
                            ? "table-border-bottom"
                            : ""
                        }`}
                        key={index}
                      >
                        <td data-label="S.No">{index + 1}</td>
                        <td data-label="Closing Date" className="column-width">
                          {moment(tablelist?.date?.value).format("MMMM YYYY")}
                        </td>
                        <td data-label="Tenders">
                          <a target="_blank" className="application-form-title">
                            {tablelist?.event_name?.value}
                          </a>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </Hidden>
          <Hidden from="lg" till="3xl">
            <Box variant={"tender_table_box"}>
              {event_data.map((content, index) => {
                return (
                  <Box variant={"tender_table_contents"} key={index}>
                    <Text variant={"tender_description"}>
                      {content?.event_name?.value}
                    </Text>
                    <Text>
                      <Text variant={"table_headline"} color={"#828282"}>
                        {t("event_date")}:
                      </Text>
                      <Text
                        color={"black"}
                        fontWeight={"500"}
                        marginLeft={"1px"}
                      >
                        {moment(content?.data?.value).format("MMMM YYYY")}
                      </Text>
                    </Text>
                    {/* <Text>
                    <Text variant={"table_headline"} color={"#828282"}>
                      {t("application_form")}:
                    </Text>
                    <Text color={"black"} fontWeight={"500"} marginLeft={"1px"}>
                      <a
                        // href={content.application_form}
                        target="_blank"
                        className="application-form-title"
                      >
                        {t("view_&_download")}
                      </a>
                    </Text>
                  </Text> */}
                  </Box>
                );
              })}
            </Box>
          </Hidden>
          <div className="pagination-box">
            <Stack variant={"pagination_stack"}>
              <Hidden from="base" till="lg">
                <Box justifyContent={"center"}>
                  <Text>Records per page</Text>
                </Box>
              </Hidden>
              <Box variant={"pagination_box"}>
                <Pagination
                  total={85}
                  //   showTotal={(total) => `Records per page ${total} items`}
                  defaultPageSize={20}
                  defaultCurrent={1}
                />
              </Box>
            </Stack>
          </div>
        </div>
      )}
    </>
  );
};

export default EventsTable;
