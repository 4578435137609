import {
  Box,
  Stack,
  VStack,
  Image,
  Text,
  Center,
  HStack,
  Hidden,
  Button,
  Pressable,
  Tooltip,
} from "native-base";
// import KabadiImage from "@assets/images/menu/kabadimage.png";
// import GroundImage from "@assets/images/menu/groundimage1.jpg";
// import GroundImage2 from "@assets/images/menu/news/stadium.jpg";
// import Swimming from "@assets/images/menu/news/SDAT AQUATIC Complex.jpg";
// import Running from "@assets/images/menu/news/DSC01262.jpg";
// import Hockey from "@assets/images/menu/news/JNS TOP VIEW.jpg";
// // import RunSport from "@assets/images/menu/running.jpg";
// import Cricket from "@assets/images/menu/cricket.jpg";
// import HockeySport from "@assets/images/menu/hockey1.jpg";
// import SportTeam from "@assets/images/menu/sportteam.jpg";
// import Kabadi from "@assets/images/menu/kabadi.jpg";
// import VellyBall from "@assets/images/menu/vellyball.jpg";
// import StalinCm from "@assets/images/menu/stalincm.jpg";
// import Award from "@assets/images/menu/award.jpg";
// import UdhayaNithi from "@assets/images/menu/udhayanithi.jpg";
// import Stage from "@assets/images/menu/img1.jpg";
// import Img2 from "@assets/images/menu/img2.jpg";
// import Img3 from "@assets/images/menu/img3.jpg";
// import weight from "@assets/images/menu/weightlift.jpg";

import { useTranslation } from "react-i18next";
import React, { useEffect, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Mousewheel } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "@src/index.css";
import { useHistory } from "react-router-dom";
import { ROUTES } from "@views/routes/my_routes";
import Carousel from "react-multi-carousel";
import { useDispatch } from "react-redux";
import { dynamicRequest, useDynamicSelector } from "@services/redux";
import { useState } from "react";
import { FilterItems } from "@helpers/constants";
import { get_cms_homepage_gallery_query } from "@services/redux/slices/dynamic_entity/graphql/graphql_cms_homepage_gallery_query";

// import { MdArrowForwardIos } from "react-icons/md";

export default function Gallery({ gallery_data }) {
  const { t } = useTranslation();
  const history = useHistory();
  // const dispatch = useDispatch();
  // const [gallery_data, setGalleryData] = useState();
  // const navigationPrevRef = useRef(null);
  // const navigationNextRef = useRef(null);

  // const { items: gallery_items, loading } = useDynamicSelector(
  //   "get_cms_homepage_gallery"
  // );
  // set_loading_status(loading);
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
      slidesToSlide: 1,
    },
    large_laptop: {
      breakpoint: { max: 2560, min: 1640 },
      items: 3.5,
      slidesToSlide: 1,
    },
    laptop: {
      breakpoint: { max: 1024, min: 480 },
      items: 3,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1.5,
      slidesToSlide: 1,
    },
    tablet1: {
      breakpoint: { max: 768, min: 480 },
      items: 3,
      slidesToSlide: 1,
    },
    medium_laptop: {
      breakpoint: { max: 1300, min: 1024 },
      items: 3,
      slidesToSlide: 1,
    },
  };

  
  const handlePress = () => {
    history.push(ROUTES.GALLERY);
  };
  return (
    <>
      <Box variant="GalleryContainer">
        <VStack>
          <Box variant="GalleryContentBox">
            <Stack variant="galleryPageContent">
              <Box>
                <Center variant="galleryAndTwitterTitle">
                  <Text variant={"TwitterTitleOne"}>{t("gallery")}</Text>
                  <Text variant={"TwitterTitleTwo"}>{t("gallery")}</Text>
                </Center>
              </Box>
              <Hidden only={"xs"}>
                <Box justifyContent={"Center"}>
                  <Tooltip label="View more" openDelay={500}>
                    <Button
                      variant="cardButton"
                      mb={"0px"}
                      width={{ xl: "120px" }}
                      height={{ xl: "40px" }}
                      onPress={handlePress}
                      background={"#3B1B7E"}
                    >
                      <Text
                        variant={"cardButton_Txt"}
                        fontSize={{ xl: "var(--font-size-15)" }}
                      >
                        {t("view_more")}
                      </Text>
                    </Button>
                  </Tooltip>
                </Box>
              </Hidden>
            </Stack>
          </Box>

          <Box>
            <Swiper
              slidesPerView={3}
              spaceBetween={30}
              // slidesPerGroup={3}
              Swiper
              // centeredSlides={true}
              // loop={true}
              // loopFillGroupWithBlank={true}
              navigation={true}
              mousewheel={true}
              modules={[Navigation, Mousewheel]}
              loopedSlides={true}
              className="mySwiper"

              // variant="galleryImageShape"
            >
              <Box
                width={{
                  base: "400px",
                  md: "726px",
                  lg: "700px",
                  xl: "55vw",
                }}
              >
                {/* <Carousel
                responsive={responsive}
                // arrows={false}
                customLeftArrow={<></>}
                // customButtonGroup={<ButtonGroup />}
                className="gallery_slider"
                infinite={true}
              > */}
                {gallery_data?.map((x) => (
                  <SwiperSlide>
                    <HStack space={"10px"}>
                      <Box variant="galleryImageShape">
                        <img
                          src={x?.image?.value}
                          alt="images"
                          className="imhad"
                        />
                      </Box>
                    </HStack>
                  </SwiperSlide>
                ))}
                {/* </Carousel> */}
              </Box>
            </Swiper>
          </Box>

          <Hidden only={["lg", "xl", "md", "2xl"]}>
            <Box justifyContent={"Center"}>
              <Center>
                <Button
                  variant="cardButton"
                  mb="0px"
                  mt={"1rem"}
                  onPress={handlePress}
                  background={"#3B1B7E"}
                >
                  <Text variant={"cardButton_Txt"}>{t("view_more")}</Text>
                </Button>
              </Center>
            </Box>
          </Hidden>
        </VStack>
      </Box>
    </>
  );
}
