import NotificationsContents from "@views/components/notifications/notifications_contents";
import React from "react";

const NotificationsPage = () => {
  return (
    <div>
      <NotificationsContents />
    </div>
  );
};

export default NotificationsPage;
