import { Skeleton, Space } from "antd";
import { Box, Hidden, Text, VStack } from "native-base";
import React from "react";

const TableLoading = () => {
  return (
    <Box>
      <Hidden from="base" till="lg">
        <VStack space={"10px"}>
          <Skeleton.Button active style={{ width: "100%" }} />
          <Skeleton active />
        </VStack>
      </Hidden>
      <Hidden from="lg" till="3xl">
        <Box variant={"tender_table_box"}>
          <VStack space={"10px"}>
            <Skeleton.Button active style={{ width: "100%" }} />
            <Skeleton active />
          </VStack>
        </Box>
      </Hidden>
    </Box>
  );
};
export default TableLoading;
