import GovernmentOrderContent from "@views/components/government_order/government_order_content";
import React from "react";

const GovernmentOrderPage = () => {
  return (
    <div>
      <GovernmentOrderContent />
    </div>
  );
};

export default GovernmentOrderPage;
