import {
  DownloadOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { gql } from "@apollo/client";
import { v4 as uuidv4 } from "uuid";
// import "antd/dist/antd.min.css";
import _grid from "./grid";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
  Card,
  Switch,
  Upload,
  message,
} from "antd";
import { Box, Center, HStack, IconButton, Text, VStack } from "native-base";
import JoditEditor from "jodit-react";
import React, { useEffect, useState } from "react";
import { QueryRequest } from "@services/apollo/api_service";
import axios from "axios";
import { showToast } from "@helpers/toast";
import moment from "moment";
import { getUUID } from "@helpers/uuid";

// import { showNotification } from "@helpers/notify";
const { TextArea } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;

const validateInput = (rule, value, callback) => {
  // Define the regular expression pattern for allowed characters
  const allowedPattern = /^[^<>]+$/;

  if (!allowedPattern.test(value)) {
    callback("Invalid characters entered.");
  } else {
    callback(); // Call the callback without any argument for a successful validation
  }
};

const readFileContent = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
    reader.readAsArrayBuffer(file);
  });
};

const isPDF = (content) => {
  // Implement PDF signature check
  // You can use a library like pdfjs-dist or manually check the file content
  // For simplicity, I'm providing a basic check here
  const signature = [0x25, 0x50, 0x44, 0x46];
  return checkFileSignature(content, signature);
};

const isImage = (content) => {
  function checkFileSignature(buffers, signature, options = { offset: 0 }) {
    return signature.every((byte, index) => byte === buffers[options.offset + index]);
  }

  // Implement image signature check (JPEG, PNG, etc.)
  // For simplicity, I'm providing a basic check for JPEG and PNG
  const jpegSignature = [0xFF, 0xD8, 0xFF, 0xE0, 0x00, 0x10, 0x4A, 0x46, 0x49, 0x46, 0x00, 0x01];
  const pngSignature = [0x89, 0x50, 0x4e, 0x47, 0x0d, 0x0a, 0x1a, 0x0a];


  return (
    checkFileSignature(content, jpegSignature) ||
    checkFileSignature(content, pngSignature)
  );
};

const isWordDocument = (content) => {
  // Implement Word document signature check
  // For simplicity, I'm providing a basic check here
  const signature = [0xD0, 0xCF, 0x11, 0xE0];
  return checkFileSignature(content, signature);
};

const isPNG = (content) => {
  const pngSignature = [0x89, 0x50, 0x4E, 0x47, 0x0D, 0x0A, 0x1A, 0x0A];
  return checkFileSignature(content, pngSignature);
};

const isDocx = (content) => {
  const docxSignature = [0x50, 0x4B, 0x03, 0x04];
  return checkFileSignature(content, docxSignature);
};

const checkFileSignature = (content, signature) => {
  const uint8Array = new Uint8Array(content);
  // Ensure that content is at least as long as the signature
  if (uint8Array.length < signature.length) {
    return false;
  }

  for (let i = 0; i < signature.length; i++) {
    if (uint8Array[i] !== signature[i]) {
      return false;
    }
  }

  return true;
};


const TextBox = (props) => {
  let name = props.field;
  const updatedProps = { ...props };
  if (updatedProps?.rules && updatedProps.rules.length > 0) {
    updatedProps.rules.push({ validator: validateInput });
  } else {
    updatedProps.rules = [{ validator: validateInput }];
  }
  return (
    <Form.Item
      style={updatedProps.style}
      label={updatedProps.label}
      name={name}
      rules={updatedProps.rules}
      labelCol={updatedProps.labelCol}
      hidden={props.hidden}
      initialValue={props.initialValues}
    >
      <Input
        placeholder={updatedProps.placeholder}
        disabled={updatedProps.isdisabled}
        onKeyDown={(e) => (e.keyCode == 13 ? e.preventDefault() : "")}
      />
    </Form.Item>
  );
};

// const TagBox = (props) => {
//   let rules = props.rules;
//   let name = props.field;

//   const _TagInput = (tag_props) => {
//     let value = [];
//     if (tag_props.value && tag_props.format === "string") {
//       value = JSON.parse(tag_props.value);
//     }
//     const handleValueChange = (values) => {
//       if (values && values.length === 0) {
//         tag_props.onChange(null);
//       } else {
//         if (tag_props.format === "string") {
//           tag_props.onChange(JSON.stringify(values));
//         } else {
//           tag_props.onChange(values);
//         }
//       }
//     };
//     return (
//       <TagsInput
//         {...tag_props}
//         inputProps={{
//           placeholder: tag_props.placeholder || "",
//           style: { width: "150px" },
//         }}
//         value={value}
//         onChange={handleValueChange}
//       />
//     );
//   };
//   return (
//     <Form.Item
//       style={props.style}
//       label={props.label}
//       name={name}
//       rules={rules}
//     >
//       <_TagInput placeholder={props.placeholder} format={props.format} />
//     </Form.Item>
//   );
// };

const Number = (props) => {
  const updatedProps = { ...props };
  if (updatedProps?.rules && updatedProps.rules.length > 0) {
    updatedProps.rules.push({ validator: validateInput });
  } else {
    updatedProps.rules = [{ validator: validateInput }];
  }
  return (
    <Form.Item label={updatedProps.label} name={updatedProps.field} rules={updatedProps.rules} initialValue={updatedProps.initialValues}>
      <InputNumber
        min={0}
        disabled={updatedProps.isdisabled}
        style={{ width: "100%" }}
      />
    </Form.Item>
  );
};

const FormTextArea = (props) => {
  const updatedProps = { ...props };
  if (updatedProps?.rules && updatedProps.rules.length > 0) {
    updatedProps.rules.push({ validator: validateInput });
  } else {
    updatedProps.rules = [{ validator: validateInput }];
  }
  return (
    <Form.Item
      label={updatedProps.label}
      name={updatedProps.field}
      rules={updatedProps.rules}
    >
      <TextArea disabled={updatedProps.isdisabled} />
    </Form.Item>
  );
};

const FormSelect = (props) => {
  // filterOption={(input, option) =>
  //   option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
  //     0 ||
  //   option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
  // }
  let options = props.options;

  return (
    <Form.Item
      label={props.label}
      name={props.field}
      rules={props.rules}

    // style={props.style}
    // hidden={true}
    >
      <Select
        style={{ width: props.width || "" }}
        showSearch
        defaultValue={props.defaultValue}
        mode={props.isMulti ? "multiple" : "single"}
        disabled={props.isdisabled}
        allowClear
        // filterOption={props.filterOption}
        filterOption={(input, option) =>
          option?.props?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >=
          0 ||
          option?.props?.value?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
        }
      >
        {options?.map((item, index) => {
          return (
            <Option value={item[props.valueField]} key={index}>
              {item[props.labelField]}
            </Option>
          );
        })}
      </Select>
    </Form.Item>
  );
};

const Password = (props) => {
  return (
    <Form.Item label={props.label} name={props.field} rules={props.rules}>
      <Input.Password />
    </Form.Item>
  );
};

const Date = (props) => {
  return (
    <Form.Item label={props.label} name={props.field} rules={props.rules}>
      <DatePicker
        style={{ width: "100%" }}
        disabledDate={props.disabledDate}
        // defaultValue={moment(props.defaultValue)}
        disabledTime={props.disabledTime}
        showTime={props.showTime}
        disabled={props.isdisabled}
      />
    </Form.Item>
  );
};

const FormRadio = (props) => {
  {
    let options = props.options.map((item) => {
      return (
        <Radio
          value={item["id"]}
          disabled={props.disabled}
          defaultChecked={item.defaultChecked}
        >
          {item["name"]}
        </Radio>
      );
    });
    return props.type === "button" ? (
      <Form.Item label={props.label} name={props.field} rules={props.rules}>
        <Radio.Group>
          {props?.options?.map((item) => {
            return (
              <Radio.Button value={item["id"]}>{item["name"]}</Radio.Button>
            );
          })}
        </Radio.Group>
      </Form.Item>
    ) : (
      <Form.Item label={props.label} name={props.field} rules={props.rules}>
        <Radio.Group>
          {props.direction === "row"
            ? props?.options?.map((item) => {
              return (
                <Row justify="space-between">
                  <Radio value={item["id"]} disabled={true}>
                    {item["name"]}
                  </Radio>
                </Row>
              );
            })
            : options}
        </Radio.Group>
      </Form.Item>
    );
  }
};

const CheckBox = (props) => {
  let options = props?.options?.map((item) => {
    return <Checkbox value={item["id"]} disabled={props.disabled}></Checkbox>;
  });
  return (
    <Form.Item label={props.label} name={props.field}>
      <Checkbox.Group>
        {props.direction === "row"
          ? props?.options?.map((item) => {
            return (
              <Row justify="space-between">
                <Checkbox
                  value={item["id"]}
                  style={{
                    lineHeight: "32px",
                  }}
                ></Checkbox>
              </Row>
            );
          })
          : options}
      </Checkbox.Group>
    </Form.Item>
  );
};

const FormSwitch = (props) => {
  return (
    <Form.Item label={props.label} name={props.field} valuePropName="checked">
      <Switch />
    </Form.Item>
  );
};

const RichText = (props) => {
  return (
    <Form.Item label={props.label} name={props.field}>
      <JoditEditor />
    </Form.Item>
  );
};
const FormButton = (props) => {
  return (
    <Form.Item>
      <Button
        type="primary"
        htmlType="submit"
        loading={props.isLoading}
        // borderRadius="5px"
        disabled={props.isDisabled}
      >
        {props.children ? props.children : "Submit"}
      </Button>
    </Form.Item>
  );
};
const Grid = (props) => {
  return <_grid {...props} />;
};
// const JSONEditor = (props) => {
//   return (
//     <Form.Item label={props.label} name={props.field}>
//       <JSONInput />
//     </Form.Item>
//   );
// };
const List = (props) => {
  return (
    <Form.List name={props.field_name}>
      {(fields, { add, remove }) => {
        return (
          <div>
            {fields?.map((field, index) => {
              return (
                <HStack key={index}>
                  {props?.children}
                  <Button
                    type="danger"
                    className="dynamic-delete-button"
                    onClick={() => remove(field.name)}
                    style={{ marginLeft: "20px" }}
                    icon={<MinusCircleOutlined />}
                  >
                    Remove
                  </Button>
                </HStack>
              );
            })}
            <Form.Item>
              <Button
                type="solid"
                onClick={() => add()}
                style={{ width: "32%", float: "right" }}
              >
                <PlusOutlined /> {props?.add_label}
              </Button>
            </Form.Item>
          </div>
        );
      }}
    </Form.List>
  );
};
export const fileQuery = gql`
  query getUploadUrl($type: String, $fileName: String) {
    getUploadUrl(type: $type, fileName: $fileName) {
      status
      url
      error {
        status_code
        message
      }
    }
  }
`;
const normFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
};
let fileUploadresponse;

const FormFile = (props) => {
  const [fileList, setFileList] = useState([]);
  const [headers, setHeaders] = useState();
  const handleBeforeUpload = async (file) => {
    const hasRepeatedExtension = /\.[^.]+(\.[^.]+)$/.test(file?.name);
    const file_name = file?.name.split(".")[1];
    const allowed_file_formats = ["pdf", "png", "jpg", "docx", "doc", "jpeg"];
    if (hasRepeatedExtension) {
      message.error("Invalid file format");
    } else {
      const file_content = await readFileContent(file);
      let fileType;
      // switch (true) {
      //   case isPDF(file_content):
      //     fileType = "photo";
      //     break;
      //   case isImage(file_content):
      //     fileType = "photo";
      //     break;
      //   case isWordDocument(file_content):
      //     fileType = "photo";
      //     break;
      //   case isPNG(file_content):
      //     fileType = "photo";
      //     break;
      //   default:
      //     showToast({
      //       type: "error",
      //       message: "File contains script content. please check your file content."
      //     })
      //     return
      // }
      let fileupload = {
        type: fileType || "photo",
        fileName: `${uuidv4()}.${file_name || "png"}`,
      };

      fileUploadresponse = await QueryRequest(fileQuery, fileupload);
    }
  };


  const handleFileChanged = ({ file }) => {
    if (file.status === "removed") {
      setFileList([]);
    } else if (file.status === "uploading") {
      setFileList([file]);
    } else if (file.status === "done") {
      const newFile = {
        ...file,
        url: fileUploadresponse?.data?.getUploadUrl?.url.split("?")[0],
      };
      props?.form?.setFieldsValue({
        [props?.field]: [newFile], // Assuming props.field is the name of the form field
      });
      setFileList([newFile]);
      showToast({
        type: "success",
        message: "Document uploaded successfully",
      });
    }
  };
  // const handleUpload = async ({ onSuccess, onError, file }) => {
  //   axios
  //     .put(fileUploadresponse?.data?.getUploadUrl?.url, file, {
  //       headers: { "Content-Type": file?.type },
  //     })
  //     .then(async (res) => {
  //       if (res.status.toString() === "200") {
  //         let url = fileUploadresponse?.data?.getUploadUrl?.url.split("?")[0];
  //         url = url.replace(process.env.REACT_APP_ASSETS_BASE_URL, "");
  //         // url = url.replace(process.env.REACT_APP_ASSETS_REPLACE_URL, "");
  //         file.url = url;
  //         onSuccess(null, file);
  //       } else {
  //         onError(err, err, file);
  //       }
  //     })
  //     .catch((err) => {
  //       onError(err, err, file);
  //     });
  // };

  const handleUpload = async ({ onSuccess, onError, file }) => {
    try {
      const maxFileSize = 5 * 1024 * 1024; // 5 MB in bytes
      if (file.size > maxFileSize &&!file?.type?.includes("pdf")) {
        onError(new Error("File size must be within 5MB"), null, file);
        return;
      }

      const res = await axios.put(
        fileUploadresponse?.data?.getUploadUrl?.url,
        file,
        {
          headers: { "Content-Type": file?.type },
        }
      );

      if (res.status === 200) {
        const file_content = await readFileContent(file);
        let fileType;

        // switch (true) {
        //   case isPDF(file_content):
        //     fileType = "photo";
        //     break;
        //   case isImage(file_content):
        //     fileType = "photo";
        //     break;
        //   case isWordDocument(file_content):
        //     fileType = "photo";
        //     break;
        //   case isPNG(file_content):
        //     fileType = "photo";
        //     break;
        //   default:
        //     onError("File contains script content.", file);
        //     return
        // }
        let url = fileUploadresponse?.data?.getUploadUrl?.url.split("?")[0];
        url = url.replace(process.env.REACT_APP_ASSETS_BASE_URL, "");
        // url = url.replace(process.env.REACT_APP_ASSETS_REPLACE_URL, "");
        file.url = url;
        onSuccess(null, file);

      } else {
        onError(new Error(`Unexpected status: ${res.status}`), res, file);
      }
    } catch (err) {
      onError(err, null, file);
    }
  };

  const action = fileUploadresponse?.data?.getUploadUrl;
  return (
    <>
      <Form.Item
        label={props.label}
        name={props.field}
        valuePropName="fileList"
        getValueFromEvent={normFile}
        extra=""
        rules={props.rules}
        initialValue={props.initialValue}
      >
        <Upload
          maxCount={props.maxCount||1}
          action={action}
          headers={headers}
          fileList={fileList}
          multiple={props.multiple}
          disabled={props.isdisabled}
          // showUploadList={{ showDownloadIcon: false, showPreviewIcon: true }}
          customRequest={(e) => handleUpload(e)}
          beforeUpload={(args) => handleBeforeUpload(args)}
          onChange={(e) => handleFileChanged(e)}
        >
          <Button icon={<UploadOutlined />}>Click to upload</Button>
        </Upload>
      </Form.Item>


    </>
  );
};

const VForm = (props) => {
  const { children } = props;
  let _form = props.form;
  if (!_form) {
    const [form] = Form.useForm();
    _form = form;
  }
  const [initialValues, setInitialValues] = useState(props.initialValues);
  useEffect(() => {
    _form.setFieldsValue(props.initialValues);
    setInitialValues(props.initialValues);
  }, [_form, props.initialValues]);

  const handleOnValueChange = (value, values) => {
    if (props.onValueChange) {
      props.onValueChange(value, values);
    }
  };

  const handleOnFinish = (values) => {
    if (props.onFinish) {
      props.onFinish(values);
    }
  };
  return (
    <Form
      form={props.form || _form}
      name={props.id}
      layout={props.layout || "vertical"}
      onFinish={handleOnFinish}
      onValuesChange={handleOnValueChange}
      initialValues={initialValues}
    >
      {children}
    </Form>
  );
};
VForm.Grid = Grid;
VForm.List = List;
VForm.TextBox = TextBox;
VForm.Number = Number;
VForm.TextArea = FormTextArea;
VForm.Password = Password;
// VForm.IntlPhone = IntlPhone;
VForm.Phone = Number;
VForm.Select = FormSelect;
// VForm.Cascade = Cascade;
VForm.RichText = RichText;
VForm.FormFile = FormFile;
VForm.Date = Date;
VForm.Radio = FormRadio;
VForm.CheckBox = CheckBox;
VForm.Button = FormButton;
VForm.Switch = FormSwitch;
export default VForm;
