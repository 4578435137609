import PressReleaseContent from "@views/components/press_release/press_release_content";
import React from "react";
const PressReleasePage = () => {
  return (
    <>
      <PressReleaseContent />
    </>
  );
};

export default PressReleasePage;
