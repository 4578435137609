import React, { useState } from "react";
// import GroupOne from "@assets/images/menu/news/group.png";
// import GroupTwo from "@assets/images/menu/news/New Coaches.jpg";
// import GroupThree from "@assets/images/menu/news/CM TROPHY (1).jpg";
// import Chess from "@assets/images/menu/news/chess.png";
// import Hockey from "@assets/images/menu/news/hockey.png";
// import StadiumOne from "@assets/images/menu/news/stadium.png";
// import StadiumTwo from "@assets/images/menu/news/stadium.jpg";
// import SwimmingOne from "@assets/images/menu/news/swimming.jpg";
// import SwimmingTwo from "@assets/images/menu/news/swimming.png";
// import Running from "@assets/images/menu/news/DSC01262.jpg";
// import StadiumTopView from "@assets/images/menu/news/JNS TOP VIEW.jpg";
// import NewCoaches from "@assets/images/menu/news/New Coaches.jpg";
// import AQUATICComplex from "@assets/images/menu/news/SDAT AQUATIC Complex.jpg";

// import HockeySport from "@assets/images/menu/hockey1.jpg";
// import SportTeam from "@assets/images/menu/sportteam.jpg";
// import Kabadi from "@assets/images/menu/kabadi.jpg";
// import VellyBall from "@assets/images/menu/vellyball.jpg";
// import StalinCm from "@assets/images/menu/stalincm.jpg";
// import Award from "@assets/images/menu/award.jpg";
// import UdhayaNithi from "@assets/images/menu/udhayanithi.jpg";
// import Stage from "@assets/images/menu/img1.jpg";
// import Img2 from "@assets/images/menu/img2.jpg";
// import Img3 from "@assets/images/menu/img3.jpg";
// import weight from "@assets/images/menu/weightlift.jpg";

import GalleryImageTemplateOne from "./gallery_image_template_one";
import { Box, Center, HStack, Pressable, Text, VStack } from "native-base";
import GalleryImageTemplateTwo from "./gallery_image_template_two";
import GalleryImageTemplateThree from "./gallery_image_template_three";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { FilterItems, color_gradients } from "@helpers/constants";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  dynamicRequest,
  get_system_cms_field,
  get_system_cms_value_clear,
  getsystemCMSFieldListSelector,
  useDynamicSelector,
} from "@services/redux";
import { get_cms_gallery_page_query } from "@services/redux/slices/dynamic_entity/graphql/graphql_cms_gallery_page_query";
import { Skeleton, Space, Spin } from "antd";
import GalleryImageTemplateLoading from "./gallery_loading";
import { order } from "styled-system";

const GalleryImages = () => {
  const dispatch = useDispatch();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [get_key, setKey] = useState("all");
  const [current_indexs, setCurrentIndexs] = useState(0);
  const [gallery_data, setGalleryData] = useState([]);
  const [gallery_filtered_data, setGalleryFilteredData] =
    useState(gallery_data);

  // const { items } = useSelector(getsystemCMSFieldListSelector);
  const { items: gallery_items, loading } = useDynamicSelector(
    "get_cms_gallery_page"
  );

  // useEffect(() => {
  //   dispatch(
  //     get_system_cms_field({
  //       json: {
  //         system_cms_key: "Gallery_page",
  //       },
  //     })
  //   );
  // }, []);

  const get_homepage_gallery = () => {
    let keys = [{ key: "get_cms_gallery_page", loading: true }];
    let variables = {
      json: {
        system_cms_key: "Gallery_page",
      },
    };
    dispatch(dynamicRequest(keys, get_cms_gallery_page_query, variables));
  };

  useEffect(() => {
    get_homepage_gallery();
  }, []);
  useEffect(() => {
    let values = FilterItems(gallery_items);
    setGalleryData(values?.sort((a, b) => Number(a?.order?.value) - Number(b?.order?.value)));
    setGalleryFilteredData(values?.sort((a, b) => Number(a?.order?.value) - Number(b?.order?.value)))
  }, [gallery_items]);


  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5.5,
      slidesToSlide: 1,
    },
    large_laptop: {
      breakpoint: { max: 2560, min: 1640 },
      items: 7.5,
      slidesToSlide: 1,
    },
    laptop: {
      breakpoint: { max: 1024, min: 480 },
      items: 4.5,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1.5,
      slidesToSlide: 1,
    },
    tablet1: {
      breakpoint: { max: 768, min: 480 },
      items: 2.5,
      slidesToSlide: 1,
    },
  };
  const handlePress = (index, title) => {
    setCurrentIndex(index);
    setKey(title);
  };
  let gallery_labels = gallery_data?.map((label) => {
    return {
      label: label?.title?.value,
      value: label?.title?.value,
    };
  });
  const uniqueLabels = [...new Set(gallery_labels.map(label => label.value))];

  // Create a new array with unique labels
  const uniqueGalleryLabels = uniqueLabels.map(value => {
    return {
      label: value,
      value: value,
    };
  });
  gallery_labels = [{ label: "All", value: "all" }, ...uniqueGalleryLabels];
  useEffect(() => {
    if (get_key !== "all") {
      let filtered_key_images = gallery_data?.filter(
        (image_key) => image_key?.title?.value === get_key
      );
      setGalleryFilteredData(filtered_key_images);
    } else {
      setGalleryFilteredData(gallery_data);
    }
  }, [get_key]);
  return (
    <div>
      <Box
        padding={{
          base: "20px",
          md: "20px",
          lg: "60px",
          xl: "60px",
          "2xl": "60px",
        }}
      >
        <VStack space={10}>
          {loading ? (
            <Space>
              {/* <Skeleton.Button active />
                <Skeleton.Avatar active /> */}
              <Skeleton.Input active />
              <Skeleton.Input active />
              <Skeleton.Input active />
              <Skeleton.Input active />
              <Skeleton.Input active />
            </Space>
          ) : (
            <Carousel responsive={responsive} className="gallery-carousel" >
              {gallery_labels?.map((item, index) => {
                return (
                  <div className="carousel-height" style={{ display: "flex", flexDirection: "row", alignItems: "end", justifyContent: "center", width: "100%", padding:"10px" }}>
                    <Pressable
                      onPress={() => {
                        handlePress(index, item?.value);
                      }}
                    >
                      <Box
                        key={index}
                        bgImage={
                          index === currentIndex
                            ? color_gradients.sports_event_gradient
                            : "linear-gradient(99.9deg, #EDEDED,#EDEDED)"
                        }
                        style={{
                          padding: "10px",
                          borderRadius: "10px",
                          minWidth: "100px",
                          maxWidth: "200px",
                          alignItems: "center",
                          boxShadow:
                            index === currentIndex
                              ? "0px 14px 19px rgba(0, 0, 0, 0.15)"
                              : "",
                        }}
                      >
                        <Text
                          textAlign={"center"}
                          color={index === currentIndex ? "white" : "black"}
                          fontSize={"var(--font-size-14)"}
                        >
                          {item?.label}
                        </Text>
                      </Box>
                    </Pressable>
                  </div>
                );
              })}
            </Carousel>
          )}

          {loading ? (
            <Center>
              <GalleryImageTemplateLoading />
            </Center>
          ) : (
            <VStack space={4}>
              {gallery_filtered_data?.map((list, index) => {
                if (index % 3 === 0) {
                  return <GalleryImageTemplateOne images={list} />;
                } else if (index % 3 === 1) {
                  return <GalleryImageTemplateTwo images={list} />;
                } else {
                  <GalleryImageTemplateThree images={list} />;
                }
              })}
            </VStack>
          )}
        </VStack>
      </Box>
    </div>
  );
};

export default GalleryImages;
