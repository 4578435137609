import React from "react";
import {
  Box,
  Button,
  Stack,
  Image,
  Text,
  Center,
  HStack,
  Hidden,
} from "native-base";
import StalinHon from "../../../assets/images/menu/Hon_CM_Photo1.png";
import KabaddiPlay from "../../../assets/images/menu/kabaddiplay.png";
import { useTranslation } from "react-i18next";
import QRImage from "@assets/images/QR.jpeg";
import TNChampionsLogo from "@assets/images/tnchampionlogo.svg";

import { Tooltip } from "antd";

const TNChampions = () => {
  const { t } = useTranslation();

  const handle_redirect = () => {
    window.open("https://tnchampions.sdat.in/", "_blank");
  };
  return (
    <Box width={"100%"}>
      <Hidden only={["sm", "xs"]}>
        <Box variant="TNChampionsBox">
          {/* <Center> */}
          <Box variant="BackGroundImageBox">
            {/* <Image
              variant="SportEventBackGroundImage"
              source={{
                uri: KabaddiPlay,
              }}
            /> */}
          </Box>
          <Stack
            justifyContent={"center"}
            display={"flex"}
            flexDirection={{
              base: "column",
              xs: "column",
              sm: "column",
              md: "row",
              lg: "row",
              xl: "row",
              "2xl":"row"
            }}
            alignItems={"center"}
            style={{ gap: "30px" }}
            overflowX={"hidden"}
          >
            <Box
              variant="SportEventImageBox"
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"center"}
            >
              <Image
                size={{ xs: "100", md: "100", lg: "300px" }}
                // borderRadius={"100"}
                // size={"full"}
                source={{ uri: QRImage }}
              />
              {/* <img src={QRImage} /> */}
            </Box>

            <Box variant="SportsContentBox" width={"35%"}>
              <Box mb={"30px"}>
                <img width={"100%"} height={"50px"} src={TNChampionsLogo} />
              </Box>
              <Text variant="SportHeadingText" color={"black"}>
                {t("TAMIL NADU CHAMPIONS FOUNDATION")}
              </Text>
              <Text
                variant="ChampionContentText"
                fontSize={"var(--font-size-14)"}
              >
                {t(
                  "The Tamil Nadu Champions Foundation is an initiative to partner with citizens and corporates to revamp sports in Tamil Nadu.Click the 'Visit Now' button to visit the portal."
                )}
              </Text>
              <Box
                display={"flex"}
                flexDirection={"row"}
                justifyContent={{ base: "center", lg: "flex-start" }}
                mt={"40px"}
                alignItems={{ base: "center", lg: "start" }}
              >
                <Tooltip label="Visit Now" openDelay={500}>
                  <Button
                    variant={"cardButton"}
                    background={"#3B1B7E"}
                    onPress={handle_redirect}
                  >
                    <Text variant={"cardButton_Txt"}> {t("Visit Now")}</Text>
                  </Button>
                </Tooltip>
              </Box>
            </Box>
          </Stack>
          {/* </Center> */}
        </Box>
      </Hidden>
      <Center>
        <Hidden only={["lg", "xl", "md", "2xl"]}>
          <Box variant="TNCampionsBox">
            <Stack
              space={"1"}
              justifyContent={"center"}
              display={"flex"}
              flexDirection={{
                base: "column",
                xs: "column",
                md: "row",
              }}
            >
              <Stack
                display={"flex"}
                flexDirection={{ base: "column", xs: "column", lg: "row" }}
              >
                <Box>
                  <Stack
                    display={"flex"}
                    flexDirection={{ base: "column", xs: "row", lg: "row" }}
                    justifyContent={"center"}
                  >
                    <Box
                      variant="SportsEventImageBox"
                      display={"flex"}
                      flexDirection={"row"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      textAlign={"center"}
                      alignContent={"center"}
                    >
                      <Image
                        size={{ xs: "56", lg: "140" }}
                        // borderRadius={"100"}
                        source={{ uri: QRImage }}
                      />
                    </Box>
                  </Stack>
                </Box>
                <Box>
                  <Box mt={"30px"}>
                    <img width={"100%"} height={"50px"} src={TNChampionsLogo} />
                  </Box>

                  <Text
                    variant="TNchampitionText"
                    color={"black"}
                    padding={"20px"}
                    textAlign={"center"}
                  >
                    {t("TAMIL NADU CHAMPIONS FOUNDATION")}
                  </Text>
                  <Text
                    variant="SportsDescriptionText"
                    textAlign={"justify"}
                    color={"black"}
                  >
                    {t(
                      "The Tamil Nadu Champions Foundation is an initiative to partner with citizens and corporates to revamp sports in Tamil Nadu.Click the 'Visit Now' button to visit the portal."
                    )}
                  </Text>
                </Box>
              </Stack>

              <Center>
                <Box
                  marginLeft={{ xs: "0", lg: "24" }}
                  marginTop={{ xs: "5", lg: "16" }}
                  width={"32"}
                  height={"12"}
                >
                  <Tooltip label="Visit Now" openDelay={500}>
                    <Button
                      variant={"cardButton"}
                      background={"#3B1B7E"}
                      onPress={handle_redirect}
                    >
                      <Text variant={"cardButton_Txt"}> {t("Visit Now")}</Text>
                    </Button>
                  </Tooltip>
                </Box>
              </Center>
            </Stack>
          </Box>
        </Hidden>
      </Center>
    </Box>
  );
};
export default TNChampions;
