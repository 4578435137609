import { Box, HStack, Stack, Text } from "native-base";
import React from "react";
import Head from "@assets/images/menu/head_office.jpg";
import { Card } from "antd";
import { useTranslation } from "react-i18next";

const ContactUsContent = () => {
  const { t } = useTranslation();
  const headOffice = {
    name: t("sports_development_authority_of_tamil_nadu"),
    address: t("contact_address"),
    pho_no1: "044 - 2561 1522 ",
    pho_no2: "+91 9514 000 777",
    e_mail: "sdat@tn.gov.in",
    gst_no: "33AAALS0325C2ZN",
    csr_no: "CSR00033848",
    "80g_no": "AAFAC5350DF20221",
  };
  return (
    <Box variant={"contact_us_card_box"}>
      <Box flex={1}>
        <img src={"https://v-lfa-assets.blr1.vultrobjects.com/eee425b5-25fb-4314-a7eb-92df8fe26848.jpg"} alt="Head_office" className="head-office-logo" />
      </Box>
      <Box gap={"20px"} flex={1}>
        <Text variant={"contact_us_card_heading"}>{headOffice.name}</Text>
        <Text variant={"contact_us_address"}>{headOffice.address}</Text>
        <Box fontFamily={"Inter"}>
          {/* <HStack variant={"contact_us_address_details"} textAlign={"start"} alignItems={"center"}>
            <HStack variant={"contact_content_title_aligns"} alignItems={"center"}>
              <Text variant={"contact_details_title"}>Information Center</Text>
              <span style={{ fontWeight: "bold" }}>{": "}</span>
            </HStack>
            <a href={`tel:${headOffice.pho_no}`}>
              <Text variant={"contact_details"} color="blue.500">
                {`   ${headOffice.pho_no}`}
              </Text>
            </a>
          </HStack> */}
          <HStack variant={"contact_us_address_details"} textAlign={"start"} space={"20px"}>
            <HStack variant={"contact_content_title_align"} space={"20px"} >
              <Text variant={"contact_details_title"} >{t("information_center")}</Text>
              <span style={{ fontWeight: "bold" }}>{": "}</span>
            </HStack>
            <HStack alignItems={"center"} space={"3px"}>
              <a href={`tel:${headOffice.pho_no1}`}>
                <Text variant={"contact_details"} color="blue.500">
                  {headOffice.pho_no1}
                </Text>
              </a>
              /
              <a href={`tel:${headOffice.pho_no2}`}>
                <Text variant={"contact_details"} color="blue.500">
                  {headOffice.pho_no2}
                </Text>
              </a>
            </HStack>
          </HStack>
          <HStack variant={"contact_us_address_details"} textAlign={"start"} justifyContent={"flex-start"} >
            <HStack variant={"contact_content_title_align"} >
              <Text variant={"contact_details_title"}>{t("e_mail")}</Text>
              <span style={{ fontWeight: "bold" }}>{": "}</span>
            </HStack>
            <a href={`mailto:${headOffice?.e_mail}`}>
              <Text variant={"contact_details"} color="blue.500">
                {headOffice.e_mail}
              </Text>
            </a>
          </HStack>
          <HStack variant={"contact_us_address_details"} textAlign={"start"}>
            <HStack variant={"contact_content_title_align"}>
              <Text variant={"contact_details_title"}>GST.No</Text>
              <span style={{ fontWeight: "bold" }}>{": "}</span>
            </HStack>
            <Text variant={"contact_details"}>{headOffice.gst_no}</Text>
          </HStack>
          <HStack variant={"contact_us_address_details"} textAlign={"start"}>
            <HStack variant={"contact_content_title_align"}>
              <Text variant={"contact_details_title"}>CSR No</Text>
              <span style={{ fontWeight: "bold" }}>{": "}</span>
            </HStack>
            <Text variant={"contact_details"}>{headOffice.csr_no}</Text>
          </HStack>
          <HStack variant={"contact_us_address_details"} textAlign={"start"}>
            <HStack variant={"contact_content_title_align"}>
              <Text variant={"contact_details_title"}>80G No</Text>
              <span style={{ fontWeight: "bold" }}>{": "}</span>
            </HStack>
            <Text variant={"contact_details"}>{headOffice["80g_no"]}</Text>
          </HStack>
        </Box>
      </Box>
    </Box>
  );
};

export default ContactUsContent;
