import { formsReducer as _formsReducer } from "./forms";
import { validationSchemaReducer as _validationSchemaReducer } from "./validation";

export const formsReducer = {
  forms: _formsReducer,
  validationSchema: _validationSchemaReducer
};

export * from "./forms";
export * from "./validation";
