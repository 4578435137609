import { color_gradients } from "@helpers/constants";
import { Box, Card, HStack, Pressable, Text, VStack } from "native-base";
import React, { useState } from "react";
import { InfrastructureContentss } from "./Infrastructure_json";
import InfrastructureTable from "./infrastructure_table";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

const InfrastructureMonogram = (props) => {
  const { list } = props;
  const { t } = useTranslation();
  const [values, setValues] = useState(InfrastructureContentss?.[0]?.key || '');
  const [description, setDescription] = useState([]); // Set to the first item
  const [find_index, setFindIndex] = useState(0); // Set to 0 initially
  // Added useEffect to select the first item when the component mounts
  useEffect(() => {
    setDescription({
      list: list?.[0]?.list?.value,
      node: list?.[0]?.note?.value,
    });
  }, [list]);

  const handlePress = (s, index, list, note) => {
    setValues(s);
    setFindIndex(index);
    setDescription({ list: list, note: note });
  };
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4.5,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2.5,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1.5,
      slidesToSlide: 1,
    },
  };
  return (
    <div>
      <VStack space={4} width={"100%"}>
        <Box>
          <Text fontFamily={"Inter"} bold fontSize={"var(--font-size-20)"}>
            {t("tariff_for_competition")}
          </Text>
        </Box>
        <Carousel responsive={responsive} className="infrastructure-carousel">
          {list?.map((values, index1) => {
            return (
              <div className="carousel-height">
                <Pressable
                  key={index1}
                  onPress={() => {
                    handlePress(
                      values?.Title?.value,
                      index1,
                      values?.list?.value,
                      values?.note?.value
                    );
                  }}
                >
                  <Card
                    key={index1}
                    width={"200px"}
                    height={"70px"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    bgImg={
                      index1 === find_index
                        ? color_gradients.sports_event_gradient
                        : "linear-gradient(99.9deg, #EDEDED,#EDEDED)"
                    }
                    style={{
                      boxShadow:
                        index1 === find_index
                          ? "0px 14px 19px rgba(0, 0, 0, 0.15)"
                          : "",
                    }}
                  >
                    <Text
                      fontSize={"var(--font-size-13)"}
                      fontFamily={"Inter"}
                      fontWeight={"medium"}
                      color={index1 === find_index ? "white" : "black"}
                    >
                      {values?.Title?.value}
                    </Text>
                  </Card>
                </Pressable>
              </div>
            );
          })}
        </Carousel>
      </VStack>
      <Box
        padding={{
          base: "0px",
          lg: "40px",
          xl: "40px",
          "2xl": "40px",
          md: "40px",
          "3xl": "40px",
        }}
      >
        <InfrastructureTable description={description} />
      </Box>
    </div>
  );
};

export default InfrastructureMonogram;
