import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, VStack, Text, HStack, Pressable, Link } from "native-base";
import Dialog from "@views/components/ui/dialog";
import Wrapper from "@views/components/ui/wrapper";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import { showToast } from "@helpers/toast";

import {
  getcmsListSelector,
  get_cms,
  deletecmsSelector,
  delete_one_cms_modal,
  delete_cms,
  get_one_cms_clear,
  delete_one_cms_clear,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";
import Scroll_view from "@views/components/ui/scroll_view";
import { Button, Pagination, Popconfirm, Space, Table } from "antd";
import SearchBox from "@views/components/ui/search_box/search_box";
import ActionButton from "@views/components/ui/dialog/action_button";
import CustomizeButton from "@views/components/ui/Button/button";
import { showNotification } from "@helpers/notify";
import { startCase } from "lodash";
import { countFormat } from "@helpers/constants";
const cmsList = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [actionItem, setActionItem] = useState({});
  const [page_limit, setPageLimit] = useState(10);
  const [page_number, setPageNumber] = useState(1);
  const [search_string, set_search_string] = useState("");
  const { t } = useTranslation();
  const [dialogVisible, setDialogVisible] = useState(false);
  const [key, setKeys] = useState();
  const { loading, total_items, items } = useSelector(getcmsListSelector);

  const {
    loading: deleteLoading,
    status: deleteStatus,
    error: deleteError,
  } = useSelector(deletecmsSelector);
  const handleAdd = () => {
    dispatch(get_one_cms_clear());
    history.push({
      pathname: `${ROUTES.CMS_DETAILS}`,
      // state: `${item.name}`,
    });
  };

  const handleEdit = (item) => {
    history.push({
      pathname: `${ROUTES.CMS_DETAILS}/${item.id}`,
      // state: `${item.name}`,
    });
  };

  const handleDelete = (item) => {
    setActionItem(item);
    setDialogVisible(true);
  };


  const handleDialogClose = () => {
    setDialogVisible(false);
  };
  const handleDeleteModel = (id) => {
    dispatch(delete_cms({ json: id }))
  }
  const handleKeys = (keys) => {
    setKeys(keys.key);
  };
  const handleSearch = (letter) => {
    // if (key == "Enter") {
      set_search_string(letter)
    // }
  };


  
  useEffect(() => {
    if (deleteError) {
      setDialogVisible(false);
      showToast({
        type: "error",
        message: deleteError,
      });
      dispatch(delete_one_cms_clear())
    } else if (deleteStatus === "success") {
      showToast({
        type: "success",
        message: `cms ${t("deleted_successfully")}`,
      });
      setDialogVisible(false);
      dispatch(get_cms());
      dispatch(delete_one_cms_modal);
      handleDialogClose();
      dispatch(delete_one_cms_clear())
    }
  }, [deleteStatus]);

  const formatName = (value) => {
    value.map((y) => {
      return y.name;
    });
  };
  let system_cms_names = items?.map((x) => {
    return x.system_cms_names;
  });
  let columns = [
    {
      title: t("table:name"),
      dataIndex: "",
      key: "",
      sortable: false,
      align: "left",
      justifyContent: "center",
      render: (e) => {
        return <Text>{startCase(e?.system_cms_names?.[0]?.name)}</Text>;
      },
    },
    {
      title: t("table:key"),
      dataIndex: "key",
      key: "key",
      sortable: false,
      align: "left",
      justifyContent: "center",
    },
    {
      title: t("table:actions"),
      dataIndex: "",
      key: "actions",
      type: "actions",
      align: "center",
      type: "actions",
      width: 200,
      render: (e) => {
        return (
          <>
            <Space space={2}>
              <Pressable
                onPress={() => {
                  handleEdit(e);
                }}
              >
                <AiOutlineEdit color="#06b506" size={20} />
                {/* <CustomizeButton.IconButton icon={<AiOutlineEdit />} /> */}
              </Pressable>
              <Pressable
                onPress={() => {
                  handleDelete(e);
                }}
              >
                <AiOutlineDelete color="red" size={20} />
              </Pressable>
            </Space>
          </>
        );
      },
    },
  ];
  useEffect(() => {
    dispatch(
      get_cms({
        json: {
          page_number: page_number,
          page_limit: 100,
          search_string: search_string
        }
      })
    );
  }, [page_number, page_limit, search_string]);

  let header_actions = [
    {
      height: "50px",
      label: t("table:add_cms"),
      colorScheme: "primary",
      variant: "outline",
      _text: { color: "green" },
      style: { border: "1px solid green" },
      onPress: handleAdd,
    },
  ];
  const fetchData = (value, value1) => {
    setPageNumber(value);
    setPageLimit(value1);
  };

  return (
    <>
      <Box width="95%" marginLeft="20px" marginRight="20px">
        <Box position="sticky" top="0px" zIndex="2" bg="white">
          <Text fontSize="md" fontWeight="bold" py={4}>
            {t("CMS")}
          </Text>
        </Box>
        <HStack
          variant="admin_search_component"
          alignItems={"flex-end"}
          justifyContent={"flex-end"}
          space={3}
          padding={"10px"}
        >
          {header_actions?.map((headerAction, index) => (
            <Box key={`header-action-${index}`}>
              <ActionButton {...headerAction} />
            </Box>
          ))}
          <Box>
            <SearchBox
              width={300}
              label="Search"
              onSearch={handleSearch}
              onKeyPress={handleKeys}
            />
          </Box>
        </HStack>
        <VStack space={5}>
          <Wrapper>
            <Table
              loading={loading}
              dataSource={items}
              columns={columns}
              pagination={false}
            />
          </Wrapper>
          {!loading &&
            <HStack justifyContent={"flex-end"}>
              <Pagination
                total={total_items}
                page_number={page_number}
                // hideOnSinglePage={false}
                onChange={fetchData}
                showTotal={(total, range) => `Total ${countFormat(total)} items`}
                showSizeChanger
              />
            </HStack>
          }
        </VStack>
      </Box>
      <Dialog
        isOpen={dialogVisible}
        onClose={handleDialogClose}
        header={t("error:delete_cms")}
        content={t("error:delete_cms_content")}
        source={actionItem}
        actions={[
          {
            height: "40px",
            label: t("cancel"),
            colorScheme: "blueGray",
            variant: "ghost",
            onPress: handleDialogClose,
          },
          {
            height: "40px",
            width: "80px",
            label: t("delete"),
            colorScheme: "danger",
            variant: "outline",
            isLoading: deleteLoading,
            formErrorMessage: t("error:error_message"),
            submit: handleDeleteModel,
          },
        ]}
      />
    </>
  );
};
export default cmsList;
