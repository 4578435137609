import React, { useEffect } from "react";
import { Box, Hidden, Text, Stack, HStack } from "native-base";
import { useTranslation } from "react-i18next";
import New from "@assets/images/menu/New.png";
import { useDispatch, useSelector } from "react-redux";
import { dynamicRequest, useDynamicSelector } from "@services/redux";
import { FilterItems } from "@helpers/constants";
import { useState } from "react";
import { Skeleton } from "antd";
import moment from "moment";
import { Pressable } from "react-native-web";

const LatestNews = ({ news_data }) => {
  const { t } = useTranslation();
  // const dispatch = useDispatch();
  // const [news_data, setNewsData] = useState([]);

  // const { items: latest_news_items, loading } = useDynamicSelector(
  //   "get_cms_latest_news"
  // );
  // set_loading_status(loading);
  // const get_latest_news = () => {
  //   let keys = [{ key: "get_cms_latest_news", loading: true }];
  //   let variables = {
  //     json: {
  //       system_cms_key: "latest_news",
  //     },
  //   };
  //   dispatch(dynamicRequest(keys, get_cms_latest_news_query, variables));
  // };

  // useEffect(() => {
  //   get_latest_news();
  // }, []);

  // useEffect(() => {
  //   let values = FilterItems(latest_news_items);
  //   setNewsData(values);
  // }, [latest_news_items]);

  const handleClick = (news) => {
    if (news?.file?.value && news?.link?.value) {
      window.open(news?.file?.value, "_blank");
      window.open(news?.link?.value, "_blank");
    } else if (news?.file?.value) {
      window.open(news?.file?.value, "_blank");
    } else if (news?.link?.value) {
      window.open(news?.link?.value, "_blank");
    }
  };
  return (
    <Stack display="flex" flexDirection={{ xs: "row", lg: "column" }}>
      <img src={New} alt="alt" className="new-tag" />
      <Box
        variant="LatestNewsBox"
        mt={{ xs: "32px", md: "0", lg: "0" }}
        width={{ base: "420px", lg: "" }}
      >
        <Text
          fontSize={"var(--font-size-16)"}
          font-family="'Inter', sans-serif"
          color="black"
        >
          <marquee direction="left" >
            <HStack space={"10px"} alignItems={"center"}>
              {news_data.map((e, index) => {
                if (index !== news_data.length - 1) {
                  return (
                    <>
                      <Pressable onPress={() => handleClick(e)}>
                        <Text>{e?.titles?.value}</Text>
                      </Pressable>
                      <Text>|</Text>
                    </>
                  );
                } else {
                  return (
                    <Pressable onPress={() => handleClick(e)}>
                      <Text>{e?.titles?.value}</Text>
                    </Pressable>
                  );
                }
              })}
            </HStack>
          </marquee>
        </Text>
      </Box>
    </Stack>
  );
};
export default LatestNews;
