import MenuBar from "@views/components/banner/menubar";
import ContactUs from "@views/components/contact_us/contact_us";
import { Box, Hidden, Text } from "native-base";
import React from "react";
import { useTranslation } from "react-i18next";

const ContactUsPage = () => {
  const { t } = useTranslation();

  return (
    <div>
      <ContactUs />
    </div>
  );
};

export default ContactUsPage;
