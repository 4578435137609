import ScreenReaderAccessContent from "@views/components/screen_reader_access/screen_reader_access_content";
import React from "react";

const ScreenReaderAccessPage = () => {
  return (
    <div>
      <ScreenReaderAccessContent />
    </div>
  );
};

export default ScreenReaderAccessPage;
