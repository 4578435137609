import { FilterItems, RupeesFormat } from "@helpers/constants";
import { dynamicRequest, useDynamicSelector } from "@services/redux";
import {
  get_sports_hostel_details,
  query_get_all_system_cms_sports_data,
} from "@services/redux/slices/dynamic_entity/graphql/graphql_cms_sports_hostal";
import { Modal, Pagination } from "antd";
import { Box, HStack, Hidden, Pressable, Stack, Text } from "native-base";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { useDispatch } from "react-redux";
import ReactHtmlParser from "react-html-parser";

const SportsHostelOfExcellenceTable = () => {
  const { t } = useTranslation();
  const [open_index, setOpenIndex] = useState(0);
  const [hostel_details_modal, set_hostel_details_modal] = useState(false);
  const [hostel_details, set_hostel_details] = useState([]);

  const dispatch = useDispatch();
  const { items } = useDynamicSelector("get_cms_sports_hostel_details");
  const handleExpendable = (index) => {
    setOpenIndex(index);
  };
  const get_sports_hostel_list = () => {
    let keys = [{ key: "get_cms_sports_hostel_details", loading: true }];
    let variables = {
      json: {
        system_cms_key: "sports hostel of excellence",
      },
    };
    dispatch(dynamicRequest(keys, get_sports_hostel_details, variables));
  };
  useEffect(() => {
    get_sports_hostel_list();
  }, []);
  useEffect(() => {
    let values = FilterItems(items);
    set_hostel_details(values);
  }, [items]);
  const handle_details = (log) => {
    set_hostel_details_modal(true);
  };
  const handle_close = () => {
    set_hostel_details_modal(false);
    set_hostel_details({});
  };
  return (
    <div>
      <table class="table">
        <thead className="table-header">
          <tr>
            <th className="radius-right">{t("table:s_no")}</th>
            <th className="radius-left">
              {t("table:name_of_sports_hostel_of_excellence")}
            </th>
          </tr>
        </thead>
        <tbody>
          {hostel_details?.map((tablelist, index) => {
            let odd_index = 0;
            let even_index = Math.round(
              tablelist?.system_cms_sports_data_value?.length / 2
            );
            return (
              <>
                <tr
                  className={`space-add ${
                    hostel_details.length - 1 !== index
                      ? "table-border-bottom"
                      : ""
                  }`}
                >
                  <td className="index-padding">{index + 1}</td>
                  <td>
                    <div
                      onClick={() => handleExpendable(index)}
                      className={`table_row ${
                        open_index === index
                          ? `table_row_height`
                          : `table_row_normal`
                      }`}
                    >
                      {open_index === index ? (
                        <p className="competition_name">
                          <HStack variant={"table_title_tag"}>
                            <Box width={"20px"}>
                              <AiOutlineMinus />
                            </Box>
                            <Text variant={"table_title"}>
                              {tablelist?.["name"]?.value}
                            </Text>
                          </HStack>
                        </p>
                      ) : (
                        <p className="competition_name">
                          <HStack variant={"table_title_tag"}>
                            <AiOutlinePlus fontWeight={600} />
                            <Text variant={"table_title"}>
                              {tablelist?.["name"]?.value}
                            </Text>
                          </HStack>
                        </p>
                      )}
                      <div
                        className={`table_open ${
                          open_index === index
                            ? `table_active`
                            : `table_inactive`
                        }`}
                      >
                        <div className="hostel_lists">
                          {ReactHtmlParser(tablelist?.details?.value || "")}
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </>
            );
          })}
        </tbody>
      </table>
      {/* <div className="pagination-box">
        <Stack variant={"pagination_stack"}>
          <Hidden from="base" till="lg">
            <Box justifyContent={"center"}>
              <Text>Records per page</Text>
            </Box>
          </Hidden>
          <Box variant={"pagination_box"}>
            <Pagination
              total={85}
              //   showTotal={(total) => `Records per page ${total} items`}
              defaultPageSize={20}
              defaultCurrent={1}
            />
          </Box>
        </Stack>
      </div> */}
      <Modal
        open={hostel_details_modal}
        onCancel={handle_close}
        footer={null}
        title={hostel_details?.name + " " + "details"}
      >
        {ReactHtmlParser(hostel_details?.details || "")}
      </Modal>
    </div>
  );
};

export default SportsHostelOfExcellenceTable;
