import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, VStack, Text, HStack } from "native-base";
import Dialog from "@views/components/ui/dialog";
import Wrapper from "@views/components/ui/wrapper";
// import Table from "@views/components/ui/table";
import { useTranslation } from "react-i18next";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
// import Modal from "@views/components/ui/modal";
import { showToast } from "@helpers/toast";
import PageDetails from "./page_details";
import {
  getPageListSelector,
  get_page,
  deletePageSelector,
  delete_one_page_modal,
  delete_page,
  get_one_page_clear,
  get_one_page,
  create_one_page_clear,
  update_one_page_clear,
  createPageSelector,
  updatePageSelector,
  generate_page,
} from "@services/redux";
import { GiRegeneration } from "react-icons/gi";
import ActionButton from "@views/components/ui/dialog/action_button";
import SearchBox from "@views/components/ui/search_box/search_box";
import { Modal, Table } from "antd";
const PageList = () => {
  const dispatch = useDispatch();
  const [actionType, setActionType] = useState(null);
  const [actionItem, setActionItem] = useState({});
  const [manageModalVisible, setManageModalVisible] = useState(false);
  const { t } = useTranslation();
  const [dialogVisible, setDialogVisible] = useState(false);

  const { status: pageCreateStatus, error: pageCreateError } =
    useSelector(createPageSelector);
  const {
    loading: updateLoading,
    status: pageUpdateStatus,
    error: pageUpdateError,
  } = useSelector(updatePageSelector);

  const handleAdd = () => {
    dispatch(get_one_page_clear());
    setActionType("add");
    setActionItem({});
    setManageModalVisible(true);
  };

  const handleEdit = (item) => {
    dispatch(get_one_page({ id: item.id }));
    setActionItem({
      ...item,
      id: item.id,
      name: item.name,
      description: item.description,
    });
    setActionType("edit");
    setManageModalVisible(true);
  };

  const handleDelete = (item) => {
    setActionItem(item);
    setDialogVisible(true);
  };
  const handleGenerate = (item) => {
    dispatch(generate_page({ id: item.id }));
  };
  const handleModalClose = () => {
    setManageModalVisible(false);
  };

  const handleDialogClose = () => {
    setDialogVisible(false);
  };
  const { loading, items, total_items } = useSelector(getPageListSelector);
  const {
    loading: deleteLoading,
    status: deleteStatus,
    error: deleteError,
  } = useSelector(deletePageSelector);

  useEffect(() => {
    if (deleteError) {
      setDialogVisible(false);
      showToast({
        type: "error",
        message: deleteError,
      });
    } else if (deleteStatus === "Success") {
      showToast({
        type: "success",
        message: `Page ${t("deleted_successfully")}`,
      });
      setDialogVisible(false);

      dispatch(get_page());
      dispatch(delete_one_page_modal());
      handleDialogClose();
    }
  }, [deleteStatus]);

  useEffect(() => {
    if (pageCreateError) {
      showToast({
        type: "error",
        message: pageCreateError?.message,
      });
      dispatch(create_one_page_clear());
    } else if (pageUpdateError === "error") {
      showToast({
        type: "error",
        message: pageUpdateError?.message,
      });
      dispatch(update_one_page_clear());
    } else if (pageCreateStatus === "Success") {
      showToast({
        type: "success",
        message: `Page ${t("create_successfully")}`,
      });
      dispatch(create_one_page_clear());
      dispatch(get_page());
      setManageModalVisible(false);
      // close();
    } else if (pageUpdateStatus === "Success") {
      showToast({
        type: "success",
        message: `Page ${t("update_successfully")}`,
      });
      dispatch(update_one_page_clear);
      dispatch(get_page());
      setManageModalVisible(false);
    }
  }, [pageCreateStatus, pageUpdateStatus]);

  let column = [
    {
      title: t("table:name"),
      dataIndex: "name",
      key: "name",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      title: t("table:html_components"),
      dataIndex: "html_components",
      key: "html_components",
      value_key: "key",
      sortable: false,
      align: "left",
      flex: 1,
      type: "array",
    },
    {
      title: t("table:table_components"),
      dataIndex: "table_components",
      key: "table_components",
      sortable: false,
      value_key: "key",
      align: "left",
      flex: 1,
      type: "array",
    },
    {
      title: t("table:card_components"),
      dataIndex: "card_components",
      key: "card_components",
      value_key: "key",
      sortable: false,
      align: "left",
      flex: 1,
      type: "array",
    },
    {
      title: t("table:actions"),
      dataIndex: "",
      key: "actions",
      type: "actions",
      align: "center",
      type: "actions",
      width: 200,
      //   actions: [
      //     {
      //       icon: <AiOutlineEdit />,
      //       iconSize: 30,
      //       iconColor: "primary.700",
      //       title: t("table:edit_page"),
      //       onPress: handleEdit,
      //     },
      //     {
      //       icon: <AiOutlineDelete />,
      //       iconSize: 30,
      //       iconColor: "red.700",
      //       title: t("table:delete_page"),
      //       onPress: handleDelete,
      //     },
      //     {
      //       icon: <GiRegeneration />,
      //       iconSize: 30,
      //       iconColor: "green.700",
      //       title: t("table:generate_page"),
      //       onPress: handleGenerate,
      //     },
      //   ],
      render: (e) => {
        return (
          <>
            <Space space={2}>
              <Pressable
                onPress={() => {
                  handleEdit(e.id);
                }}
              >
                <AiOutlineEdit color="#06b506" size={20} />
                {/* <CustomizeButton.IconButton icon={<AiOutlineEdit />} /> */}
              </Pressable>
              <Pressable
                onPress={() => {
                  handleDelete(e.id);
                }}
              >
                <AiOutlineDelete color="red" size={20} />
              </Pressable>
              <Pressable
                onPress={() => {
                  handleGenerate(e.id);
                }}
              >
                <handleGenerate color="red" size={20} />
              </Pressable>
            </Space>
          </>
        );
      },
    },
  ];
  let modal_actions = [
    {
      height: "50px",
      label: t("table:close"),
      colorScheme: "primary",
      variant: "ghost",
      onPress: handleModalClose,
    },
    {
      height: "50px",
      label: t("Submit"),
      colorScheme: "primary",
      variant: "outline",
      type: "submit",
      errorMessage: t("error:error_message"),
    },
  ];

  let header_actions = [
    {
      height: "50px",
      label: t("table:add_page"),
      colorScheme: "primary",
      variant: "outline",
      onPress: handleAdd,
    },
  ];

  return (
    <>
      <Box width="100%" mt="10px" marginBottom="20px">
        <Box position="sticky" top="0px" zIndex="2" bg="white">
          <Text fontSize="md" fontWeight="bold" py={4}>
            {t("table:Pages")}
          </Text>
        </Box>
        <HStack
          variant="admin_search_component"
          alignItems={"flex-end"}
          justifyContent={"flex-end"}
          space={3}
          padding={"10px"}
        >
          {header_actions?.map((headerAction, index) => (
            <Box key={`title-action-${index}`}>
              <ActionButton {...headerAction} />
            </Box>
          ))}
          <Box>
            <SearchBox
              width={300}
              label="Search"
              //   onSearch={handleSearch}
              //   onKeyPress={handleKeys}
            />
          </Box>
        </HStack>
        <VStack space={5}>
          <Table dataSource={items} columns={column} />
          {/* <Wrapper>
            <Table
              width="100%"
              rowKey="id"
              group="ProgrammeSessions"
              loading={loading}
              data={items}
              column={column}
              //   hasPagination
              //   totalItems={total_items}
              //   fetch={get_page}
              query={null}
              //   headerActions={header_actions}
              //   emptyMessage={t("table:empty_page")}
              headerColor={"white"}
            />
          </Wrapper> */}
        </VStack>
      </Box>

      {/* <Modal
        id="manage_user"
        isOpen={manageModalVisible}
        onClose={handleModalClose}
        title={
          actionType === "add" ? t("form:add_page") : t("form:update_page")
        }
        component={<PageDetails />}
        source={actionItem}
        size={"container"}
        actions={modal_actions}
          /> */}
      <Modal
        open={manageModalVisible}
        onCancel={handleModalClose}
        title={
          actionType === "add" ? t("form:add_page") : t("form:update_page")
        }
        width={500}
        footer={null}
      >
        <PageDetails onClose={handleModalClose} />
      </Modal>

      <Dialog
        isOpen={dialogVisible}
        onClose={handleDialogClose}
        title={t("table:delete_page")}
        content={t("table:delete_page_content")}
        source={actionItem}
        actions={[
          {
            height: "40px",
            label: t("cancel"),
            colorScheme: "blueGray",
            variant: "ghost",
            onPress: handleDialogClose,
          },
          {
            height: "40px",
            width: "80px",
            label: t("delete"),
            colorScheme: "danger",
            variant: "outline",
            isLoading: deleteLoading,
            formErrorMessage: t("error:error_message"),
            submit: delete_page,
          },
        ]}
      />
    </>
  );
};
export default PageList;
