import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { login_query } from "./graphql";
import { storeItem } from "@helpers/storage";
import { decryptData } from "@helpers/crypto";

const initialState = {
  loading: false,
  error: null,
  item: null,
  authorize: false,
  is_profile_completed: false,
  roles: [],
};

const slice = createSlice({
  name: "login",
  initialState,
  reducers: {
    _login: (state) => {
      state.loading = true;
      state.item = null;
      state.error = null;
      state.status = null;
      state.authorize = false;
      state.roles = [];
    },
    _login_authorize: (state) => {
      state.loading = false;
      state.item = null;
      state.error = null;
      state.authorize = false;
      state.roles = [];
      state.status = null;
    },
    _login_success: (state, { payload }) => {
      let decrypt_payload = decryptData(payload?.data);
      state.item = decrypt_payload;
      state.roles = decrypt_payload?.roles;
      storeItem("roles", JSON.stringify(decrypt_payload?.roles));
      storeItem("user", JSON.stringify(decrypt_payload));
      storeItem("user_id", decrypt_payload?.user_id);
      storeItem("session_id", decrypt_payload?.session_id);
      state.error = null;
      state.status = decrypt_payload?.status;
      state.loading = false;
      state.authorize = true;
    },
    _login_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
      state.authorize = false;
      state.item = null;
      state.roles = [];
      state.status = null;
    },
  },
});

const { _login, _login_success, _login_authorize, _login_failure } =
  slice.actions;

export const loginSelector = (state) => state.login;

export const loginReducer = slice.reducer;

export function login(variables) {
  return async (dispatch) => {
    dispatch(_login());
    try {
      const response = await QueryRequest(login_query, variables, dispatch);
      if (response?.data?.login && !response?.data?.login.error) {
        dispatch(_login_success(response?.data?.login));
      } else if (response?.data?.login?.error) {
        dispatch(_login_failure(response?.data?.login.error));
      }
    } catch (error) {
      dispatch(_login_failure(error));
    }
  };
}

export function setAuthorize() {
  return async (dispatch) => {
    dispatch(_login_authorize());
  };
}
export function set_login_reset() {
  return (dispatch) => {
    dispatch(_login_authorize);
  };
}
