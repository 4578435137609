import { Box, Card, HStack, SimpleGrid, Text, VStack } from "native-base";
import React, { useEffect, useState } from "react";
// import MeghanadhReddy from "@assets/images/menu/j-meghanadh-reddy.jpg";
import NoProfile from "@assets/images/menu/user.png";
import ScrollView from "../ui/scroll_view/scroll_view";
import { get_cms_contact_us_card_query } from "@services/redux/slices/dynamic_entity/graphql/graphql_cms_contact_us_card_query";
import { dynamicRequest, useDynamicSelector } from "@services/redux";
import { useDispatch } from "react-redux";
import { FilterItems } from "@helpers/constants";
import ContactCardLoading from "./contact_card_loading";

const ContactUsCard = ({ contact_data }) => {
  const dispatch = useDispatch();

  return (
    <>
      {/* {loading ? (
        <ContactCardLoading />
      ) : ( */}
      <div className="contactUs-card">
        <VStack space={4}>
          {contact_data?.map((s, i) => (
            <Box
              key={i}
              minW={"150px"}
              height={"auto"}
              width={"100%"}
              padding={{
                base: "20px",
                xs: "10px",
                lg: "25px",
                "2xl": "30px",
              }}
              style={{
                boxShadow: "0px 4px 15px rgba(0,0,0,0.08)",
                borderRadius: "10px",
                justifyContent: "space-between",
                // alignItems: "center",
              }}
              display={"flex"}
              flexDirection={{
                md: "row",
                xs: "column",
                lg: "row",
                xl: "row",
                "2xl": "row",
                "3xl": "row",
              }}
              justifyContent={"center"}
              alignItems={"center"}
            >
              {s?.image?.value === " " ? (
                <Box width={"40%"} alignItems={"center"}>
                  <img
                    src={NoProfile}
                    alt="person_img"
                    className="contact_us_card_image"
                  />
                </Box>
              ) : (
                <Box width={"40%"} alignItems={"center"}>
                  <img
                    src={s?.image?.value}
                    alt="person_img"
                    className="contact_us_card_image"
                  />
                </Box>
              )}

              <VStack
                justifyContent={{
                  base: "start",
                  xs: "center",
                  sm: "center",
                  lg: "start",
                  xl: "start",
                  "2xl": "start",
                  "3xl": "start",
                }}
                space={2}
                minW={"250px"}
                width={"60%"}
                alignItems={{
                  base: "start",
                  xs: "center",
                  sm: "center",
                  lg: "start",
                  xl: "start",
                  "2xl": "start",
                  "3xl": "start",
                }}
              >
                <Text
                  fontSize={{ xs: "var(--font-size-14)", xl: "var(--font-size-24)", md: "var(--font-size-20)" }}
                  fontWeight={"bold"}
                  fontFamily={"Inter"}
                >
                  {s?.name?.value}
                </Text>
                <Text
                  fontFamily={"Inter"}
                  fontSize={{ xs: "var(--font-size-8)", xl: "var(--font-size-18)", md: "var(--font-size-14)" }}
                  color={"#828282"}
                >
                  {s?.position?.value}
                </Text>
              </VStack>
            </Box>
          ))}
        </VStack>
      </div>
      {/* )} */}
    </>
  );
};

export default ContactUsCard;
