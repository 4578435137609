import { Box, SimpleGrid, Text, VStack } from "native-base";
import React from "react";
import { Card } from "antd";
import NoProfile from "@assets/images/menu/user.png";

const ExecutiveComponent = (props) => {
  const { persondetails } = props;

  return (
    <Box>
      <SimpleGrid
        columns={{
          base: 2,
          sm: 2,
          xs: 1,
          md: 2,
          lg: 3,
          xl: 3,
          "2xl": 5,
          "3xl": 6,
        }}
        space={["1", "1", "2", "25px", "85px", "105px", "185px"]}
        justifyContent={"center"}
        alignItems={"center"}
        // marginLeft={"60px"}
        // marginRight={"60px"}
      >
        {persondetails?.map((s) => {
          return (
            <Box
              marginBottom={{
                xs: "-150px",
                md: "100px",
                lg: "50px",
                xl: "-64px",
                "2xl": "100px",
                "3xl": "100px",
              }}
            >
              <Card
                width={"370px"}
                style={{
                  width: "370px",
                  borderRadius: "12px",
                  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.05)",
                  background: "#fff",
                  maxHeight: "500px",
                  marginBottom: "60px",
                }}
                hoverable
              >

                {s?.profile?.value === " " ? (
                  <Box variant={"executive_image_box"}>
                    <img
                      src={NoProfile}
                      alt={"StalinImage"}
                      className="imagesbd"
                    />
                  </Box>
                ) : (
                  <Box variant={"executive_image_box"}>
                    <img
                      src={s?.profile?.value}
                      alt={"StalinImage"}
                      className="imagesbd"
                    />
                  </Box>
                )}

                <VStack
                // height={"auto"}
                // justifyContent={"end"}
                // alignContent={"end"}
                >
                  <Box variant={"executive_text_box"}>
                    <Text variant={"executive_title"}>{s?.name?.value}</Text>
                    <Text variant={"executive_position"}>
                      {s?.designation?.value}
                    </Text>
                    <Text variant={"executive_address"}>
                      {s?.address?.value}
                    </Text>
                  </Box>
                </VStack>
              </Card>
            </Box>
          );
        })}
      </SimpleGrid>
    </Box>
  );
};

export default ExecutiveComponent;
