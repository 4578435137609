import { Box, HStack, Stack } from "native-base";
import React from "react";
import GalleryPage from "./gallery_page";
import TwitterPage from "./twitter_page";
const GalleryAndTwitterPage = ({ gallery_data }) => {
  return (
    <Box variant="galleryAndFooterPage">
      <Stack
        display={"flex"}
        flexDirection={{ xs: "column", md: "column", lg: "row", xl: "row" }}
        style={{ gap: "20px" }}
        // space={10}
      >
        {/* <Box
        // width={{
        //   md: "500px",
        // }}
        // height={{
        //   md: "300px",
        // }}
        // > */}
        <TwitterPage />
        <GalleryPage gallery_data={gallery_data} />
        {/* </Box> */}
      </Stack>
    </Box>
  );
};

export default GalleryAndTwitterPage;
