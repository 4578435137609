import { FilterItems } from "@helpers/constants";
import { dynamicRequest, useDynamicSelector } from "@services/redux";
import { get_cms_by_laws_query } from "@services/redux/slices/dynamic_entity/graphql/graphql_cms_by_laws_query";
import { Button, Card, Form, Select, Skeleton } from "antd";
import { Box, Text, HStack, VStack, Stack } from "native-base";
import React, { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

const PolicyNote = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { laws_data, Details } = props;
  const [download, setDownload] = useState();
  // const [laws_data, setLawsData] = useState([]);

  // const { items: laws_items, loading } = useDynamicSelector("get_cms_by_laws");

  let year = laws_data?.map((s) => {
    const ss = { label: s?.year?.value, value: s?.document?.value };
    return ss;
  });
  const handleChangeYear = (e) => {
    setDownload(e?.value);
  };
  const handleDownload = () => {
    window.open(download);
  };

  return (
    <Box>
      <Card
        style={{
          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
          borderRadius: "12px",
        }}
        hoverable
      >
        <Stack
          justifyContent={{
            xs: "center",
            lg: "space-around",
            xl: "space-around",
          }}
          alignItems={"center"}
          display={"flex"}
          flexDirection={{ xs: "column", md: "column", lg: "row" }}
          space={{ xs: "20px", md: "10px" }}
        >
          <Box variant={"Service_left"} width={"100px"}>
            <div
              className="service-left-icon"
              style={{
                background: "#E1DBEF",
                width: "80px",
                height: "80px",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <img
                src={Details.icon}
                alt="${service_list.name}"
                width={"50px"}
              // style={{padding:"1px"}}
              />
            </div>
          </Box>
          <Box>
            <VStack
              width={{ xs: "300px", md: "600px" }}
              alignItems={{ xs: "center" }}
            >
              <Text
                variant="HeadLineTextOne"
                fontSize={{ xs: "var(--font-size-20)", md: "var(--font-size-25)" }}
              >
                {Details?.name}
              </Text>
              <Text
                fontFamily={"Inter"}
                fontWeight={"600"}
                color={"black"}
                fontSize={{ xs: "var(--font-size-8)", md: "var(--font-size-15)" }}
              >
                {Details?.description}
              </Text>
            </VStack>
          </Box>
          <Box>
            {/* <h3 className="select-label">Select Year Range</h3> */}
            <Select
              style={{
                width: 200,
              }}
              className="antd-select"
              options={year}
              labelInValue
              onChange={handleChangeYear}
            />
          </Box>
          <Button
            // href={download}
            style={{
              borderRadius: "12px",
              width: "150px",
              height: "40px",
              border: "1px solid #3B1B7E",
            }}
            onClick={handleDownload}
            disabled={download ? false : true}
          >
            <Text color={"#3B1B7E"}>{t("download")}</Text>
          </Button>
        </Stack>
      </Card>
    </Box>
  );
};

export default PolicyNote;
