import { Box, Text, Image, Center } from "native-base";
import React, { useEffect, useState } from "react";
// import SportImg1 from "../../../assets/images/menu/kabaddi.jpg";
// import SportImg2 from "../../../assets/images/menu/sprots2.jpg";
// import SportImg3 from "../../../assets/images/menu/campion.png";
// import SportImg4 from "../../../assets/images/menu/jumping.png";
// import SportImg5 from "../../../assets/images/menu/sprotsimage.jpg";
// import ChessOne from "@assets/images/menu/background_layer/Chess_one.jpg";
// import ChessTwo from "@assets/images/menu/background_layer/chess_two.jpg";
// import Cricket from "@assets/images/menu/background_layer/cricket.jpg";
// import HighJump from "@assets/images/menu/background_layer/high_jump.jpg";
// import Kabadi from "@assets/images/menu/background_layer/kabadi.jpg";
// import Running from "@assets/images/menu/background_layer/running.jpg";
// import HockeyOne from "@assets/images/menu/background_layer/hockey_one.jpg";
// import HockeyTwo from "@assets/images/menu/background_layer/hockey_two.jpg";
// import HockeyThree from "@assets/images/menu/background_layer/hockey_three.jpg";
// import HockeyFour from "@assets/images/menu/background_layer/hockey_four.jpg";
// import HockeyFive from "@assets/images/menu/background_layer/hockey_five.jpg";
// import GroupPhoto from "@assets/images/menu/background_layer/group_photo.jpg";
// import HockeyGroupPhoto from "@assets/images/menu/background_layer/hockey_group_photo.jpg";
// import YogaOne from "@assets/images/menu/background_layer/yoga_one.jpg";
// import YogaTwo from "@assets/images/menu/background_layer/yoga_two.jpg";
// import YogaThree from "@assets/images/menu/background_layer/yoga_three.jpg";
// import YogaFour from "@assets/images/menu/background_layer/yoga_four.jpg";
// import YogaFive from "@assets/images/menu/background_layer/yoga_five.jpg";
// import YogaGroupPhoto from "@assets/images/menu/background_layer/yoga_group_photo.jpg";
// import WinningOne from "@assets/images/menu/background_layer/winning_one.jpg";
// import WinningTwo from "@assets/images/menu/background_layer/winning_two.jpg";
// import WinningThree from "@assets/images/menu/background_layer/winning_three.jpg";
// import WinningFour from "@assets/images/menu/background_layer/winning_four.jpg";
// import WinningFive from "@assets/images/menu/background_layer/winning_five.jpg";
// import WinningCup from "@assets/images/menu/background_layer/winning_cup.jpg";

import MenuBar, { MenuBarContent } from "./menubar";
import Carousel from "react-multi-carousel";
import { get_one_cms_query } from "@services/redux/slices/cms/graphql";
import {
  dynamicRequest,
  get_system_cms_field,
  get_system_cms_value_clear,
  getsystemCMSFieldListSelector,
  useDynamicSelector,
} from "@services/redux";
import { useDispatch, useSelector } from "react-redux";
import { get_cms_query } from "@services/redux/slices/dynamic_entity/graphql/graphql_cms";
import { FilterItems } from "@helpers/constants";
import { get_cms_homepage_slider_query } from "@services/redux/slices/dynamic_entity/graphql/graphql_cms_homepage_slider_query";
const BackgroundLayer = ({ slider_data }) => {
  // const { t } = useTransition();
  // const dispatch = useDispatch();
  // const [slider_data, setSliderData] = useState([]);

  // const { items } = useSelector(getsystemCMSFieldListSelector);
  // const { items: slider_items, loading } = useDynamicSelector(
  //   "get_cms_homepage_slider"
  // );
  // set_loading_status(loading);
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1,
    },
    large_laptop: {
      breakpoint: { max: 2560, min: 1640 },
      items: 1,
      slidesToSlide: 1,
    },
    laptop: {
      breakpoint: { max: 1024, min: 480 },
      items: 1,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
    tablet1: {
      breakpoint: { max: 768, min: 480 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  // useEffect(() => {
  //   dispatch(
  //     get_system_cms_field({
  //       json: {
  //         system_cms_key: "homepage_slider",
  //       },
  //     })
  //   );
  // }, []);

  // const get_homepage_slider = () => {
  //   let keys = [{ key: "get_cms_homepage_slider", loading: true }];
  //   let variables = {
  //     json: {
  //       system_cms_key: "homepage_slider",
  //     },
  //   };
  //   dispatch(dynamicRequest(keys, get_cms_homepage_slider_query, variables));
  // };

  // useEffect(() => {
  //   get_homepage_slider();
  // }, []);

  // useEffect(() => {
  //   let values = FilterItems(slider_items);
  //   setSliderData(values);
  // }, [slider_items]);

  // const layer_images = [
  //   {
  //     img2: WinningOne,
  //     img1: WinningTwo,
  //     img3: WinningThree,
  //     img6: WinningFour,
  //     img5: WinningFive,
  //     img4: WinningCup,
  //   },
  //   {
  //     img1: SportImg1,
  //     img2: Running,
  //     img3: SportImg3,
  //     img4: SportImg4,
  //     img5: SportImg5,
  //     img6: SportImg2,
  //   },
  //   {
  //     img1: HighJump,
  //     img2: Cricket,
  //     img3: ChessTwo,
  //     img4: Kabadi,
  //     img5: ChessOne,
  //     img6: GroupPhoto,
  //   },
  //   {
  //     img1: HockeyOne,
  //     img2: HockeyTwo,
  //     img3: HockeyThree,
  //     img4: HockeyFour,
  //     img5: HockeyFive,
  //     img6: HockeyGroupPhoto,
  //   },
  //   {
  //     img2: YogaOne,
  //     img1: YogaTwo,
  //     img3: YogaThree,
  //     img5: YogaFour,
  //     img4: YogaFive,
  //     img6: YogaGroupPhoto,
  //   },
  // ];

  return (
    <>
      <Box
        background={
          "linear-gradient(45deg, rgb(31 0 95), rgb(167 18 173 / 58%))"
        }
      >
        <Carousel
          responsive={responsive}
          customLeftArrow={<></>}
          customRightArrow={<></>}
          infinite={true}
          autoPlay
          autoPlaySpeed={2800}
        >
          {slider_data?.map((layer_img) => {
            return (
              <>
                <Box variant="bannerContainer" style={{ padding: "45px" }}>
                  <Box>
                    <Box variant="bannerBackgroundImage">
                      <Image
                        size={"100%"}
                        resizeMode="cover"
                        rounded={8}
                        // src={layer_img?.img6}
                        source={{
                          uri: layer_img?.image_six?.value,
                        }}
                        alt="image"
                        style={{ opacity: 0.5 }}
                      />
                    </Box>
                  </Box>
                  <Box variant="bannerAllImages">
                    {/* 1st image */}
                    <Box variant="backgroundImageOne">
                      <Image
                        variant="bannerImages"
                        source={{
                          uri: layer_img?.image_one?.value,
                        }}
                        // src={layer_img?.img1}
                        alt="image"
                      />
                    </Box>
                    {/* 2nd image */}
                    <Box variant="backgroundImageTwo">
                      <Image
                        variant="bannerImages"
                        source={{
                          uri: layer_img?.image_two?.value,
                        }}
                        // src={layer_img?.img2}
                        alt="image"
                      />
                    </Box>
                    <Box variant="backgroundImageThree">
                      <Image
                        variant="bannerImages"
                        source={{
                          uri: layer_img?.image_three?.value,
                        }}
                        // src={layer_img?.img3}
                        alt="image"
                      />
                    </Box>
                    {/* 4th image */}
                    <Box variant="backgroundImageFour">
                      <Image
                        variant="bannerImages"
                        source={{
                          uri: layer_img?.imgae_four?.value,
                        }}
                        // src={layer_img?.img4}
                        alt="image"
                      />
                    </Box>
                    {/* 5th images */}
                    <Box variant="backgroundImageFifth">
                      <Image
                        variant="bannerImages"
                        source={{
                          uri: layer_img?.image_five?.value,
                        }}
                        // src={layer_img?.img5}
                        alt="image"
                      />
                    </Box>
                  </Box>
                </Box>
              </>
            );
          })}
        </Carousel>
        <Box variant="BackgroundTextBoxLayer">
          <MenuBar />
          {/* bannerEnglish name */}
          <Box
            width={{ xs: "100%", lg: "80%", xl: "64%", "2xl": "50%" }}
            textAlign={"start"}
            ml={{ xs: "20px", lg: "30px", xl: "40px" }}
            display="flex"
            alignItems="start"
            marginTop={"-20px"}
          >
            <Text variant="BannerEnglishTitle">தமிழ்நாடு விளையாட்டு</Text>
            <Text variant="BannerEnglishTitle" style={{ marginTop: "auto" }}>
              மேம்பாட்டு ஆணையம்
            </Text>
            <Text variant={"BannerTamilTitle"} style={{ marginLeft: "4px" }}>
              SPORTS DEVELOPMENT AUTHORITY OF TAMILNADU
            </Text>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default BackgroundLayer;
