import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
import { update_system_cms_value_mutation } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  id: null,
  status: null,
};

const slice = createSlice({
  name: "systemCMSValueUpdate",
  initialState,
  reducers: {
    _update_system_cms_value: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _update_system_cms_value_reset: (state) => {
      state.loading = false;
      state.id = null;
      state.status = null;
      state.error = null;
    },
    _update_system_cms_value_success: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = payload.id;
      state.status = payload.status;
    },
    _update_system_cms_value_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
    },
  },
});

const {
  _update_system_cms_value,
  _update_system_cms_value_reset,
  _update_system_cms_value_success,
  _update_system_cms_value_failure,
} = slice.actions;

export const updatesystemCMSValueSelector = (state) =>
  state.systemCMSValueUpdate;

export const updatesystemCMSValueReducer = slice.reducer;

export function update_system_cms_value(variables) {
  return async (dispatch) => {
    dispatch(_update_system_cms_value());
    try {
      const response = await MutateRequest(
        update_system_cms_value_mutation,
        variables,
        dispatch
      );
      if (
        response?.data?.updateSystemCMSValue &&
        !response?.data?.updateSystemCMSValue.error
      ) {
        dispatch(
          _update_system_cms_value_success(response?.data?.updateSystemCMSValue)
        );
      } else if (response?.data?.updateSystemCMSValue?.error) {
        dispatch(
          _update_system_cms_value_failure(
            response?.data?.updateSystemCMSValue.error
          )
        );
      }
    } catch (error) {
      dispatch(_update_system_cms_value_failure(error));
    }
  };
}
export async function update_one_system_cms_value_clear() {
  return async (dispatch) => {
    dispatch(_update_system_cms_value_reset());
  };
}
