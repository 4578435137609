import { Box, Button, Center, HStack, Hidden, Link, Text } from "native-base";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ScrollView from "../ui/scroll_view/scroll_view";
import { startCase } from "lodash";
import { height } from "styled-system";
import { ROUTES } from "@views/routes/my_routes";
import { dynamicRequest, useDynamicSelector } from "@services/redux";
import { useDispatch } from "react-redux";
import { get_cms_contact_details_query } from "@services/redux/slices/dynamic_entity/graphql/graphql_cms_contact_details_query";
import { FilterItems } from "@helpers/constants";
import ReactHtmlParser from "react-html-parser";
import { useHistory } from "react-router-dom";

const ContactUsWelfareOfficers = () => {
  const { t } = useTranslation();
  const [contact_data, setContactData] = useState([]);
  const [mobile_contact_us, set_mobile_contact_us] = useState([]);
  const history = useHistory()


  const dispatch = useDispatch();
  const handleExpendable = (index) => {
    setOpenIndex(index);
  };
  const { items: contact_items, loading } = useDynamicSelector(
    "get_cms_contact_details"
  );

  const get_contact_details = () => {
    let keys = [{ key: "get_cms_contact_details", loading: true }];
    let variables = {
      json: {
        system_cms_key: "contact_us_youth_welfare_officers",
      },
    };
    dispatch(dynamicRequest(keys, get_cms_contact_details_query, variables));
  };

  useEffect(() => {
    get_contact_details();
  }, []);

  useEffect(() => {
    let values = FilterItems(contact_items);
    setContactData(values);
  }, [contact_items]);

  let splitValuesssss = contact_data?.[2]?.contact_details?.value?.split("</tr>")?.slice(0, 1)
  let content = contact_data?.[2]?.contact_details?.value?.split("</tr>")?.slice(1, 7)

  useEffect(() => {
    let splitValues = contact_data?.[2]?.contact_details?.value?.split("</td>")?.slice(1,29)
    if (splitValues && splitValues?.length >= 3) {
      let groups = [];
      let numberOfGroups = splitValues?.length / 3;

      for (let i = 0; i < numberOfGroups; i++) {
        let groupObject = {
          id: i + 1,
          values: splitValues.slice(i * 4, (i + 1) * 4).map(value => value.trim())
        };
        if (groupObject) {
          groups.push(groupObject);
        }
      }
      set_mobile_contact_us(groups)
    } else {
      console.error("Invalid splitValues:", splitValues);
    }
  }, [contact_data])
  const handle_redirect = () => {
    history.push(ROUTES.CONTACT_DETAILS)
  }
  return (
    <div
      style={{
        width: "-webkit-fill-available",
        minHeight: "450px",
      }}
    >
      <Box
        marginBottom={"10px"}
        width={"100%"}
        height={"50px"}
        justifyContent={"center"}
        textAlign={{
          xs: "center",
          sm: "center",
          md: "start",
          xl: "start",
          lg: "start",
          "2xl": "start",
          "3xl": "start",
        }}
      >
        <Text fontFamily={"Inter"} fontSize={"2.8vh"} fontWeight={"bold"}>
          {/* {t("regional_senior_managers")} */}
          {contact_data?.[2]?.name_of_contact_details?.value}
        </Text>
      </Box>
      <Hidden from="base" till="lg">
        <div style={{ maxHeight: "fit-content", height: "fit-content", overflow: "scroll" }}>
          {/* {ReactHtmlParser(
            contact_data?.[2]?.contact_details?.value)} */}
          {splitValuesssss?.map((list) => {
            return ReactHtmlParser(list.replace(/40.9817%/g, "35.9817%")?.replace(/11.658%/g, "15.658%")?.replace(/35.9817%/g,"40.9817%"))
          })}
          {content?.map((list) => {
            return ReactHtmlParser(list?.replace(/11.658%/g, "17.658%;padding:20px")?.replace(/text-align: center/g, "text-align: center;width: 20%;").replace(/text-align: left/g, "text-align: left;width: 20%;"))
          })}
        </div>
        <a
          href="contact-details"
          style={{ display: "block", textAlign: "center" }}
        >
          View More
          <span style={{ fontWeight: "1000", marginLeft: "10px" }}>
            &raquo;
          </span>
        </a>
      </Hidden>
      <Hidden from="lg" till="3xl">
        <Box
          variant={"tender_table_box"}
          width={"100%"}
          height={"400px"}
          // maxH={"auto"}
          overflowX={"scroll"}
        >
          {mobile_contact_us.map((content, index) => {
            if (content?.values?.length !== 0) {
              return (
                <Box variant={"tender_table_contents"}>
                  {content?.values?.[0] &&
                    <Text color={"black"} marginLeft={"1px"} bold>
                      {ReactHtmlParser(content?.values?.[0])}
                    </Text>
                  }
                  {content?.values?.[1] &&
                    <Text color={"black"} fontWeight={"500"} marginLeft={"1px"}>
                      {ReactHtmlParser(content?.values?.[1])}
                    </Text>
                  }
                  {content?.values?.[2] &&
                    <Text variant={"tender_description"}>{ReactHtmlParser(content?.values?.[2])}</Text>
                  }
                </Box>
              );
            }
          })}
          <Center>
            <Button onPress={handle_redirect} variant="cardButton"
              mb={"0px"}
              width={{ xl: "120px" }}
              height={{ xl: "40px" }} background={"#3B1B7E"}>
              <Text
                variant={"cardButton_Txt"}
                fontSize={{ xl: "15px" }}
              >
                {t("view_more")}
              </Text>
            </Button>
          </Center>
        </Box>
      </Hidden>
    </div>
  );
};

export default ContactUsWelfareOfficers;
