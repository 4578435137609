import React from "react";
import { Box, HStack, Pressable, Text, VStack } from "native-base";
import Card from "@src/threecard/Card";
import { ROUTES } from "@views/routes/my_routes";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { get_cms_announcement_table_query } from "@services/redux/slices/dynamic_entity/graphql/graphql_cms_announcement_table_query";
import { dynamicRequest, useDynamicSelector } from "@services/redux";
import { FilterItems } from "@helpers/constants";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { get_cms_notification_table_query } from "@services/redux/slices/dynamic_entity/graphql/graphql_cms_notification_table_query";

const Notifications = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [announce_data, setAnnounceData] = useState([]);

  const { items: announcement_items } = useDynamicSelector(
    "get_cms_notification_table"
  );

  const informations = [
    "Tamil Nadu Champions Foundation",
    "Advertisement for High Performance Coach",
    // "த.வி.மே.ஆ - பயிற்றுனர் - நேரடி நியமன அறிவிக்கை 2022 SDAT - Coaches -Direct Recruitment Notification 2022",
    // "Apply Online - SDAT - Coaches - Direct Recruitment Notification 2022: Last date for Submission:08.01.2023",
    // "த.வி.மே.ஆ - பயிற்றுனர் - நேரடி நியமன அறிவிக்கை 2022 SDAT - Coaches -Direct Recruitment Notification 2022",
    // "Apply Online - SDAT - Coaches - Direct Recruitment Notification 2022: Last date for Submission:08.01.2023",
    // "Apply Online - SDAT - Coaches - Direct Recruitment Notification 2022: Last date for Submission:08.01.2023",
    // "Apply Online - SDAT - Coaches - Direct Recruitment Notification 2022: Last date for Submission:08.01.2023",
    // "Apply Online - SDAT - Coaches - Direct Recruitment Notification 2022: Last date for Submission:08.01.2023",
    // "Apply Online - SDAT - Coaches - Direct",
  ];

  const get_announcement_table = () => {
    let keys = [{ key: "get_cms_notification_table", loading: true }];
    let variables = {
      json: {
        system_cms_key: "notification",
      },
    };
    dispatch(dynamicRequest(keys, get_cms_notification_table_query, variables));
  };

  useEffect(() => {
    get_announcement_table();
  }, []);

  useEffect(() => {
    let values = FilterItems(announcement_items);
    setAnnounceData(
      values?.sort((a, b) => b?.name?.row_number - a?.name?.row_number)
    );
  }, [announcement_items]);

  const handleClick = (list) => {
    if (list?.file?.value && list?.link?.value) {
      window.open(list.file.value, "_blank");
      window.open(list.link.value, "_blank");
    } else if (list?.file?.value) {
      window.open(list?.file?.value, "_blank");
    } else if (list?.link?.value) {
      window.open(list?.link?.value, "_blank");
    }
  };

  const AnnouncementList = (announce_data) => (
    <>
      {announce_data?.map((information) => (
        <Box>
          <HStack space={"10px"} alignItems={"center"}>
            <Box>
              <img
                src={information?.new?.value}
                style={{
                  width: "32px",
                  // position:"absolute",
                  marginBottom: "30px",
                }}
              />
            </Box>
            <Box
              width={{
                xs: "200px",
                md: "120px",
                lg: "230px",
                xl: "230px",
                "2xl": "auto",
              }}
            >
              <Pressable onPress={() => handleClick(information)}>
                <Text variant="announcementCard_txt">
                  {information?.name?.value}
                </Text>
              </Pressable>
            </Box>
          </HStack>
        </Box>
      ))}
    </>
  );

  return (
    <>
      <Card
        header={t("notifications")}
        component={AnnouncementList(announce_data)}
        url={ROUTES.NOTIFICATIONS}
      />
    </>
  );
};

export default Notifications;
