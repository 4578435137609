import React, { useEffect, useState } from "react";
import {
  Box,
  Divider,
  HStack,
  Pressable,
  Text,
  VStack,
} from "native-base";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button, Collapse, Form } from "antd";
import VForm from "../../../views/components/ui/antd_form/antd_form.jsx";
import { AiOutlineMinusCircle } from "react-icons/ai";
import {
  createcmsSelector,
  updatecmsSelector,
  get_one_cms,
  getcmsSelector,
  update_cms,
  create_cms,
  create_one_cms_clear,
  update_one_cms_clear,
  get_cms,
  getcmsLanguageSelector,
  get_cms_languages,
} from "@services/redux";
import { useHistory, useParams } from "react-router-dom";
import { ROUTES } from "@views/routes/my_routes";
import { startCase, values } from "lodash";
import { showToast } from "@helpers/toast";

const cmsDetails = (props) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();

  const [inputList, setInputList] = useState([{ doc: "" }]);
  const [fieldList, setFieldList] = useState([]);
  const [cmsNameList, setCmsNameList] = useState([{ name: "" }]);
  const [fieldNameList, setFieldNameList] = useState([]);

  const [value, setValue] = useState({});

  const [actionItem, setActionItem] = useState({});
  const { t } = useTranslation();

  const { item } = useSelector(getcmsSelector);
  const { items: cms_languages } = useSelector(getcmsLanguageSelector);

  const {
    loading: createLoading,
    status: cmsCreateStatus,
    error: cmsCreateError,
  } = useSelector(createcmsSelector);

  const {
    loading: updateLoading,
    status: cmsUpdateStatus,
    error: cmsUpdateError,
  } = useSelector(updatecmsSelector);
  const { Panel } = Collapse;
  useEffect(() => {
    if (item) {
      setActionItem({ ...item });
      setValue(item);
      setCmsNameList(item.system_cms_names);
      setInputList(item.system_cms_fields);
      let result = [];
      result = item?.system_cms_fields?.map((x, i) => {
        return (result[i] = x.system_cms_field_names);
      });
      setFieldNameList(result);
      let temp = [];
      temp = item?.system_cms_fields?.map((x, i) => {
        return (temp[i] = x.system_cms_field_options);
      });
      let temp1 = [];
      temp1 = item.system_cms_fields.map((x) => {
        let temp = [...fieldList];
        x.system_cms_field_options.map((y, i) => {
          temp[i] = y.system_cms_field_option_values;
        });
        setFieldList(temp);
      });
    }
  }, [item]);

  useEffect(() => {
    dispatch(get_cms_languages());
  }, []);

  const _type_options = [
    {
      value: "html",
      label: "HTML",
    },
    {
      value: "list",
      label: "List",
    },
  ];

  const _options = [
    {
      value: "text",
      label: "Text",
    },
    { value: "number", label: "Number" },
    { value: "checkbox", label: "Checkbox" },
    { value: "textarea", label: "Large Paragraph" },
    { value: "dropdown", label: "Dropdown" },
    {
      value: "rich_text",
      label: "HTML Content",
    },
    { value: "file", label: "File" },
    { value: "datetime", label: "Datetime" },
    { value: "list", label: "List" },
    { value: "nested_list", label: "Nested List" },

  ];  

  const handleNameAddClick = () => {
    setCmsNameList([
      ...cmsNameList,
      { name: "", system_cms_language_id: null },
    ]);
  };

  const handleNameRemoveClick = (index) => {
    const updatedCmsNameList = [...cmsNameList];
    updatedCmsNameList.splice(index, 1);
    setCmsNameList(updatedCmsNameList);
  };

  const handleAddClick = () => {
    setInputList([...inputList, { key: "", type: "" }]);
  };

  const handleRemoveClick = (index) => {
    const updatedInputList = [...inputList];
    updatedInputList.splice(index, 1);
    setInputList(updatedInputList);
  };

  const handleFieldNameAddClick = (index) => {
    const updatedFieldNames = [...fieldNameList];
    updatedFieldNames[index] = [
      ...(fieldNameList[index] || []),
      { name: "", system_cms_language_id: null },
    ];
    setFieldNameList(updatedFieldNames);
  };

  const handleFieldNameRemoveClick = (index, fieldNameIndex) => {
    const updatedFieldNames = [...fieldNameList];
    updatedFieldNames[index].splice(fieldNameIndex, 1);
    setFieldNameList(updatedFieldNames);
  };

  const handleBack = () => {
    history.push({
      pathname: `${ROUTES.CMS_LIST}`,
    });
  };

  const handleSubmit = (values) => {
    let system_cms_fields = [];
    for (let index = 0; index < values?.system_cms_fields?.length; index++) {
      let cms_field = values.system_cms_fields[index];
      if (!cms_field.system_cms_field_names) {
        cms_field = {
          ...cms_field,
          system_cms_field_names: [
            {
              name: startCase(cms_field.key),
              system_cms_language_id:
                values?.system_cms_names?.[0]?.system_cms_language_id,
              id: id,
            },
          ],
        };
      }
      if (cms_field) system_cms_fields.push(cms_field);
    }


    values = {
      ...values,
      system_cms_fields: system_cms_fields,
    };
    delete values.field_value;
    delete values.option_value;
    delete values.cms_name_value;
    delete values.cms_value;
    delete values.cms_values;
    delete values.field_name_value;
    let update_values = values?.system_cms_fields?.map((list) => {
      let system_cms_field_names_change = list.system_cms_field_names.map((listtt) => {
        return {
          ...listtt
        }
      })
      return {
        ...list,
        system_cms_field_names: system_cms_field_names_change
      }
    })
    update_values = { ...values, system_cms_fields: [...update_values] }

    if (id) {
      let update_id = values?.id;
      delete values?.id;
      delete values?.error;
      dispatch(
        update_cms({
          json: {
            id: id,
            data: { ...update_values },
          },
        })
      );
    } else {
      dispatch(
        create_cms({
          json: { data: { ...values } },
        })
      );
    }
  };

  useEffect(() => {
    if (cmsCreateError) {
      showToast({
        type: "error",
        message: cmsCreateError?.message,
      });
      dispatch(create_one_cms_clear());
    } else if (cmsUpdateError === "error") {
      showToast({
        type: "error",
        message: cmsUpdateError?.message,
      });
      dispatch(update_one_cms_clear());
    } else if (cmsCreateStatus === "success") {
      showToast({
        type: "success",
        message: `Cms ${t("create_successfully")}`,
      });
      dispatch(create_one_cms_clear());
      dispatch(get_cms());
      handleBack();
    } else if (cmsUpdateStatus === "success") {
      showToast({
        type: "success",
        message: `Cms ${t("update_successfully")}`,
      });

      dispatch(update_one_cms_clear());
      dispatch(get_cms());
      handleBack();
    }
  }, [cmsCreateStatus, cmsUpdateStatus]);

  useEffect(() => {
    if (value && JSON.stringify(value) !== JSON.stringify(actionItem))
      setActionItem(value);
  }, [value]);

  useEffect(() => {
    if (id) {
      if (props?.location?.state) {
        setActionItem(props?.location?.state);
      } else {
        dispatch(get_one_cms({ json: { id: id } }));
      }
    }
  }, [id]);

  const handleInputChange = async (value) => {
    setValue(value);
  };
  useEffect(() => {
    if (actionItem) {
      form.setFieldsValue(actionItem);
    }
  }, [id, actionItem]);
  return (
    <Box mx="5">
      <Box mt="10">
        <Text bold fontSize={"20px"}>
          {id ? "Update CMS" : "Add CMS"}
        </Text>
      </Box>
      <Form
        form={form}
        layout="vertical"
        // initialValues={actionItem}
        initialValues={actionItem}
        onFinish={(i) => {
          handleSubmit(i);
        }}
        onValueChange={handleInputChange}
      >
        <Box display={"flex"} flexDirection={"row"} gap={"20px"} width={"100%"}>
          <VForm.TextBox
            label={"Key"}
            style={{ width: "33%" }}
            field={"key"}
            placeholder={"key"}
          />
          <Box width={"200px"}>
            <VForm.Select
              label={"Type"}
              width={"100px"}
              field={"type"}
              labelField={"type"}
              valueField={"value"}
              options={_type_options || []}
              placeholder={"type"}
            />
          </Box>
        </Box>
        <Text bold textAlign={"left"}>
          {"CMS Name"}
        </Text>
        {/* <Collapse accordion>
        <Collapse.Panel header="CMS Name" key="1"> */}
        <HStack justifyContent={"flex-end"}>
          <Pressable onPress={handleNameAddClick} textDecoration={"underline"}>
            <Text bold color="#282d73">
              Add CMS Name
            </Text>
          </Pressable>
        </HStack>
        {cmsNameList?.map((a, i) => (
          <HStack space={4}>
            <VForm.TextBox
              hidden
              field={["system_cms_names", i, "id"]}
              label={t("form:system_cms_names")}
              placeholder={"system cms name"}
            />
            <Box flex={1}>
              <VForm.TextBox
                field={["system_cms_names", i, "name"]}
                label={t("form:system_cms_names")}
                placeholder={"system cms name"}
              />
            </Box>
            <Box flex={1}>
              <VForm.Select
                field={["system_cms_names", i, "system_cms_language_id"]}
                label={t("form:language")}
                labelField={"name"}
                valueField={"id"}
                options={cms_languages}
                placeholder={"language"}
              />
            </Box>
            <Box flex="1" marginTop="1">
              <HStack justifyContent="flex-end" space={2}>
                <Button
                  size="middle"
                  onClick={() => {
                    handleNameRemoveClick(i);
                  }}
                  className={"AiOutlineMinusCircle"}
                >
                  <AiOutlineMinusCircle size="20" />
                </Button>
              </HStack>
            </Box>
          </HStack>
        ))}
        {/* </Collapse.Panel>
      </Collapse> */}
        <Box>
          <Text bold textAlign={"left"}>
            {"CMS field"}
          </Text>
        </Box>
        <HStack justifyContent={"flex-end"}>
          <Pressable
            onPress={() => handleAddClick()}
            textDecoration={"underline"}
          >
            <Text bold color="#282d73">
              Add CMS Field
            </Text>
          </Pressable>
        </HStack>
        {inputList?.map((a, i) => (
          <VStack space={4}>
            <HStack space="5">
              <Box flex={1}>
                <VForm.TextBox
                  hidden
                  field={["system_cms_fields", i, "id"]}
                  label={t("form:key")}
                  key="1"
                  placeholder={"key"}
                />
                <VForm.TextBox
                  field={["system_cms_fields", i, "key"]}
                  label={t("form:key")}
                  key="1"
                  placeholder={"key"}
                />
              </Box>
              <Box flex={1}>
                <VForm.Select
                  field={["system_cms_fields", i, "type"]}
                  label={t("form:type")}
                  options={_options}
                  labelField={"label"}
                  valueField={"value"}
                  key="1"
                  isClearable={true}
                  placeholder={"type"}
                />
              </Box>
            </HStack>
            <Box>
              <Text bold textAlign={"left"}>
                {"CMS field name"}
              </Text>
            </Box>
            <HStack justifyContent={"flex-end"}>
              <Pressable
                onPress={() => {
                  handleFieldNameAddClick(i);
                }}
                textDecoration={"underline"}
              >
                <Text bold color="#282d73">
                  Add CMS field Name
                </Text>
              </Pressable>
            </HStack>
            {fieldNameList?.[i]?.map((a, l) => (
              <HStack space={3}>
                <Box flex={1}>
                  <VForm.TextBox
                    hidden
                    field={[
                      "system_cms_fields",
                      i,
                      "system_cms_field_names",
                      l,
                      "id",
                    ]}
                    label={t("form:name")}
                    key="1"
                    placeholder={"name"}
                  />
                  <VForm.TextBox
                    field={[
                      "system_cms_fields",
                      i,
                      "system_cms_field_names",
                      l,
                      "name",
                    ]}
                    label={t("form:name")}
                    key="1"
                    placeholder={"name"}
                  />
                </Box>
                <Box flex={1}>
                  <VForm.Select
                    field={[
                      "system_cms_fields",
                      i,
                      "system_cms_field_names",
                      l,
                      "system_cms_language_id",
                    ]}
                    label={t("form:language")}
                    options={cms_languages}
                    labelField={"name"}
                    valueField={"id"}
                    isSearchable={true}
                    isClearable={true}
                    isMulti={false}
                    placeholder={"language"}
                  />
                </Box>
                <Box flex="1" marginTop="6">
                  <HStack justifyContent="flex-end" space={2}>
                    <Button
                      size="middle"
                      onClick={() => {
                        handleFieldNameRemoveClick(i);
                      }}
                      className={"AiOutlineMinusCircle"}
                    >
                      <AiOutlineMinusCircle size="20" />
                    </Button>
                  </HStack>
                </Box>
              </HStack>
            ))}
            <Box flex="1" marginTop="5">
              <HStack justifyContent="flex-end" space={2}>
                <Button
                  size="middle"
                  onClick={() => {
                    handleRemoveClick(i);
                  }}
                  className={"AiOutlineMinusCircle"}
                >
                  <AiOutlineMinusCircle size="20" />
                </Button>
              </HStack>
            </Box>
            <Divider />
          </VStack>
        ))}
        <HStack m={5} space="5" justifyContent="flex-end">
          <Button
            danger
            type="ghost"
            onClick={handleBack}
            size={"middle"}
            className={"back-button"}
          >
            {t("back")}
          </Button>
          <Form.Item bg="#161680">
            <Button
              loading={updateLoading || createLoading}
              size={"middle"}
              className={"submit-button"}
              htmlType={"submit"}
              onClick={(i) => {
                handleSubmit(i);
              }}
            >
              {id ? t("update") : t("submit")}
            </Button>
          </Form.Item>
        </HStack>
      </Form>
    </Box>
  );
};
export default cmsDetails;
