import { Box, Hidden, Text } from "native-base";
import React from "react";
import { useTranslation } from "react-i18next";
import MenuBar from "../banner/menubar";
import ApplicationFormTable from "./application_form_table";
import BackgroundWaterMarkLogo from "@views/pages/home/background_water_mark_logo";

const ApplicationFormContents = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Box variant={"tender_page_menubox"} height={"auto"}>
        <Box height={"120px"}>
          <MenuBar />
          <Hidden only={["lg", "xl", "2xl", "3xl"]}>
            <Box variant={"xs_size_menu_headline"}>
              <Text variant={"xs_size_menu_headline_one"}>
                {t("header_content_one")}
              </Text>
              <Text variant={"xs_size_menu_headline_two"}>
                {t("sports_development_authority_of_tamilnadu")}
              </Text>
            </Box>
          </Hidden>
        </Box>
        <Hidden only={["xs", "sm"]}>
          <Text variant={"page_title"}>{t("budget_and_expenditure")}</Text>
        </Hidden>
      </Box>
      <Hidden only={["lg", "xl", "md", "2xl", "3xl"]}>
        <Box>
          <Text variant={"page_title"} color={"black"} paddingBottom={"0px"}>
            {t("budget_and_expenditure")}
          </Text>
        </Box>
      </Hidden>
      {/* <BackgroundWaterMarkLogo /> */}
      <Box
        padding={{
          base: "20px",
          md: "60px",
          lg: "60px",
          xl: "60px",
          "2xl": "60px",
        }}
      >
        <ApplicationFormTable />
      </Box>
    </div>
  );
};

export default ApplicationFormContents;
