import { createSlice } from "@reduxjs/toolkit";
import lodash from "lodash";
import { v4 as uuidv4 } from "uuid";

const initialState = {
  forms: [],
  data_update_ref: null,
  error_update_ref: null,
};

const slice = createSlice({
  name: "forms",
  initialState,
  reducers: {
    _reset_form_data: (state, { payload }) => {
      let forms = JSON.parse(JSON.stringify(state.forms));
      let form = forms.find((x) => x.group === payload.group);
      if (!form) {
        form = {
          group: payload.group,
          data: payload.data,
          error: {},
          isValidating: false,
          data_update_ref: uuidv4(),
        };
        forms.push(form);
        form = forms.find((x) => x.group === payload.group);
      } else {
        form.data = payload.data;
        form.error = {};
        form.isValidating = false;
        form.data_update_ref = uuidv4();
      }
      state.forms = forms;
      state.data_update_ref = uuidv4();
    },
    _set_form_data: (state, { payload }) => {
      let forms = JSON.parse(JSON.stringify(state.forms));
      let form = forms.find((x) => x.group === payload.group);
      if (form) {
        lodash.set(form.data, payload.key, payload.value);
        form.data_update_ref = uuidv4();
        state.forms = forms;
      }
    },
    _set_form_error: (state, { payload }) => {
      let forms = JSON.parse(JSON.stringify(state.forms));
      let form = forms.find((x) => x.group === payload.group);

      if (form) {
        lodash.set(form.error, payload.key, payload.value);
        form.error_update_ref = uuidv4();
        state.forms = forms;
      }
    },
    _set_form_validating: (state, { payload }) => {
      let forms = JSON.parse(JSON.stringify(state.forms));
      let form = forms.find((x) => x.group === payload.group);
      if (form) {
        lodash.set(form, "isValidating", true);
        state.forms = forms;
      }
    },
  },
});

const {
  _reset_form_data,
  _set_form_data,
  _set_form_error,
  _set_form_validating,
} = slice.actions;

export const formsSelector = (state) => state.forms;

export const formsReducer = slice.reducer;

export function reset_form_data(group, data) {
  return async (dispatch) => {
    dispatch(_reset_form_data({ group, data }));
  };
}

export function set_form_data(group, key, value) {
  return async (dispatch) => {
    dispatch(_set_form_data({ group, key, value }));
  };
}

export function set_form_error(group, key, value) {
  return async (dispatch) => {
    dispatch(_set_form_error({ group, key, value }));
  };
}

export function set_form_validating(group) {
  return async (dispatch) => {
    dispatch(_set_form_validating({ group }));
  };
}
