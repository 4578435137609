import React from "react";
import { useHistory } from "react-router-dom";
import NotAllowed from "@views/components/common/not_allowed";
import {
  useCheckLogin,
  useShouldChangePassword,
  usePageAccess,
} from "@helpers/auth";
import { useLocation } from "react-router-dom";

const PrivateRoute = ({ children, pageKey, has_login_access, page }) => {
  const history = useHistory();
  const isLoggedIn = useCheckLogin();
  const location = useLocation()
  // React.useEffect(() => {
  //   if (isLoggedIn === false) history.replace("/login");
  // }, [isLoggedIn]);
  const shouldChangePassword = useShouldChangePassword();
  if (shouldChangePassword) {
    //redirect to change password
  }
  const hasAccess = usePageAccess(pageKey);
  if (has_login_access || page?.page_key === pageKey) {
    return <>{children}</>;
  } else {
    if (!has_login_access) {
      return <NotAllowed />;
    }
  }

};

export default PrivateRoute;
