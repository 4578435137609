import React, { useEffect, useState } from "react";
import BackgroundLayer from "@views/components/banner/background_layer";
import { Box, HStack, VStack } from "native-base";
import GalleryAndTwitterPage from "@views/components/gallery/pages";
import LatestNews from "@views/components/upcoming_events/latest_news";
import UpcomingEvent from "@views/components/upcoming_events/upcoming_events";
import CmTrophy from "@views/components/upcoming_events/cm_trophy";
import { Cards } from "../../../threecard";
import HomeAboutUs from "@views/components/upcoming_events/home_about_us";
// import Largelogo from "@assets/images/menu/background_image.png";
// import PolicyNote from "@views/components/about_us/policy_note";
// import Low from "@assets/images/menu/law-book.png";
import { useTranslation } from "react-i18next";
import { dynamicRequest, useDynamicSelector } from "@services/redux";
import HomePageLoading from "./home_page_loading";
import { FilterItems } from "@helpers/constants";
import { get_cms_homepage_gallery_query } from "@services/redux/slices/dynamic_entity/graphql/graphql_cms_homepage_gallery_query";
import { get_cms_homepage_slider_query } from "@services/redux/slices/dynamic_entity/graphql/graphql_cms_homepage_slider_query";
import { get_cms_latest_news_query } from "@services/redux/slices/dynamic_entity/graphql/graphql_cms_latest_news_query";
import { get_cms_upcoming_events_query } from "@services/redux/slices/dynamic_entity/graphql/graphql_cms_upcoming_events_query";
import { useDispatch } from "react-redux";
import TNChampions from "@views/components/upcoming_events/tn_champions";
import { storeItem } from "@helpers/storage";

const HomePage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading_status, set_loading_status] = useState(false);
  const [gallery_data, setGalleryData] = useState();
  const [slider_data, setSliderData] = useState([]);
  const [news_data, setNewsData] = useState([]);
  const [events_data, setEventsData] = useState([]);
  const { loading: rti_loading } = useDynamicSelector(
    "get_cms_right_to_person_disabilities"
  );
  const { loading: slider_loading, items: slider_items } = useDynamicSelector(
    "get_cms_homepage_slider"
  );
  const { loading: latest_news_loading, items: latest_news_items } =
    useDynamicSelector("get_cms_latest_news");
  const { loading: gallery_loading, items: gallery_items } = useDynamicSelector(
    "get_cms_homepage_gallery"
  );
  const { items: events_items, loading: upcoming_loading } = useDynamicSelector(
    "get_cms_upcoming_events"
  );

  // let loading_status =
  //   gallery_loading || latest_news_loading || slider_loading || rti_loading;

  useEffect(() => {
    let values = FilterItems(gallery_items);
    setGalleryData(values);
  }, [gallery_items]);
  useEffect(() => {
    let values = FilterItems(events_items);
    setEventsData(values);
  }, [events_items]);
  useEffect(() => {
    let values = FilterItems(slider_items);
    setSliderData(values);
  }, [slider_items]);
  useEffect(() => {
    let values = FilterItems(latest_news_items);
    setNewsData(values);
  }, [latest_news_items]);

  const get_homepage_gallery = () => {
    let keys = [{ key: "get_cms_homepage_gallery", loading: true }];
    let variables = {
      json: {
        system_cms_key: "home_page_gallery",
      },
    };
    dispatch(dynamicRequest(keys, get_cms_homepage_gallery_query, variables));
  };
  const get_latest_news = () => {
    let keys = [{ key: "get_cms_latest_news", loading: true }];
    let variables = {
      json: {
        system_cms_key: "latest_news",
      },
    };
    dispatch(dynamicRequest(keys, get_cms_latest_news_query, variables));
  };
  const get_homepage_slider = () => {
    let keys = [{ key: "get_cms_homepage_slider", loading: true }];
    let variables = {
      json: {
        system_cms_key: "homepage_slider",
      },
    };
    dispatch(dynamicRequest(keys, get_cms_homepage_slider_query, variables));
  };

  const get_upcoming_events = () => {
    let keys = [{ key: "get_cms_upcoming_events", loading: true }];
    let variables = {
      json: {
        system_cms_key: "upcoming_events",
      },
    };
    dispatch(dynamicRequest(keys, get_cms_upcoming_events_query, variables));
  };
  useEffect(() => {
    get_homepage_gallery();
    get_homepage_slider();
    get_latest_news();
    get_upcoming_events();
    storeItem("language", {
      id: "815d74c2-5c50-4bc0-96fd-8f81c403a7cf",
      name: "English",
      key: "en",
    })
  }, []);
  return (
    <>
      {gallery_loading ||
        latest_news_loading ||
        slider_loading ||
        upcoming_loading
        ? (
          <HomePageLoading />
        ) : (
          <Box overflowX={{ base: "hidden" }}>
            <Box
              bgImage={
                "linear-gradient(45deg, rgb(31 0 95), rgb(167 18 173 / 58%))"
              }
            >
              {/* <MenuBar /> */}
              <BackgroundLayer slider_data={slider_data} />
            </Box>
            <Box flex={1}>
              <HStack>
                <Box flex={1}>
                  <LatestNews news_data={news_data?.sort((a, b) => Number(a?.titles?.row_number) - Number(b?.titles?.row_number))} />
                </Box>
                <Box
                  width={{
                    base: "100%",
                    md: "100%",
                    lg: "",
                    xl: "",
                    "2xl": "",
                    "3xl": "",
                  }}
                >
                  <UpcomingEvent events_data={events_data?.sort((a, b) => Number(a?.order?.value) - Number(b?.order?.value))} />
                </Box>
              </HStack>
              <HomeAboutUs />
              <TNChampions />
              <CmTrophy />
            </Box>
            <Cards />
            <GalleryAndTwitterPage gallery_data={gallery_data} />
            {/* <Box
        pl={{
          base: "60px",
          xs: "30px",
          lg: "60px",
          xl: "60px",
          "2xl": "60px",
          "3xl": "60px",
        }}
        pr={{
          base: "60px",
          xs: "30px",
          lg: "60px",
          xl: "60px",
          "2xl": "60px",
          "3xl": "60px",
        }}
        pb={"30px"}
        pt={"10px"}
      >
        <PolicyNote year_of_documents={year_of_documents} Details={Details} />
      </Box> */}
          </Box>
        )}
    </>
  );
};

export default HomePage;
