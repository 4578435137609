import { Pagination } from "antd";
import { startCase } from "lodash";
import { Box, HStack, Hidden, Link, Stack, Text } from "native-base";
import React from "react";
import { useTranslation } from "react-i18next";
const ScreenReaderAccessTable = () => {
  const { t } = useTranslation();
  const TableData = [
    {
      tender: "Screen Access For All (SAFA)",
      closing_date: "http://www.nabdelhi.in",
      commercial: "free",
    },
    {
      tender: "Non Visual Desktop Access (NVDA)",
      closing_date: "http://www.nvda-project.org",
      commercial: "free",
    },
    {
      tender: "System Access To Go",
      closing_date: "http://www.satogo.com/",
      commercial: "free",
    },
    {
      tender: "Thunder",
      closing_date: "http://www.screenreader.net/index.php?pageid=2",
      commercial: "free",
    },
    {
      tender: "WebAnywhere",
      closing_date: "http://webanywhere.cs.washington.edu/wa.php",
      commercial: "free",
    },
    {
      tender: "Hal",
      closing_date: "http://www.yourdolphin.co.uk/productdetail.asp?id=5",
      commercial: "commercial",
    },
    {
      tender: "JAWS",
      closing_date: "http://www.freedomscientific.com/jaws-hq.asp",
      commercial: "commercial",
    },
    {
      tender: "Supernova",
      closing_date: "http://www.yourdolphin.co.uk/productdetail.asp?id=1",
      commercial: "commercial",
    },
    {
      tender: "Window-Eyes",
      closing_date: "http://www.gwmicro.com/Window-Eyes/",
      commercial: "commercial",
    },
  ];
  return (
    <div>
      <Hidden from="base" till="md">
        <table class="table">
          <thead className="table-header">
            <tr>
              <th className="radius-right">{t("screen_reader")}</th>
              <th style={{ flex: 1 }}>{t("website")}</th>
              <th className="radius-left" style={{ flex: 1 }}>
                {t("free_commercial")}
              </th>
            </tr>
          </thead>
          <tbody>
            {TableData.map((tablelist, index) => {
              return (
                <>
                  <tr
                    className={`space-add ${
                      TableData.length - 1 !== index
                        ? "table-border-bottom"
                        : ""
                    }`}
                  >
                    <td data-label="screen_reader">{tablelist.tender}</td>
                    <td data-label="website" style={{ paddingRight: "20px" }}>
                      <a
                        href={tablelist.closing_date}
                        target="_blank"
                        className="website"
                      >
                        {tablelist.closing_date}
                      </a>
                    </td>
                    <td
                      data-label="commercial"
                      className="column-width"
                      style={{ paddingRight: "20px" }}
                    >
                      {startCase(tablelist.commercial)}
                    </td>
                  </tr>
                </>
              );
            })}
          </tbody>
        </table>
      </Hidden>
      <Hidden from="lg" till="3xl">
        <Box variant={"tender_table_box"}>
          {TableData.map((content, index) => {
            return (
              <>
                <Box variant={"tender_table_contents"}>
                  <Text variant={"tender_description"} color={"black"} bold>
                    {content.tender}
                  </Text>
                  <Text>
                    <Text variant={"table_headline"} color={"#828282"}>
                      {t("website")}:
                    </Text>
                    <Text color={"black"} fontWeight={"500"} marginLeft={"1px"}>
                      <a
                        href={content.closing_date}
                        style={{ color: "#1890ff" }}
                        target="_blank"
                      >
                        {content.closing_date}
                      </a>
                    </Text>
                  </Text>
                  <Text>
                    <Text variant={"table_headline"} color={"#828282"}>
                      {t("free_commercial")}:
                    </Text>
                    <Text color={"black"} fontWeight={"500"} marginLeft={"1px"}>
                      {startCase(content.commercial)}
                    </Text>
                  </Text>
                </Box>
              </>
            );
          })}
        </Box>
      </Hidden>
      <div className="pagination-box">
        <Stack variant={"pagination_stack"}>
          <Hidden from="base" till="lg">
            <Box justifyContent={"center"}>
              <Text>Records per page</Text>
            </Box>
          </Hidden>
          <Box variant={"pagination_box"}>
            <Pagination
              total={85}
              //   showTotal={(total) => `Records per page ${total} items`}
              defaultPageSize={20}
              defaultCurrent={1}
            />
          </Box>
        </Stack>
      </div>
    </div>
  );
};

export default ScreenReaderAccessTable;
